/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../redux-magic/store";
import { ObjectId } from "mongodb";

/* Component Imports */

import {
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Skeleton,
	Tooltip,
	useTheme,
	Paper,
	Typography,
	Box,
} from "@mui/material";

/* Icon Imports */
import BoltIcon from "@mui/icons-material/Bolt";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { useRouter } from "next/router";
import DeleteListingPropertyModal from "../../../modals/PropertyModal/DeleteListingProperty";
import { MoreVertOutlined } from "@mui/icons-material";
import { signIn } from "next-auth/react";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "14rem",
	borderRadius: "8px 8px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "14rem",
	borderRadius: "5px 5px 0px 0px",
}));

const BoltContainer = styled("div")(({ theme }) => ({
	background: "white",
	"&:hover": {
		background: "white",
	},
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "#00000040",
	margin: "0.5rem 0.5rem 0rem 0rem",
	"&:hover": {
		background: "#00000040",
		// scale: "1.1",
	},
}));
const ThreeDotIconButton = styled(IconButton)(({ theme }) => ({
	background: "#00000040",
	margin: "0.5rem 0.5rem 0rem 0rem",
	// "@media (pointer: fine": {
	"&:hover": {
		background: "#00000040",
		// scale: "1.1",
	},
	// },
}));

const ServiceCardImage = ({
	imagesrc,
	alt,
	dispatch,
	profile_context,
	session,
	service_id,
	slug,
	loading,
	saved_content,
	created_by,
	page_type,
	isEditor,
	assigned_to,
	boosting,
	preview,
}: {
	imagesrc: string;
	alt: string;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	session: any;
	loading: boolean;
	service_id: string | ObjectId;
	slug: string;
	saved_content: Array<any>;
	created_by?: any;
	page_type?: string;
	isEditor?: boolean;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	boosting?: StateInterface["service"]["boost_information"];
	preview?: boolean;
}) => {
	const [isHovered, setHovered] = React.useState(false);

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

	const [open, setOpen] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const theme = useTheme();
	/*

  & Next, Let's descride a function to chnage anchorEl state.

  */

	const handleClick = (event: any) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const router = React.useRef(useRouter()).current;

	const [bookmark, setBookmark] = React.useState(
		saved_content?.find((item) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id === profile_context?.business_profile_id
				: item.saved_by.user_id === profile_context?.user_id;
		}) !== undefined,
	);
	React.useEffect(() => {
		setBookmark(
			saved_content?.find((item) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id === profile_context?.business_profile_id
					: item.saved_by.user_id === profile_context?.user_id;
			}) !== undefined,
		);
	}, [profile_context, saved_content]);

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: service_id,
					entity_type: "service",
					add_remove: !bookmark ? "add" : "remove",
					url: router.asPath ? router.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	const handleDelete = async () => {
		setOpenDeleteModal(true);
		setAnchorEl(null);
		setOpen(false);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false);
	};

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="100%"
					height="100%"
					style={{ borderRadius: "0.25rem" }}
				/>
			</React.Fragment>
		);
	}
	/*

  & Next, let's declare  local states to handle Modals.

  */

	let BoostingCheck = boosting?.active ? true : false;

	/*boosting end date */
	const date = new Date(boosting?.ends_at || Date.now());

	/* Date , Month and year format*/
	const formattedDate = `
  ${date.getDate()} 
  ${date.toLocaleString("en-US", {
		month: "short",
	})} 
  ${date.getFullYear().toString().slice(2)}`;

	const BoostPaper = (
		<Paper
			sx={{
				position: "absolute",
				top: 13,
				left: 13,
				zIndex: 4,
				display: "flex",
				justifyContent: "center",
				alignItems: "space-between",
				height: "2.25rem",
				padding: "0.43rem",
				flexDirection: "column",
				overflow: "hidden",
				borderRadius: "10px",
				boxShadow: "none",
				backgroundColor: "white",
			}}
		>
			<BoltContainer
				// onClick={(event: any) => {
				//   event.preventDefault();
				//   session === null ? window.open("/auth/login") : submit();
				// }}
				sx={{
					display: "flex",
					width: !isHovered ? "1.5rem" : "11rem",
					height: "2rem",
					gap: "0.5rem",
					overflow: "hidden",
					transition: "width 0.5s ease-in-out",
				}}
			>
				<BoltIcon
					sx={{ color: theme.palette.primary.main }}
					fontSize="medium"
				/>
				<Typography>{session === null ? "Boost Property" : "Ends on " + formattedDate}</Typography>
			</BoltContainer>
		</Paper>
	);

	return (
		<React.Fragment>
			<DeleteListingPropertyModal
				open={openDeleteModal}
				handleClose={handleCloseDeleteModal}
				creator_user_id={created_by?.userId}
				creator_business_profile_id={created_by?.business_profile_id}
				propertyId={service_id}
				session={session}
				isEditor={isEditor ? isEditor : false}
				profile_context={profile_context}
				dispatch={dispatch}
				property_type={"service"}
			/>

			<Container
				sx={{
					borderTop: BoostingCheck ? "3px solid #fc8019" : "none",
					borderRight: BoostingCheck ? "3px solid #fc8019" : "none",
					borderLeft: BoostingCheck ? "3px solid #fc8019" : "none",
					// boxShadow: BoostingCheck ? "none" : "0px 1px 3px rgba(0, 0, 0, 0.12)",
				}}
			>
				{preview ? null : (
					<BookmarkIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? window.open("/auth/login") : submit();
						}}
						sx={{
							position: "absolute",
							top: 3,
							right: page_type ? 48 : 4,
							zIndex: 4,
						}}
					>
						<Tooltip title="Favourite">
							{bookmark == false ? (
								<BookmarkBorderOutlinedIcon
									sx={{ color: "#fafafa" }}
									fontSize="medium"
								/>
							) : (
								<BookmarkOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
									fontSize="medium"
								/>
							)}
						</Tooltip>
					</BookmarkIconButton>
				)}

				{boosting?.active === true ? (
					<div
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={{ position: "absolute", background: "black" }}
					>
						<Box sx={{ width: "100%" }}>
							<div>{BoostPaper}</div>
						</Box>
					</div>
				) : null}
				{page_type ? (
					<ThreeDotIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? signIn() : handleClick(event);
						}}
						sx={{
							position: "absolute",
							top: 3,
							right: 3,
							zIndex: 4,
						}}
					>
						<MoreVertOutlined sx={{ color: "#fafafa" }} />
					</ThreeDotIconButton>
				) : null}
				{/* Menu */}
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					disableScrollLock={true}
				>
					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem
							onClick={() => {
								window.open(`/services/${slug}`, "_self");
								handleClose();
							}}
						>
							Edit service
						</MenuItem>
					) : null}

					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem onClick={handleDelete}>Delete service</MenuItem>
					) : null}

					{/* <MenuItem>
                  <Link
                    href={`/boostings/property/${slug}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    onClick={handleClose}
                    rel="noopener"
                    target="_blank"
                    referrerPolicy="no-referrer"
                    component="a"
                  >
                    <ListItemIcon>
                      <img
                        src="/images/icons/business-profile/tunder.svg"
                        width={22}
                        height={22}
                        alt="tunder"
                        referrerPolicy="no-referrer"
                      />
                    </ListItemIcon>
                    Boost property
                  </Link>
                </MenuItem> */}
				</Menu>

				<Image
					src={imagesrc}
					alt={alt}
					referrerPolicy="no-referrer"
				/>
			</Container>
		</React.Fragment>
	);
};
export default ServiceCardImage;
