/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.145rem",
  letterSpacing: "0.0025rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
  margin: "0rem 0.25rem 0rem 0rem",
}));

const GridCardLocation = ({
  loading,
  location,
}: {
  loading: boolean;
  location: string;
}) => {
  const theme = useTheme();
  if (loading) {
    return (
      <React.Fragment>
        <Skeleton
          animation="wave"
          variant="text"
          width="75%"
          height="auto"
          style={{ marginBottom: "0.5rem" }}
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Heading>
        <InfoIcon
          src={
            theme.palette.mode === "dark"
              ? "/images/icons/config/location_marker_white.svg"
              : "/images/icons/config/location_marker.svg"
          }
          alt="Location Icon"
          width={16}
          height={16}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
        {location}
      </Heading>
    </React.Fragment>
  );
};

export default GridCardLocation;
