/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography } from "@mui/material";

/* Styled Components */

const FooterCopyrightDesktop = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const FooterCopyrightMobile = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  margin: "0rem 0rem 4rem 0rem",
  textAlign: "center",
}));

const FooterCopyrightInfo = () => {
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      {/* Desktop */}

      <FooterCopyrightDesktop variant="body2">
        {"© " +
          currentYear +
          " Beegru Real Estate Private Limited. All Rights Reserved."}
      </FooterCopyrightDesktop>

      {/* Mobile */}

      <FooterCopyrightMobile variant="body2">
        {"© " +
          currentYear +
          " Beegru Real Estate Private Limited. All Rights Reserved."}
      </FooterCopyrightMobile>
    </React.Fragment>
  );
};

export default FooterCopyrightInfo;
