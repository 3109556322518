/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import {
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
} from "../../../../../redux-magic/store";

/* Component Imports */

import {
	Typography,
	useTheme,
	TextField,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	OutlinedInput,
	Checkbox,
	ListItemText,
	FormGroup,
	FormControlLabel,
	Switch,
	IconButton,
} from "@mui/material";

/* Icon Imports */

import { DeleteOutline, Add } from "@mui/icons-material";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	width: "100%",
	borderRadius: "8px",
	gap: "1.5rem",
	overflow: "auto",
	paddingTop: "2rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	// gap: "0.6rem",
	width: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
	margin: "0.5rem 0rem 0.5rem 0rem",
	fontWeight: "400",
	fontSize: "0.875rem",
	lineHeight: "1.125rem",
	whiteSpace: "pre",
}));

interface Area {
	label: string;
	enableLabel: boolean;
	land_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	super_built_up_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	carpet_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	karab_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
}

interface BHK {
	min: number;
	max: number;
	label: string;
	enableLabel: boolean;
}

const PropertyConfig = ({
	propertyData,
	property_furnishing_status,
	property_plot_dimensions,
	property_area,
	property_bhk,
	property_bathroom,
	property_balconies,
	property_floors,
	property_floor_no,
	property_no_of_units,
	property_no_of_seats,
	studio,
	dispatch,
	session,
}: {
	propertyData: any;
	property_furnishing_status: Array<string>;
	property_plot_dimensions: Array<string>;
	property_area: Area;
	property_bhk: BHK;
	property_bathroom: BHK;
	property_balconies: BHK;
	property_floors: BHK;
	property_floor_no: BHK;
	property_no_of_units: BHK;
	property_no_of_seats: BHK;
	studio: boolean;
	dispatch: Function;
	session: any;
}) => {
	const theme = useTheme();

	const plot_dimensions = ["20 x 30", "30 x 40", "30 x 50", "40 x 60", "50 x 80", "60 x 90", "80 x 120", "Other"];

	const [isEditing, setIsEditing] = React.useState(false);

	const [landAreaUnit, setLandAreaUnit] = React.useState<string>("");

	const [superBuiltUpAreaUnit, setSuperBuiltUpAreaUnit] = React.useState<string>("");

	const [ca_is_percentage_of_sba, setCaIsPercentageOfSba] = React.useState<boolean>(false);

	const [minCarpetAreaPercentage, setMinCarpetAreaPercentage] = React.useState<number>(70);

	const [maxCarpetAreaPercentage, setMaxCarpetAreaPercentage] = React.useState<number>(70);

	const [carpetAreaUnit, setCarpetAreaUnit] = React.useState<string>("");

	const newDelimitedSearchString = propertyData.search_metrics
		? propertyData.search_metrics.search_string_delimiter.split("ߐ")
		: [];

	const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

	const [errorBedroom, setErrorBedroom] = React.useState<boolean>(false);

	const [errorMinBedroom, setErrorMinBedroom] = React.useState<boolean>(false);

	const [errorMaxBedroom, setErrorMaxBedroom] = React.useState<boolean>(false);

	const [errorBathroom, setErrorBathroom] = React.useState<boolean>(false);

	const [errorMinBathroom, setErrorMinBathroom] = React.useState<boolean>(false);

	const [errorMaxBathroom, setErrorMaxBathroom] = React.useState<boolean>(false);

	const [errorNoOfSeats, setErrorNoOfSeats] = React.useState<boolean>(false);

	const [errorNoOfUnits, setErrorNoOfUnits] = React.useState<boolean>(false);

	const [errorMaxFloors, setErrorMaxFloors] = React.useState<boolean>(false);

	const [errorMinOtherPlot, setErrorMinOtherPlot] = React.useState<Array<boolean>>(
		new Array(property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length).fill(false),
	);

	const [errorMaxOtherPlot, setErrorMaxOtherPlot] = React.useState<Array<boolean>>(
		new Array(property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length).fill(false),
	);

	const [errorLandArea, setErrorLandArea] = React.useState<boolean>(false);

	const [errorSba, setErrorSba] = React.useState<boolean>(false);

	const [errorMinSba, setErrorMinSba] = React.useState<boolean>(false);

	const [errorMaxSba, setErrorMaxSba] = React.useState<boolean>(false);

	const [errorMinCa, setErrorMinCa] = React.useState<boolean>(false);

	const [errorMaxCa, setErrorMaxCa] = React.useState<boolean>(false);

	return (
		<PaperContainer id="config-area">
			{/* Furnishing */}
			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<FormControl
					sx={{
						width: "100%",
						margin: "0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0rem 0rem 0rem 0.5rem",
						},
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-simple-select-helper-label">Furnishing</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_furnishing_status ? property_furnishing_status : []}
						sx={{
							width: "100%",
							[theme.breakpoints.down("sm")]: {
								minWidth: "100%",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label="Furnishing"
						onChange={(event: any) => {
							dispatch(setCreateNewPropertyFurnishingThunk([event.target.value]));
						}}
					>
						<MenuItem value="furnished">Furnished</MenuItem>
						<MenuItem value="semi_furnished">Semi furnished</MenuItem>
						<MenuItem value="unfurnished">Unfurnished</MenuItem>
					</Select>
				</FormControl>
			) : null}

			{/* Bedrooms */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			studio === false &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer>
					{propertyData.transaction_type !== "buy" ||
					propertyData.property_type.includes("Co-living/PG") ||
					propertyData.property_type.includes("Independent House") ? (
						<TextField
							value={property_bhk.max === 0 ? "" : property_bhk.max}
							sx={{
								width: "100%",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem",
									minWidth: "100%",
								},
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							inputProps={{
								min: 0,
							}}
							onChange={(event: any) => {
								dispatch(setCreateNewPropertyMinBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
								if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
									setErrorBedroom(true);
									setSubmitDisabled(true);
								} else {
									setErrorBedroom(false);
									setSubmitDisabled(false);
								}
							}}
							error={errorBedroom}
							helperText={errorBedroom ? "Enter a valid number" : ""}
							label="Bedrooms*"
						/>
					) : (
						<TextContainer
							sx={{
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									margin: "0.5rem 0rem 0rem 0.5rem",
									gap: "1.5rem",
								},
								gap: "1.5rem",
							}}
						>
							<TextField
								value={property_bhk.min === 0 ? "" : property_bhk.min}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								inputProps={{
									min: 0,
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bhk.max !== 0 && Number(event.target.value.replace(/\D/g, "")) > property_bhk.max) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBedroom(true);
										setErrorMaxBedroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBedroom(false);
										setErrorMaxBedroom(false);
										setSubmitDisabled(false);
									}
								}}
								error={errorMinBedroom}
								label="Minimum Bedrooms*"
								helperText={errorMinBedroom ? "Value is more than max bedrooms" : ""}
							/>

							<TextField
								value={property_bhk.max === 0 ? "" : property_bhk.max}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								inputProps={{
									min: 0,
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMaxBedroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bhk.min !== 0 && Number(event.target.value.replace(/\D/g, "")) < property_bhk.min) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBedroom(true);
										setErrorMaxBedroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBedroom(false);
										setErrorMaxBedroom(false);
										setSubmitDisabled(false);
									}
								}}
								error={errorMaxBedroom}
								helperText={errorMaxBedroom ? "Value is less than min bedrooms" : ""}
								label="Maximum Bedrooms*"
							/>
						</TextContainer>
					)}
				</TextContainer>
			) : null}

			{/* Bathrooms */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			studio === false &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer>
					{(propertyData.transaction_type !== "buy" && !propertyData.property_type.includes("Co-living/PG")) ||
					propertyData.property_type.includes("Independent House") ? (
						<TextContainer
							sx={{
								[theme.breakpoints.down("sm")]: {
									margin: "0.5rem 0rem 0rem 0.5rem",
								},
							}}
						>
							<TextField
								value={property_bathroom.max === 0 ? "" : property_bathroom.max}
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								inputProps={{
									min: 0,
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									dispatch(setCreateNewPropertyMaxBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
										setErrorBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								error={errorBathroom}
								helperText={errorBathroom ? "Enter a valid number" : ""}
								label="Bathrooms"
							/>
						</TextContainer>
					) : (
						<TextContainer
							sx={{
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									margin: "0.5rem 0rem 0rem 0.5rem",
									gap: "1.5rem",
								},
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
								gap: "1.5rem",
							}}
						>
							<TextField
								value={property_bathroom.min === 0 ? "" : property_bathroom.min}
								inputProps={{
									min: 0,
								}}
								autoComplete="off"
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMinBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bathroom.max !== 0 &&
											Number(event.target.value.replace(/\D/g, "")) > property_bathroom.max) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBathroom(true);
										setErrorMaxBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBathroom(false);
										setErrorMaxBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								error={errorMinBathroom}
								helperText={errorMinBathroom ? "Value is more than max bathrooms" : ""}
								label="Minimum Bathrooms"
							/>

							<TextField
								value={property_bathroom.max === 0 ? "" : property_bathroom.max}
								inputProps={{
									min: 0,
								}}
								autoComplete="off"
								onChange={(event: any) => {
									dispatch(setCreateNewPropertyMaxBathroomsThunk(Number(event.target.value.replace(/\D/g, ""))));
									if (
										(property_bathroom.min !== 0 &&
											Number(event.target.value.replace(/\D/g, "")) < property_bathroom.min) ||
										isNaN(Number(event.target.value.replace(/\D/g, "")))
									) {
										setErrorMinBathroom(true);
										setErrorMaxBathroom(true);
										setSubmitDisabled(true);
									} else {
										setErrorMinBathroom(false);
										setErrorMaxBathroom(false);
										setSubmitDisabled(false);
									}
								}}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								error={errorMaxBathroom}
								helperText={errorMaxBathroom ? "Value is less than min bathrooms" : ""}
								label="Maximum Bathrooms"
							/>
						</TextContainer>
					)}
				</TextContainer>
			) : null}

			{/* Balconies */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Building") &&
			!propertyData.property_type.includes("Shop") &&
			!propertyData.property_type.includes("Office Space") &&
			!propertyData.property_type.includes("Co-working") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer>
					{(propertyData.transaction_type !== "buy" && !propertyData.property_type.includes("Co-living/PG")) ||
					propertyData.property_type.includes("Independent House") ? (
						<TextField
							value={property_balconies.max === 0 ? "" : property_balconies.max}
							inputProps={{
								min: 0,
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							onChange={(event: any) => {
								dispatch(setCreateNePropertyMinBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
							}}
							label="Balconies"
						/>
					) : (
						<TextField
							value={property_balconies.max === 0 ? "" : property_balconies.max}
							inputProps={{
								min: 0,
							}}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
							autoComplete="off"
							onChange={(event: any) => {
								dispatch(setCreateNePropertyMinBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
								dispatch(setCreateNewPropertyMaxBalconiesThunk(Number(event.target.value.replace(/\D/g, ""))));
							}}
							label="Balconies"
						/>
					)}
				</TextContainer>
			) : null}

			{/* No of Seats */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			propertyData.property_type.includes("Co-working") ? (
				<TextField
					value={property_no_of_seats.max === 0 ? "" : property_no_of_seats.max}
					inputProps={{
						min: 0,
					}}
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					autoComplete="off"
					onChange={(event: any) => {
						dispatch(setCreateNewPropertyNoOfSeatsThunk(Number(event.target.value.replace(/\D/g, ""))));
						if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
							setErrorNoOfSeats(true);
							setSubmitDisabled(true);
						} else {
							setErrorNoOfSeats(false);
							setSubmitDisabled(false);
						}
					}}
					error={errorNoOfSeats}
					helperText={errorNoOfSeats ? "Enter a valid number" : ""}
					label="No. of seats*"
				/>
			) : null}

			{/* No of Units */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			propertyData.transaction_type == "buy" &&
			(propertyData.property_type.includes("Apartment") ||
				propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)") ||
				propertyData.property_type.includes("Villa") ||
				propertyData.property_type.includes("Row House") ||
				propertyData.property_type.includes("Builder Floor Apartment") ||
				propertyData.property_type.includes("Studio Apartment") ||
				propertyData.property_type.includes("Penthouse")) ? (
				<TextField
					value={property_no_of_units.max === 0 ? "" : property_no_of_units.max}
					inputProps={{
						min: 0,
					}}
					autoComplete="off"
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					onChange={(event: any) => {
						dispatch(setCreateNewPropertyNoOfUnitsThunk(Number(event.target.value.replace(/\D/g, ""))));
						if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
							setErrorNoOfUnits(true);
							setSubmitDisabled(true);
						} else {
							setErrorNoOfUnits(false);
							setSubmitDisabled(false);
						}
					}}
					error={errorNoOfUnits}
					helperText={errorNoOfUnits ? "Enter a valid number" : ""}
					label="Total no. of units*"
				/>
			) : null}

			{/* No of Floors */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextField
					value={property_floors.max === 0 ? "" : property_floors.max}
					inputProps={{
						min: 0,
					}}
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					onChange={(event: any) => {
						// setMinFloors(event.target.value);
						dispatch(setCreateNewPropertyFloorNoThunk(Number(event.target.value.replace(/\D/g, ""))));
						dispatch(setCreateNewPropertyMaxFloorsThunk(Number(event.target.value.replace(/\D/g, ""))));
						if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
							setErrorMaxFloors(true);
							setSubmitDisabled(true);
						} else {
							setErrorMaxFloors(false);
							setSubmitDisabled(false);
						}
					}}
					error={errorMaxFloors}
					helperText={errorMaxFloors ? "Enter a valid number" : ""}
					label="Total no. of floors"
					autoComplete="off"
				/>
			) : null}

			{/* Plot Dimensions */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			(propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)")) ? (
				<FormControl
					sx={{
						width: "100%",
						margin: "0rem 0rem 0rem 0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0.5rem 0rem 0rem 0.5rem",
						},
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-simple-select-helper-label">Plot Dimensions*</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						multiple={propertyData.transaction_type === "buy" ? true : false}
						value={property_plot_dimensions}
						sx={{
							width: "100%",
							[theme.breakpoints.down("sm")]: {
								minWidth: "100%",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label="Plot Dimensions*"
						onChange={(event: any) => {
							dispatch(
								setCreateNewPropertyPlotDimensionsThunk(
									typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
								),
							);
						}}
						input={<OutlinedInput label="Plot Dimensions" />}
						renderValue={(selected) => selected.join(", ")}
					>
						{plot_dimensions?.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								{propertyData.transaction_type === "buy" ? (
									<Checkbox checked={property_plot_dimensions.indexOf(name) > -1} />
								) : null}

								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{/* Other Plot Dimensions */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			(propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)")) &&
			property_plot_dimensions.includes("Other") ? (
				// <TextContainer sx={{ alignItems: "flex-start" }}>
				<TextContainer
					sx={{
						width: "100%",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "0.5rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0rem 0rem 0rem 0.5rem",
						},
					}}
				>
					<Text
						sx={{
							margin: "0rem",
						}}
					>
						Other plot dimensions :{" "}
					</Text>

					{property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length > 0
						? property_plot_dimensions
								.filter((item) => !plot_dimensions.includes(item))
								.map((item: string, index: number) => (
									<div key={index}>
										<TextContainer sx={{ justifyContent: "space-between", alignItems: "flex-start", gap: "1rem" }}>
											<TextField
												value={item.split(" x ")[0] === "0" ? "" : item.split(" x ")[0]}
												onChange={(event: any) => {
													const { value } = event.target;
													const other_plot_dimensions = property_plot_dimensions.filter(
														(item) => !plot_dimensions.includes(item),
													);
													const list = [...other_plot_dimensions];
													const dimensions = list[index].split(" x ");
													dimensions[0] = Number(value.replace(/\D/g, "")).toString();
													list[index] = dimensions.join(" x ");
													dispatch(
														setCreateNewPropertyPlotDimensionsThunk([
															...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
															...list,
														]),
													);

													const newValue = Number(value.replace(/\D/g, ""));
													const newErrorMinOtherPlot = [...errorMinOtherPlot];
													if (isNaN(newValue) || newValue <= 0) {
														newErrorMinOtherPlot[index] = true;
														setSubmitDisabled(true);
													} else {
														newErrorMinOtherPlot[index] = false;
														setSubmitDisabled(false);
													}
													setErrorMinOtherPlot(newErrorMinOtherPlot);
												}}
												label="Length"
												sx={{
													width: "45%",
													"& .MuiOutlinedInput-root": {
														borderRadius: "0.75rem",
													},
												}}
												autoComplete="off"
												error={errorMinOtherPlot[index]}
												helperText={errorMinOtherPlot[index] ? "Invalid number" : ""}
											/>

											<Typography
												variant="body1"
												sx={{ margin: "1rem 0rem 0rem 0rem" }}
											>
												x
											</Typography>

											<TextField
												value={item.split(" x ")[1] === "0" ? "" : item.split(" x ")[1]}
												inputProps={{
													min: 0,
												}}
												onChange={(event: any) => {
													const { value } = event.target;
													const other_plot_dimensions = property_plot_dimensions.filter(
														(item) => !plot_dimensions.includes(item),
													);
													const list = [...other_plot_dimensions];
													const dimensions = list[index].split(" x ");
													dimensions[1] = Number(value.replace(/\D/g, "")).toString();
													list[index] = dimensions.join(" x ");
													dispatch(
														setCreateNewPropertyPlotDimensionsThunk([
															...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
															...list,
														]),
													);

													const newValue = Number(value.replace(/\D/g, ""));
													const newErrorMaxOtherPlot = [...errorMaxOtherPlot];
													if (isNaN(newValue) || newValue <= 0) {
														newErrorMaxOtherPlot[index] = true;
														setSubmitDisabled(true);
													} else {
														newErrorMaxOtherPlot[index] = false;
														setSubmitDisabled(false);
													}
													setErrorMaxOtherPlot(newErrorMaxOtherPlot);
												}}
												label="Width"
												sx={{
													width: "45%",
												}}
												autoComplete="off"
												error={errorMaxOtherPlot[index]}
												helperText={errorMaxOtherPlot[index] ? "Invalid number" : ""}
											/>
											{propertyData.transaction_type === "buy" ? (
												<IconButton
													color="primary"
													sx={{ margin: "0.5rem 0rem 0rem 0rem" }}
													onClick={() => {
														const other_plot_dimensions = property_plot_dimensions.filter(
															(item) => !plot_dimensions.includes(item),
														);
														const list = [...other_plot_dimensions];
														list.splice(index, 1);
														dispatch(
															setCreateNewPropertyPlotDimensionsThunk([
																...property_plot_dimensions.filter((item) => plot_dimensions.includes(item)),
																...list,
															]),
														);
													}}
												>
													<DeleteOutline />
												</IconButton>
											) : null}
										</TextContainer>

										{property_plot_dimensions.filter((item) => !plot_dimensions.includes(item)).length - 1 === index &&
										propertyData.transaction_type === "buy" ? (
											<IconButton
												color="primary"
												onClick={() => {
													dispatch(setCreateNewPropertyPlotDimensionsThunk([...property_plot_dimensions, ""]));
												}}
												sx={{
													fontSize: "0.875rem",
													borderRadius: "0.5rem",
												}}
											>
												<Add sx={{ margin: "0rem 0.25rem 0rem 0rem" }} />
												Add More
											</IconButton>
										) : null}
									</div>
								))
						: dispatch(setCreateNewPropertyPlotDimensionsThunk([...property_plot_dimensions, ""]))}
				</TextContainer>
			) : // </TextContainer>
			null}

			{/* Total land Area */}

			{propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			((propertyData.transaction_type === "rent" &&
				(propertyData.property_type.includes("Land (Residential)") ||
					propertyData.property_type.includes("Land (Commercial)") ||
					propertyData.property_type.includes("Land (Agricultural)") ||
					propertyData.property_type.includes("Land (Industrial)") ||
					propertyData.property_type.includes("Mining"))) ||
				propertyData.transaction_type == "buy") ? (
				<TextContainer sx={{ alignItems: "flex-start" }}>
					<TextContainer
						sx={{
							width: "100%",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "0.5rem",
							[theme.breakpoints.down("sm")]: {
								margin: "0rem 0rem 0rem 0.5rem",
							},
						}}
					>
						<Text
							sx={{
								[theme.breakpoints.down("sm")]: {
									margin: "0.3rem 0rem 0.75rem 0rem",
								},
								margin: "0rem",
							}}
						>
							Total land area{" "}
						</Text>
						<Typography
							sx={{
								fontSize: "0.875rem",
								fontWeight: 400,
								lineHeight: "143%",
								letterSpacing: "0.009rem",
								color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
							}}
						>
							It is the size of the the entire land where the building stands.
						</Typography>
						<TextContainer sx={{ flexDirection: "row", gap: "1rem", marginTop: "0.5rem" }}>
							<TextField
								value={property_area.land_area.max.acre === 0 ? "" : property_area.land_area.max.acre}
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								autoComplete="off"
								inputProps={{
									min: 0,
								}}
								onChange={(event: any) => {
									dispatch(
										setCreateNewPropertyLandAreaThunk(
											// Number(
											event.target.value.replace(/[^0-9.]/g, ""),
											// )
										),
									);
									if (
										(propertyData.transaction_type == "resale" ||
											propertyData.transaction_type == "buy" ||
											(propertyData.transaction_type == "rent" &&
												(propertyData.property_type.includes("Land (Residential)") ||
													propertyData.property_type.includes("Land (Commercial)") ||
													propertyData.property_type.includes("Land (Agricultural)") ||
													propertyData.property_type.includes("Land (Industrial)") ||
													propertyData.property_type.includes("Plot") ||
													propertyData.property_type.includes("Farm Plot") ||
													propertyData.property_type.includes("Plot (Commercial)")))) &&
										isNaN(
											// Number(
											event.target.value.replace(/[^0-9.]/g, ""),
											// )
										)
									) {
										setErrorLandArea(true);
										setSubmitDisabled(true);
									} else {
										setErrorLandArea(false);
										setSubmitDisabled(false);
									}
								}}
								error={errorLandArea}
								helperText={errorLandArea ? "Enter a valid number" : ""}
								label={
									propertyData.transaction_type == "buy" ||
									(propertyData.transaction_type == "rent" &&
										(propertyData.property_type.includes("Land (Residential)") ||
											propertyData.property_type.includes("Land (Commercial)") ||
											propertyData.property_type.includes("Land (Agricultural)") ||
											propertyData.property_type.includes("Land (Industrial)") ||
											propertyData.property_type.includes("Mining")))
										? "Land area*"
										: "Land area"
								}
							/>

							<FormControl
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
							>
								<InputLabel id="demo-simple-select-helper-label">Select Unit</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={landAreaUnit}
									sx={{
										width: "100%",
										height: "3.5rem",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									label="Unit"
									onChange={(event: any) => {
										setLandAreaUnit(event.target.value);
										dispatch(setCreateNewPropertyLandAreaUnitThunk(event.target.value));
									}}
								>
									<MenuItem value="acre">acre</MenuItem>
									<MenuItem value="sqft">sqft</MenuItem>
									<MenuItem value="sqmt">sqm</MenuItem>
								</Select>
							</FormControl>
						</TextContainer>
					</TextContainer>
				</TextContainer>
			) : null}

			{/* Super Built Up Area */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer sx={{ alignItems: "flex-start" }}>
					{(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-living/PG")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Penthouse")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Row House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm Plot")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot (Commercial)")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-living/PG")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm Plot")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot (Commercial)")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "resale" &&
						propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Penthouse")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Row House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Factory")) ? (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "0.5rem",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem 0rem 0rem 0.5rem",
								},
							}}
						>
							<Text
								sx={{
									margin: "0.35rem 0rem 0.75rem 0rem",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.75rem 0rem",
									},
								}}
							>
								{propertyData.property_type.includes("Plot") ||
								propertyData.property_type.includes("Farm Plot") ||
								propertyData.property_type.includes("Plot (Commercial)")
									? "Plot area "
									: "Super built up area"}
							</Text>
							{propertyData.property_type.includes("Plot") ||
							propertyData.property_type.includes("Farm Plot") ||
							propertyData.property_type.includes("Plot (Commercial)") ? null : (
								<Typography
									sx={{
										fontSize: "0.875rem",
										fontWeight: 400,
										lineHeight: "143%",
										letterSpacing: "0.009rem",
										color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
									}}
								>
									It is the total area that includes the living space and a proportionate share of common areas and
									amenities.{" "}
								</Typography>
							)}
							<TextContainer sx={{ flexDirection: "row", gap: "1rem", marginTop: "0.5rem" }}>
								<TextField
									value={property_area.super_built_up_area.max.ft === 0 ? "" : property_area.super_built_up_area.max.ft}
									inputProps={{
										min: 0,
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									onChange={(event: any) => {
										dispatch(
											setCreateNewPropertyMinSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										dispatch(
											setCreateNewPropertyMaxSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
											setErrorSba(true);
											setSubmitDisabled(true);
										} else {
											setErrorSba(false);
											setSubmitDisabled(false);
										}
									}}
									error={errorSba}
									helperText={errorSba ? "Enter a valid number" : ""}
									label="Area*"
								/>
								<FormControl
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
								>
									<InputLabel id="demo-simple-select-helper-label">Unit</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										value={superBuiltUpAreaUnit}
										sx={{
											width: "100%",
											height: "3.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										label="Unit"
										onChange={(event: any) => {
											setSuperBuiltUpAreaUnit(event.target.value);
											setCarpetAreaUnit(event.target.value);
											dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(event.target.value));
										}}
									>
										<MenuItem value="sqft">sqft</MenuItem>
										<MenuItem value="sqmt">sqm</MenuItem>
									</Select>
								</FormControl>
							</TextContainer>
						</TextContainer>
					) : (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "0.5rem",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem 0rem 0rem 0.5rem",
								},
							}}
						>
							<Text
								sx={{
									margin: "0.35rem 0rem 0.75rem 0rem",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.75rem 0rem",
									},
								}}
							>
								{propertyData.property_type.includes("Plot") ||
								propertyData.property_type.includes("Farm Plot") ||
								propertyData.property_type.includes("Plot (Commercial)")
									? "Plot area "
									: "Super built up area"}
							</Text>
							{propertyData.property_type.includes("Plot") ||
							propertyData.property_type.includes("Farm Plot") ||
							propertyData.property_type.includes("Plot (Commercial)") ? null : (
								<Typography
									sx={{
										fontSize: "0.875rem",
										fontWeight: 400,
										lineHeight: "143%",
										letterSpacing: "0.009rem",
										color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
									}}
								>
									It is the total area that includes the living space and a proportionate share of common areas and
									amenities.{" "}
								</Typography>
							)}
							<TextContainer
								sx={{
									flexDirection: "row",
									gap: "1rem",
									marginTop: "0.5rem",
									[theme.breakpoints.down("sm")]: {
										flexDirection: "column",
									},
								}}
							>
								<TextField
									value={property_area.super_built_up_area.min.ft === 0 ? "" : property_area.super_built_up_area.min.ft}
									inputProps={{
										min: 0,
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									autoComplete="off"
									onChange={(event: any) => {
										dispatch(
											setCreateNewPropertyMinSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										if (
											(property_area.super_built_up_area.max.ft !== 0 &&
												Number(event.target.value.replace(/\D/g, "")) > property_area.super_built_up_area.max.ft) ||
											isNaN(Number(event.target.value.replace(/\D/g, "")))
										) {
											setErrorMinSba(true);
											setErrorMaxSba(true);
											setSubmitDisabled(true);
										} else {
											setErrorMinSba(false);
											setErrorMaxSba(false);
											setSubmitDisabled(false);
										}
									}}
									error={errorMinSba}
									helperText={errorMinSba ? "Value is more than max area" : ""}
									label="Minimum Area*"
								/>

								<TextField
									value={property_area.super_built_up_area.max.ft === 0 ? "" : property_area.super_built_up_area.max.ft}
									inputProps={{
										min: 0,
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									autoComplete="off"
									onChange={(event: any) => {
										dispatch(
											setCreateNewPropertyMaxSuperBuiltUpAreaThunk(Number(event.target.value.replace(/\D/g, ""))),
										);
										if (
											(property_area.super_built_up_area.min.ft !== 0 &&
												Number(event.target.value.replace(/\D/g, "")) < property_area.super_built_up_area.min.ft) ||
											isNaN(Number(event.target.value.replace(/\D/g, "")))
										) {
											setErrorMinSba(true);
											setErrorMaxSba(true);
											setSubmitDisabled(true);
										} else {
											setErrorMinSba(false);
											setErrorMaxSba(false);
											setSubmitDisabled(false);
										}
									}}
									error={errorMaxSba}
									helperText={errorMaxSba ? "Value is less than min area" : ""}
									label="Maximum Area*"
								/>

								<FormControl
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
								>
									<InputLabel id="demo-simple-select-helper-label">Unit</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										value={superBuiltUpAreaUnit}
										sx={{
											width: "100%",
											height: "3.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										label="Unit"
										onChange={(event: any) => {
											setSuperBuiltUpAreaUnit(event.target.value);
											setCarpetAreaUnit(event.target.value);
											dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(event.target.value));
										}}
									>
										<MenuItem value="sqft">sqft</MenuItem>
										<MenuItem value="sqmt">sqm</MenuItem>
									</Select>
								</FormControl>
							</TextContainer>
						</TextContainer>
					)}
				</TextContainer>
			) : null}

			{/* Carpet area is % of super built up area */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Mining") ? (
				<FormGroup
					sx={{
						margin: "0rem",
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<FormControlLabel
						labelPlacement="start"
						control={
							<Switch
								checked={ca_is_percentage_of_sba}
								onChange={(event: any) => {
									setCaIsPercentageOfSba(event.target.checked);
								}}
							/>
						}
						sx={{
							display: "flex",
							flexDirection: "row-reverse",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							margin: "0rem ",
							"& .MuiFormControlLabel-label": {
								fontSize: "0.875rem",
							},
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label="Carpet area is % of super built up area"
					/>
				</FormGroup>
			) : null}

			{/* Carpet Area */}

			{propertyData.transaction_type !== "ofb" &&
			propertyData.transaction_type !== "" &&
			propertyData.property_category !== "" &&
			propertyData.property_type.length !== 0 &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Co-living/PG") &&
			!propertyData.property_type.includes("Warehouse") &&
			!propertyData.property_type.includes("Factory") &&
			!propertyData.property_type.includes("Mining") ? (
				<TextContainer sx={{ alignItems: "flex-start" }}>
					{(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Factory")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Independent House")) ||
					(propertyData.transaction_type == "resale" &&
						propertyData.property_type.includes("Builder Floor Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Building")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Shop")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Office Space")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-working")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm House")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Warehouse")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Apartment")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Villa")) ||
					(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Factory")) ? (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								margin: "0rem ",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem 0rem 0rem 0.5rem",
								},
							}}
						>
							<Text
								sx={{
									margin: "0.35rem 0rem 0.75rem 0rem",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.75rem 0rem",
									},
								}}
							>
								Carpet area{" "}
							</Text>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "143%",
									letterSpacing: "0.009rem",
									color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
								}}
							>
								It is the usable space within an apartment, excluding the thickness of the walls and other non-usable
								area{" "}
							</Typography>
							<TextField
								value={
									ca_is_percentage_of_sba
										? maxCarpetAreaPercentage === 0
											? ""
											: maxCarpetAreaPercentage
										: property_area.carpet_area.max.ft === 0
											? ""
											: property_area.carpet_area.max.ft
								}
								inputProps={{
									min: 0,
								}}
								sx={{
									width: "100%",
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								onChange={(event: any) => {
									setMinCarpetAreaPercentage(Number(event.target.value.replace(/\D/g, "")));
									setMaxCarpetAreaPercentage(Number(event.target.value.replace(/\D/g, "")));

									if (ca_is_percentage_of_sba) {
										dispatch(
											setCreateNewPropertyMinCarpetAreaThunk(
												parseFloat(
													(
														property_area.super_built_up_area.min.ft *
														(Number(event.target.value.replace(/\D/g, "")) / 100)
													).toFixed(2),
												),
											),
										);
										dispatch(
											setCreateNewPropertyMaxCarpetAreaThunk(
												parseFloat(
													(
														property_area.super_built_up_area.max.ft *
														(Number(event.target.value.replace(/\D/g, "")) / 100)
													).toFixed(2),
												),
											),
										);
									} else {
										dispatch(setCreateNewPropertyMinCarpetAreaThunk(Number(event.target.value.replace(/\D/g, ""))));
										dispatch(setCreateNewPropertyMaxCarpetAreaThunk(Number(event.target.value.replace(/\D/g, ""))));
									}
								}}
								autoComplete="off"
								label={ca_is_percentage_of_sba ? "Percentage (%)" : "Area"}
							/>
						</TextContainer>
					) : (
						<TextContainer
							sx={{
								width: "100%",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								margin: "0rem",
								[theme.breakpoints.down("sm")]: {
									margin: "0rem 0rem 0rem 0.5rem",
								},
							}}
						>
							<Text
								sx={{
									margin: "0.3rem 0rem 0.75rem 0rem",
									[theme.breakpoints.down("sm")]: {
										margin: "0.3rem 0rem 0.75rem 0rem",
									},
								}}
							>
								Carpet area{" "}
							</Text>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "143%",
									letterSpacing: "0.009rem",
									color: theme.palette.mode === "light" ? "#666666" : "#ffffff",
								}}
							>
								It is the usable space within an apartment, excluding the thickness of the walls and other non-usable
								area{" "}
							</Typography>
							<TextContainer sx={{ flexDirection: "row", gap: "1rem", marginTop: "0.5rem" }}>
								<TextField
									value={
										ca_is_percentage_of_sba
											? minCarpetAreaPercentage === 0
												? ""
												: minCarpetAreaPercentage
											: property_area.carpet_area.min.ft === 0
												? ""
												: property_area.carpet_area.min.ft
									}
									inputProps={{
										min: 0,
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									onChange={(event: any) => {
										setMinCarpetAreaPercentage(Number(event.target.value.replace(/\D/g, "")));
										setMaxCarpetAreaPercentage(Number(event.target.value.replace(/\D/g, "")));
										if (ca_is_percentage_of_sba) {
											dispatch(
												setCreateNewPropertyMinCarpetAreaThunk(
													parseFloat(
														(
															property_area.super_built_up_area.min.ft *
															(Number(event.target.value.replace(/\D/g, "")) / 100)
														).toFixed(2),
													),
												),
											);
											dispatch(
												setCreateNewPropertyMaxCarpetAreaThunk(
													parseFloat(
														(
															property_area.super_built_up_area.max.ft *
															(Number(event.target.value.replace(/\D/g, "")) / 100)
														).toFixed(2),
													),
												),
											);
										} else {
											dispatch(setCreateNewPropertyMinCarpetAreaThunk(Number(event.target.value.replace(/\D/g, ""))));
											if (
												property_area.carpet_area.max.ft !== 0 &&
												Number(event.target.value.replace(/\D/g, "")) > property_area.carpet_area.max.ft
											) {
												setErrorMinCa(true);
												setErrorMaxCa(true);
												setSubmitDisabled(true);
											} else {
												setErrorMinCa(false);
												setErrorMaxCa(false);
												setSubmitDisabled(false);
											}
										}
									}}
									error={errorMinCa}
									autoComplete="off"
									helperText={errorMinCa ? "Value is more than max area" : ""}
									label={ca_is_percentage_of_sba ? "Percentage (%)" : "Minimum Area"}
								/>

								{ca_is_percentage_of_sba ? null : (
									<TextField
										value={
											ca_is_percentage_of_sba
												? maxCarpetAreaPercentage === 0
													? ""
													: maxCarpetAreaPercentage
												: property_area.carpet_area.max.ft === 0
													? ""
													: property_area.carpet_area.max.ft
										}
										inputProps={{
											min: 0,
										}}
										sx={{
											width: "100%",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										onChange={(event: any) => {
											dispatch(setCreateNewPropertyMaxCarpetAreaThunk(Number(event.target.value.replace(/\D/g, ""))));
											if (
												property_area.carpet_area.min.ft !== 0 &&
												Number(event.target.value.replace(/\D/g, "")) < property_area.carpet_area.min.ft
											) {
												setErrorMinCa(true);
												setErrorMaxCa(true);
												setSubmitDisabled(true);
											} else {
												setErrorMinCa(false);
												setErrorMaxCa(false);
												setSubmitDisabled(false);
											}
										}}
										error={errorMaxCa}
										autoComplete="off"
										helperText={errorMaxCa ? "Value is less than min area" : ""}
										label="Maximum Area"
									/>
								)}
							</TextContainer>
						</TextContainer>
					)}
				</TextContainer>
			) : null}
			{/* <Typography
				sx={{
					fontSize: "1rem",
					fontWeight: 400,
					lineHeight: "150%",
					color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
				}}
			>
				Fields marked * are mandatory fields.
			</Typography> */}
		</PaperContainer>
	);
};

export default PropertyConfig;
