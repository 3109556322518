const NumberInWords: Function = (num: any) => {
  num = String(num).replace(/\D/g, "");
  // const ones = [
  //   "zero",
  //   "one",
  //   "two",
  //   "three",
  //   "four",
  //   "five",
  //   "six",
  //   "seven",
  //   "eight",
  //   "nine",
  // ];
  // const teens = [
  //   "ten",
  //   "eleven",
  //   "twelve",
  //   "thirteen",
  //   "fourteen",
  //   "fifteen",
  //   "sixteen",
  //   "seventeen",
  //   "eighteen",
  //   "nineteen",
  // ];
  // const tens = [
  //   "twenty",
  //   "thirty",
  //   "forty",
  //   "fifty",
  //   "sixty",
  //   "seventy",
  //   "eighty",
  //   "ninety",
  // ];

  // if (number < 10) {
  //   return ones[number];
  // } else if (number >= 10 && number < 20) {
  //   return teens[number - 10];
  // } else if (number >= 20 && number < 100) {
  //   const tensDigit = Math.floor(number / 10);
  //   const onesDigit = number % 10;
  //   return `${tens[tensDigit - 2]}${onesDigit ? `-${ones[onesDigit]}` : ""}`;
  // } else if (number >= 100 && number < 1000) {
  //   const hundredsDigit = Math.floor(number / 100);
  //   const tensDigit = Math.floor((number % 100) / 10);
  //   const onesDigit = number % 10;
  //   return `${ones[hundredsDigit]} hundred${
  //     tensDigit || onesDigit
  //       ? ` ${tens[tensDigit - 2]}${onesDigit ? `-${ones[onesDigit]}` : ""}`
  //       : ""
  //   }`;
  // } else if (number >= 1000 && number < 100000) {
  //   const thousandsDigit = Math.floor(number / 1000);
  //   const hundredsDigit = Math.floor((number % 1000) / 100);
  //   const tensDigit = Math.floor((number % 100) / 10);
  //   const onesDigit = number % 10;
  //   return `${NumberInWords(thousandsDigit)} thousand${
  //     hundredsDigit || tensDigit || onesDigit
  //       ? ` ${NumberInWords(hundredsDigit)} hundred${
  //           tensDigit || onesDigit
  //             ? ` ${tens[tensDigit - 2]}${
  //                 onesDigit ? `-${ones[onesDigit]}` : ""
  //               }`
  //             : ""
  //         }`
  //       : ""
  //   }`;
  // } else if (number >= 100000 && number < 10000000) {
  //   const lakhsDigit = Math.floor(number / 100000);
  //   const thousandsDigit = Math.floor((number % 100000) / 1000);
  //   const hundredsDigit = Math.floor((number % 1000) / 100);
  //   const tensDigit = Math.floor((number % 100) / 10);
  //   const onesDigit = number % 10;
  //   return `${NumberInWords(lakhsDigit)} lakh${
  //     thousandsDigit || hundredsDigit || tensDigit || onesDigit
  //       ? ` ${NumberInWords(thousandsDigit)} thousand${
  //           hundredsDigit || tensDigit || onesDigit
  //             ? ` ${NumberInWords(hundredsDigit)} hundred${
  //                 tensDigit || onesDigit
  //                   ? ` ${tens[tensDigit - 2]}${
  //                       onesDigit ? `-${ones[onesDigit]}` : ""
  //                     }`
  //                   : ""
  //               }`
  //             : ""
  //         }`
  //       : ""
  //   }`;
  // } else if (number >= 10000000) {
  //   const croresDigit = Math.floor(number / 10000000);
  //   const lakhsDigit = Math.floor((number % 10000000) / 100000);
  //   const thousandsDigit = Math.floor((number % 1000000) / 1000);
  //   const hundredsDigit = Math.floor((number % 1000) / 100);
  //   const tensDigit = Math.floor((number % 100) / 10);
  //   const onesDigit = number % 10;
  //   return `${NumberInWords(croresDigit)} crore${
  //     lakhsDigit || thousandsDigit || hundredsDigit || tensDigit || onesDigit
  //       ? ` ${NumberInWords(lakhsDigit)} lakh${
  //           thousandsDigit || hundredsDigit || tensDigit || onesDigit
  //             ? ` ${NumberInWords(thousandsDigit)} thousand${
  //                 hundredsDigit || tensDigit || onesDigit
  //                   ? ` ${NumberInWords(hundredsDigit)} hundred${
  //                       tensDigit || onesDigit
  //                         ? ` ${tens[tensDigit - 2]}${
  //                             onesDigit ? `-${ones[onesDigit]}` : ""
  //                           }`
  //                         : ""
  //                     }`
  //                   : ""
  //               }`
  //             : ""
  //         }`
  //       : ""
  //   }`;
  // }

  const single = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const double = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const formatTenth = (digit: any, prev: any) => {
    return 0 == digit ? "" : " " + (1 == digit ? double[prev] : tens[digit]);
  };
  const formatOther = (digit: any, next: any, denom: any) => {
    return (
      (0 != digit && 1 != next ? " " + single[digit] : "") +
      (0 != next || digit > 0 ? " " + denom : "")
    );
  };
  let res = "";
  let index = 0;
  let digit = 0;
  let next = 0;
  let words = [];
  if (((num += ""), isNaN(parseInt(num)))) {
    res = "";
  } else if (parseInt(num) > 0 && num.length <= 10) {
    for (index = num.length - 1; index >= 0; index--)
      switch (
        ((digit = num[index] - 0),
        (next = index > 0 ? num[index - 1] - 0 : 0),
        num.length - index - 1)
      ) {
        case 0:
          words.push(formatOther(digit, next, ""));
          break;
        case 1:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? " " +
                  single[digit] +
                  " Hundred" +
                  (0 != num[index + 1] && 0 != num[index + 2] ? " and" : "")
              : ""
          );
          break;
        case 3:
          words.push(formatOther(digit, next, "Thousand"));
          break;
        case 4:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 5:
          words.push(formatOther(digit, next, "Lakh"));
          break;
        case 6:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 7:
          words.push(formatOther(digit, next, "Crore"));
          break;
        case 8:
          words.push(formatTenth(digit, num[index + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? " " +
                  single[digit] +
                  " Hundred" +
                  (0 != num[index + 1] || 0 != num[index + 2]
                    ? " and"
                    : " Crore")
              : ""
          );
      }
    res = words.reverse().join("");
  } else res = "";
  return res;
};

export default NumberInWords;
