/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
			sx={{
				zindex: 8,
			}}
		/>
	);
});

/* Styled Components */

const Snack = styled(Snackbar)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "auto",
	margin: "2.5rem 0rem 0rem 0rem",
	zindex: 8,
	".&MuiSnackbar-root": {
		top: "0px",
		zindex: 8,
	},
}));

const Heading2 = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 400,
	fontSize: "0.875rem",
	lineHeight: "1.251rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 0rem",
	color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#666",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	textTransform: "capitalize",
}));

const PropertyCardConfigAndContact = ({ config }: { config: string | null | undefined }) => {
	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%",
					margin: "0rem 0rem 0rem 0rem",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						width: "46%",
					}}
				>
					<Typography
						variant="body2"
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							maxWidth: "100%",
							letterSpacing: "0.009rem",
							"&:hover": {
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							},
						}}
					>
						{config}
					</Typography>
					<Heading2>Configuration</Heading2>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PropertyCardConfigAndContact;
