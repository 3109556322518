/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { PublishServiceThunk } from "../../../redux-magic/store";

/* Component Imports */

import {
	Dialog,
	DialogTitle,
	DialogActions,
	// IconButton,
	Alert,
	DialogContent,
	useMediaQuery,
	useTheme,
	CircularProgress,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import ServiceCard from "../../cards/ServiceCard/ServiceCard";
import {
	BusinessProfileInterface,
	ServiceInterface,
	UserInterface,
} from "../../../redux-magic/sub-interfaces/sub-interfaces";
import { ObjectId } from "mongodb";

/* Icon Imports */

// import CloseIcon from "@mui/icons-material/Close";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
	[theme.breakpoints.down("sm")]: {
		top: "5rem",
		bottom: "5rem",
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	fontSize: "1.25rem",
	fontWeight: 400,
	background: theme.palette.background.default,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1rem 1rem 1rem 1rem",
	background: theme.palette.background.default,
	[theme.breakpoints.down("sm")]: { padding: "0rem 0rem 0rem 0rem" },
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0rem 1rem 1rem 0rem",
	background: theme.palette.background.default,
}));

interface LocalServiceInterface extends Omit<ServiceInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

const PublishButtonModal = ({
	serviceData,
	open,
	handleClose,
	dispatch,
	profile_context,
	isEditor,
}: {
	serviceData: LocalServiceInterface;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	isEditor: boolean;
}) => {
	const theme = useTheme();

	const profile_link = serviceData
		? serviceData.created_by.is_business_profile
			? process.env.PRODUCTION_URL +
				`${(serviceData.created_by.business_profile_id as BusinessProfileInterface).business_profile_type}s/${(serviceData.created_by.business_profile_id as BusinessProfileInterface).slug}`
			: process.env.PRODUCTION_URL + `users/${(serviceData.created_by.user_id as UserInterface)._id}`
		: "";

	const [openPublishSnack, setOpenPublishSnack] = React.useState<boolean>(false);

	const [openUnpublishSnack, setOpenUnpublishSnack] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const handleCloseSnack = () => {
		setOpenPublishSnack(false);
		setOpenUnpublishSnack(false);
	};

	const Publish = async () => {
		setLoad(true);
		await dispatch(
			PublishServiceThunk({
				service_id: serviceData ? serviceData._id : "",
				status: status === "unpublish" ? "untouched" : "publish_requested",
				creator_is_business_profile: isEditor
					? profile_context.is_business_profile
					: serviceData.created_by.is_business_profile,
				creator_user_id: isEditor ? profile_context.user_id : (serviceData.created_by.user_id as UserInterface)._id,
				creator_business_profile_id: isEditor
					? profile_context.business_profile_id
					: (serviceData.created_by.business_profile_id as BusinessProfileInterface)._id,
			}),
		);
		handleClose();
		if (serviceData.published) {
			setOpenUnpublishSnack(true);
		} else {
			setOpenPublishSnack(true);
		}
		setLoad(false);
		setTimeout(() => {
			window.open(profile_link, "_self");
		}, 1000);
	};

	const status = serviceData && serviceData.status === "published" ? "unpublish" : "publish";

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openUnpublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert onClose={handleCloseSnack} severity="success" sx={{ width: "100%" }}>
					Servcie has been unpublished
				</Alert>
			</Snackbar>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openPublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert onClose={handleCloseSnack} severity="success" sx={{ width: "100%" }}>
					We will notify you once the servcie is published
				</Alert>
			</Snackbar>

			<CustomDialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={useMediaQuery(theme.breakpoints.down("sm")) ? true : false}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					{!serviceData.published ? "Service card preview" : "Are you sure you want to unpublish this service?"}
				</CustomDialogTitle>

				{!serviceData.published ? (
					<CustomDialogContent>
						<ServiceCard
							service_id={serviceData._id}
							loading={false}
							profile_context={profile_context}
							session={null}
							dispatch={dispatch}
							slug={serviceData.slug}
							title={serviceData.title}
							images={serviceData.images}
							tags={serviceData.tags}
							location={serviceData.location ? serviceData.location.title : ""}
							price={serviceData.price}
							service_provider={serviceData.service_provider ? serviceData.service_provider.name : ""}
							service_subtype={serviceData.service_subtype}
							saved_content={serviceData.saved_content}
							boosting={serviceData.boost_information}
							preview={true}
						/>
					</CustomDialogContent>
				) : null}

				<CustomDialogActions>
					<BeegruButton flavor="primary" variant="outlined" onClick={handleClose} name="cancel" type="button">
						{!serviceData.published ? "Cancel" : "No"}
					</BeegruButton>
					{load ? (
						<CircularProgress
							// size={24}
							sx={{
								marginTop: "1rem",
							}}
						/>
					) : (
						<BeegruButton flavor="primary" variant="contained" onClick={Publish} name="publish" type="button">
							{!serviceData.published ? "Publish" : "Yes"}
						</BeegruButton>
					)}
				</CustomDialogActions>
			</CustomDialog>
		</React.Fragment>
	);
};

export default PublishButtonModal;
