/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Price } from "../../../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";

/* Library Imports */

import PreparePriceString from "../../../../../../lib/PreparePriceString";


/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 400,
	fontSize: "1rem",
	lineHeight: "1.625rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
	margin: "0rem",
}));

const GridCardPrice = ({ price }: { price: Price;}) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Heading>
				<InfoIcon
					src={
						theme.palette.mode === "dark"
							? "/images/icons/config/currency_rupee_white.svg"
							: "/images/icons/config/currency_rupee.svg"
					}
					alt="Price Icon"
					width={17}
					height={24}
					loading="lazy"
					referrerPolicy="no-referrer"
				/>
				{price !== undefined
					? price
						? price.min === price.max
							? PreparePriceString(price.max)
							: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
						: "Price not available"
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardPrice;
