/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const ChipPacket = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  margin: "0rem 0rem 0rem 0rem",
  overflowX: "scroll",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  // scrollbarWidth: "none",
}));

const Chip = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.125rem 0.5rem 0.125rem 0.5rem",
  margin: "0rem 0.5rem 0rem 0rem",
  borderRadius: "8px",
  whiteSpace: "nowrap",
  background: theme.palette.mode === "dark" ? "#919EAB14" : "#fafafa",
}));

const ChipText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "0.75rem",
  lineHeight: "1.125rem",
  color: theme.palette.mode === "dark" ? "#ffffff" : "#00000099",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "space-between",
  width: "100%",
}));

const PropertyCardChips = ({
  loading,
  transaction_type,
  property_type,
  tags,
  property_status,
  location,
}: {
  loading: boolean;
  transaction_type: string;
  property_type: Array<string>;
  tags: Array<string>;
  property_status: Array<string>;
  location: string;
}) => {
  if (loading) {
    return (
      <React.Fragment>
        <SkeletonSection>
          <Skeleton
            animation="wave"
            variant="text"
            width="20%"
            height="auto"
            sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="20%"
            height="auto"
            sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="20%"
            height="auto"
            sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="20%"
            height="auto"
            sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
          />
        </SkeletonSection>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <ChipPacket>
        {transaction_type !== undefined && transaction_type !== "" ? (
          <Chip>
            <ChipText>
              {transaction_type === "buy"
                ? "Sale"
                : transaction_type.charAt(0).toUpperCase() +
                  transaction_type.slice(1)}
            </ChipText>
          </Chip>
        ) : null}

        {property_type !== undefined && property_type.length > 0 ? (
          <Chip>
            <ChipText>
              {property_type[0].charAt(0).toUpperCase() +
                property_type[0].slice(1)}
            </ChipText>
          </Chip>
        ) : null}

        {property_status !== undefined &&
        property_status.length > 0 &&
        (!property_status.includes("Select date") ||
          !property_status.includes("select_date")) ? (
          <Chip>
            <ChipText>
              {property_status
                .map(
                  (element: any) =>
                    element.charAt(0).toUpperCase() +
                    element.slice(1).replace(/_/g, " ")
                )
                .join(", ")}
            </ChipText>
          </Chip>
        ) : null}

        {location !== undefined && location !== "" ? (
          <Chip>
            <ChipText>{location} </ChipText>
          </Chip>
        ) : null}

        {/* {tags.map((tag: string, key: number) => {
          return (
            <Chip key={key}>
              <ChipText>{tag.charAt(0).toUpperCase() + tag.slice(1)}</ChipText>
            </Chip>
          );
        })} */}
      </ChipPacket>
    </React.Fragment>
  );
};

export default PropertyCardChips;
