import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../../redux-magic/state-interface";
import { setCreateNewPropertyUploadImagesThunk } from "../../../../../redux-magic/thunks";
import { DbUserPage } from "../../../../../redux-magic/sub-interfaces/user-interface";
import { PropertyInterface, BusinessProfileInterface, UserInterface } from "../../../../../models";

/* Component Imports */

import {
	Typography,
	IconButton,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Snackbar,
	Alert,
	useTheme,
	SelectChangeEvent,
	FormControl,
	Select,
	MenuItem,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import uploadFiles from "../../../../../lib/uploadFiles";
import validateFiles from "../../../../../lib/validateFiles";
import { useRouter } from "next/router";

/* Icon Imports */

import { Close, Backup, DeleteOutline } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { ObjectId } from "bson";

/* Interfaces */

interface FileAndMore {
	file: File;
	mime_type: string;
}

/* Styled Components */

const CustomDialog = styled("div")(({ theme }) => ({
	// padding: "1rem 1rem 1rem 1rem",
	width: "100%",
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	width: "100%",
	fontWeight: "500",
	fontSize: "1.125rem",
	lineHeight: "1.25rem",
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "2rem 1rem 1rem 1rem",
	background: theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0rem 1rem 1rem 0rem",
	background: theme.palette.background.paper,
}));

const InputContent = styled("input")(({ theme }) => ({
	display: "none",
}));

const UploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	border: "2px dashed #EE4B2B",
	borderRadius: "8px",
	cursor: "pointer",
	borderColor: theme.palette.primary.main,
	height: "10rem",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "10.563rem",
	height: "9.625rem",
	border: "1px solid rgba(0, 0, 0, 0.23)",
	borderRadius: "8px",
	borderColor: theme.palette.primary.main,
	[theme.breakpoints.down("xs")]: {
		flexDirection: "column",
	},
}));

const SecondaryUploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "10.563rem",
	height: "9.625rem",
	border: "2px dashed #EE4B2B",
	borderRadius: "8px",
	cursor: "pointer",
	borderColor: theme.palette.primary.main,
}));

const ImageContainer = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "10.563rem",
}));

const FileNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	flexWrap: "wrap",
}));

const PreviewImage = styled("img")(({ theme }) => ({
	// objectFit: "contain",
	width: "10.4rem",
	height: "6.238rem",
	borderRadius: "8px 8px 0px 0px",
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

const FormControlContainer = styled(FormControl)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	padding: "var(--Spacing-spacing-sm, 0.5rem)",
	// width: "10.563rem",
	gap: "var(--Spacing-spacing-sm, 0.5rem)",
	alignSelf: "stretch",
	width: "100%",
	height: "3rem",
}));

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	position: "absolute",
	width: "100%",
	height: "100%",
	zIndex: 0,
}));

const ImagePreviewContainer = styled(RadioGroup)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	flexWrap: "wrap",
}));

const UploadImages = ({
	newPropertyData,
	session,
	dispatch,
	businessProfiles,
	setImgPreview,
	isBusinessProfile,
	isNewBusinessProfile,
}: {
	newPropertyData: StateInterface["new_property_data"];
	session: any;
	dispatch: Function;
	businessProfiles: DbUserPage;
	setImgPreview: React.Dispatch<React.SetStateAction<string>>;
	isBusinessProfile: boolean;
	isNewBusinessProfile: boolean;
}) => {
	const theme = useTheme();

	const input = React.useRef<HTMLInputElement>(null);
	const [selectedFiles, setSelectedFiles] = React.useState<Array<FileAndMore>>([]);
	const [percentage, setPercentage] = React.useState<Array<number>>([0]);
	const [stage, setStage] = React.useState<Array<string>>(["NOT_STARTED"]);
	const [fileId, setFileId] = React.useState<Array<ObjectId | null>>([null]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [selectedFilePreview, setSelectedFilePreview] = React.useState<Array<string>>([]);
	const [fileErrorSnackbarText, setFileErrorSnackbarText] = React.useState<string>("File size or type not supported");
	const [category, setCategory] = React.useState<Array<string>>([]);
	const [load, setLoad] = React.useState<boolean>(false);
	const [isFileDialogOpen, setFileDialogOpen] = React.useState(false);
	let uploadImages:
		| Array<{
				file_id: ObjectId;
				mime_type: string;
				category: string;
				caption: string;
				priority: number;
		  }>
		| undefined = undefined;

	/*
	
	& Next, let's declare the priority state. This state will be used to hold the index number of the cover image selected by the user .

	*/

	const [priority, setPriority] = React.useState<number>(0);

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

	const router = React.useRef(useRouter()).current;

	interface UploadProgress {
		fileId: ObjectId | null;
		percentage: number;
		stage: string;
	}

	const handleUpload = async (validFiles: Array<FileAndMore>) => {
		setLoad(true);
		await uploadFiles(
			session ? (session.authorized ? (session.session_id ? session.session_id : "") : "") : "",
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: isBusinessProfile || isNewBusinessProfile ? true : false,
				business_profile_id: businessProfiles ? new ObjectId(businessProfiles.page_id) : new ObjectId(),
				entity: "property_regular",
				// entity_id: newPropertyData ? new ObjectId(newPropertyData._id) : new ObjectId(),
				entity_id: new ObjectId(),
				use_case: "property_image",
				uiPath: "/",
				isPublic: false,
				text: businessProfiles ? businessProfiles.page_title : "",
			})),
			(uploadProgress: Array<UploadProgress>) => {
				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				fileId[0] != null ? setFileId([...fileId, ...data]) : setFileId(data);
				setPercentage(uploadProgress.map((progress) => progress.percentage));
				stage[0] != "NOT_STARTED" ? setStage([...stage, ...stageInfo]) : setStage(stageInfo);
			},
		);
		setLoad(false);
	};

	const handleNext = async () => {
		uploadImages = fileId.map((file_id: ObjectId, index: number) => ({
			file_id: file_id ? new ObjectId(file_id) : new ObjectId(),
			mime_type: selectedFiles[index].mime_type,
			category: category[index],
			caption: "",
			priority: priority === index ? 0 : 1,
		}));
	};

	React.useEffect(() => {
		if (selectedFiles.length > 0 && !load) {
			dispatch(
				setCreateNewPropertyUploadImagesThunk(
					fileId.map((file_id: ObjectId, index: number) => ({
						file_id: file_id,
						mime_type: selectedFiles[index].mime_type,
						category: category[index],
						caption: "",
						priority: priority === index ? 0 : 1,
					})),
				),
			);
		}
	}, [fileId, category, priority, dispatch, selectedFiles, load]);

	const handleRemoveImage = async (index: number) => {
		const newSelectedFilePreview = [...selectedFilePreview];
		const newSelectedFiles = [...selectedFiles];
		const newFileId = [...fileId];
		const newStage = [...stage];

		newSelectedFilePreview.splice(index, 1);
		newSelectedFiles.splice(index, 1);
		newFileId.splice(index, 1);
		newStage.splice(index, 1);

		// Update the state with the new arrays
		setSelectedFilePreview(newSelectedFilePreview);
		setSelectedFiles(newSelectedFiles);
		setFileId(newFileId);
		setStage(newStage);
	};

	const handleUploadClose = () => {
		setSelectedFiles([]);
		setSelectedFilePreview([]);
		setLoading(false);
		setStage(["NOT_STARTED"]);
		setCategory(["all_images"]);
		setFileId([null]);
		setPercentage([0]);
	};

	const handleSelect = (event: SelectChangeEvent, index: number) => {
		setCategory((prev) => {
			const updated = [...prev];
			updated[index] = event.target.value;
			return updated;
		});
	};

	React.useEffect(() => {
		if (isFileDialogOpen && input.current) {
			input.current.value = "";
			input.current.click();
			// Set isFileDialogOpen to false after opening the dialog
			setFileDialogOpen(false);
		}
	}, [isFileDialogOpen]);
	return (
		<React.Fragment>
			{/* Upload Image Modal */}
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity="error"
					sx={{ width: "100%" }}
				>
					{fileErrorSnackbarText}
				</Alert>
			</Snack>
			<CustomDialog>
				<CustomDialogContent>
					<InputContent
						ref={input}
						type="file"
						accept="image/jpeg, image/png"
						multiple={true}
						onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
							const files = event.target.files;
							const customMimeTypes = ["image/jpeg", "image/png"];
							const customValidExtensions = ["jpg", "jpeg", "png"];
							const uploadType: string = "property_or_services";
							if (files && files.length > 0) {
								const {
									validFiles,
									invalidFiles,
								}: {
									validFiles: Array<FileAndMore>;
									invalidFiles: Array<File>;
								} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
								if (validFiles.length > 0) {
									setSelectedFilePreview([
										...selectedFilePreview,
										...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
									]);
									setImgPreview(URL.createObjectURL(validFiles[0].file));
									setSelectedFiles([...selectedFiles, ...validFiles]);
									setCategory(validFiles.map(() => "exterior"));
									handleUpload(validFiles);
								}
								if (invalidFiles.length > 0) {
									const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
									setFileErrorSnackbarText(
										(fileNames.join(", ").length > 15
											? fileNames.join(", ").slice(0, 15) + "..."
											: fileNames.join(", ")) +
											(invalidFiles.length === 1 ? " is an" : " are") +
											" unsupported " +
											(invalidFiles.length === 1 ? "file" : "files") +
											" and " +
											(invalidFiles.length === 1 ? "has" : "have") +
											" been deselected automatically.",
									);
									setOpenSnackbar(true);
								} else {
									setOpenSnackbar(false);
								}
							} else {
								setOpenSnackbar(true);
							}
						}}
					/>
					{selectedFiles.length === 0 ? (
						<UploadContent
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (input.current) {
									input.current.value = "";
									input.current.click();
								}
							}}
						>
							<Backup sx={{ color: theme.palette.primary.main }} />
							{/* <Typography>Add More</Typography> */}
							<Typography sx={{ color: "#9E9E9E" }}>JPEG or PNG</Typography>
							<Typography sx={{ color: "#9E9E9E" }}>Image max size 10MB</Typography>
						</UploadContent>
					) : null}
					<ImagePreviewContainer>
						{/* <RadioGroup> */}
						{selectedFilePreview.map((file: string, index: number) => (
							<ContentContainer key={index}>
								{file !== "" ? (
									<ImageContainer>
										<PreviewImage
											alt="Property Images"
											src={file}
											referrerPolicy="no-referrer"
										/>
										<FormControlLabel
											value={index}
											control={
												<Radio
													sx={{
														padding: "0rem",
													}}
													size="small"
													onClick={() => setPriority(index)}
												/>
											}
											label={
												<Typography
													sx={{
														color: "var(--Primary-New-Contrast, #FFF)",
														fontSize: "0.625rem",
														fontWeight: 400,
														lineHeight: "166%",
													}}
												>
													Cover Photo
												</Typography>
											}
											labelPlacement="start"
											sx={{
												display: "flex",
												alignItems: "center",
												position: "absolute",
												gap: "0.25rem",
												top: "0.5rem",
												right: "0.5rem",
												height: "1.5rem",
												borderRadius: "0.25rem",
												backgroundColor: "var(--Other-New-Backdrop-Overlay, rgba(0, 0, 0, 0.50))",
												padding: "0.25rem",
												margin: "0rem",
											}}
										/>
										<ProgressContainer>
											{stage[index] && stage[index] !== "FETCHED_FILE_INFORMATION" ? (
												<CircularProgress />
											) : stage[index] === undefined || stage[index] === "FETCHED_FILE_INFORMATION" ? (
												<CheckIcon
													sx={{
														color: "#4CC417",
														fontSize: "2rem",
														backgroundColor: "rgba(0, 0, 0, 0.5)",
														borderRadius: "50%",
													}}
												/>
											) : null}
										</ProgressContainer>
										<FormControlContainer size="small">
											<Select
												value={category[index].charAt(0).toUpperCase() + category[index].slice(1)}
												displayEmpty
												renderValue={(selected) => {
													if (selected === "all_images") {
														return <em style={{ fontSize: "0.875rem" }}>Category</em>;
													}

													return selected;
												}}
												placeholder="Category"
												onChange={(event) => handleSelect(event, index)}
												sx={{
													width: "7rem",
												}}
												MenuProps={{
													disableScrollLock: true,
													MenuListProps: {
														sx: {
															backgroundColor: theme.palette.background.paper,
														},
													},
												}}
											>
												<MenuItem value={"exterior"}>Exterior</MenuItem>
												<MenuItem value={"interior"}>Interior</MenuItem>
												<MenuItem value={"master_plan"}>Master plan</MenuItem>
												<MenuItem value={"amenities"}>Amenities</MenuItem>
												<MenuItem value={"route_map"}>Route map</MenuItem>
											</Select>
											{stage[index] === "FETCHED_FILE_INFORMATION" ? (
												<IconButton
													size="small"
													// disabled={show ? true : false}
													onClick={() => handleRemoveImage(index)}
													sx={{
														padding: "0.5rem",
														position: "absolute",
														bottom: "0.3rem",
														right: "0.5rem",
														zIndex: 1,
														background: "var(--Other-New-Backdrop-Overlay, rgba(0, 0, 0, 0.50))",
													}}
												>
													<DeleteOutline
														sx={{
															color: theme.palette.primary.main,
															fontSize: "1.25rem",
														}}
													/>
												</IconButton>
											) : null}
										</FormControlContainer>
									</ImageContainer>
								) : null}
							</ContentContainer>
						))}
						{selectedFiles.length === 0 || load ? null : (
							<SecondaryUploadContent
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									if (input.current) {
										input.current.value = "";
										input.current.click();
									}
								}}
							>
								<Backup sx={{ color: theme.palette.primary.main }} />
								<Typography>Add More</Typography>
							</SecondaryUploadContent>
						)}
					</ImagePreviewContainer>
				</CustomDialogContent>
			</CustomDialog>
		</React.Fragment>
	);
};

export default UploadImages;
