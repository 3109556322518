/* 
The below function shows when t6he notification was sent.
Format example: 1 day ago, 2 hours ago, 4 weeks ago, 3 months ago, etc
*/

const timeSince = (date: any) => {
  const seconds = Math.floor((new Date().getTime() - date) / 1000);

  /* Years */

  let interval = seconds / 31536000;

  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " year";
  } else if (interval >= 2) {
    return Math.floor(interval) + " years";
  }

  /* Months */

  interval = seconds / 2592000;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " month";
  } else if (interval > 2) {
    return Math.floor(interval) + " months";
  }

  /* Weeks */

  interval = seconds / 604800;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " week";
  } else if (interval >= 2) {
    return Math.floor(interval) + " weeks";
  }

  /* Days */

  interval = seconds / 86400;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " day";
  } else if (interval >= 2) {
    return Math.floor(interval) + " days";
  }

  /* Hours */

  interval = seconds / 3600;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " hour";
  } else if (interval >= 2) {
    return Math.floor(interval) + " hours";
  }

  /* Minutes */

  interval = seconds / 60;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " minute";
  } else if (interval >= 2) {
    return Math.floor(interval) + " minutes";
  }

  /* Seconds */

  return Math.floor(seconds) + " seconds";
};

export default timeSince;
