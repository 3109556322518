/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import FooterSocialIcons from "./FooterSocialIcons";
import FooterLinks from "./FooterLinks";
import FooterCopyrightInfo from "./FooterCopyrightInfo";
import { useTheme } from "@mui/material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

const FooterHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

const FooterContainer = () => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Container>
        {/* Footer Header */}

        <FooterHeader>
          {/* Logo */}

          <img
            src={
              theme.palette.mode === "dark"
                ? "/images/logo/logo-white.svg"
                : "/images/logo/logo.svg"
            }
            alt="Footer Logo"
            width={100}
            height={30}
            loading="lazy"
            referrerPolicy="no-referrer"
          />

          {/* Social Icons */}

          <FooterSocialIcons />
        </FooterHeader>

        {/* Footer Links */}

        <FooterLinks />

        {/* Copyright */}

        <FooterCopyrightInfo />
      </Container>
    </React.Fragment>
  );
};

export default FooterContainer;
