/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	fontWeight: 400,
	fontSize: "0.875rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	margin: "0.5rem 0rem 0rem 0rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#666666",
}));

const ServiceCardServiceSubtype = ({
	service_subtype,
	other_service_subtype,
}: {
	service_subtype: Array<string>;
	other_service_subtype: string;
}) => {
	return (
		<React.Fragment>
			<Heading>
				{service_subtype.includes("other")
					? other_service_subtype
					: (service_subtype[0].charAt(0).toUpperCase() + service_subtype[0].slice(1))
							.replace(/_/g, " ")
							.replace(/Hvac /g, "HVAC ")
							.replace(/Ac /g, "AC ")
							.replace(/Tv /g, "TV ")
							.replace(/Lpg/g, "LPG")
							.replace(/Cctv/g, "CCTV")
							.replace(/open parenthesis it close parenthesis/g, "(IT)")
							.replace(/ or /g, " / ")
							.replace(/ and /g, " & ")}
			</Heading>
			<SubHeading>Service Subtype</SubHeading>
		</React.Fragment>
	);
};

export default ServiceCardServiceSubtype;
