/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../redux-magic/store";
import { ObjectId } from "mongodb";

/* Component Imports */

import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  useTheme,
} from "@mui/material";

/* Icon Imports */

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { useRouter } from "next/router";
import DeleteListingPropertyModal from "../../../modals/PropertyModal/DeleteListingProperty";
import { MoreVertOutlined, DeleteOutlineOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
  objectFit: "cover",
  width: "100%",
  height: "14rem",
  borderRadius: "8px",
  position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
  display: "block",
  objectFit: "cover",
  width: "100%",
  height: "14rem",
  borderRadius: "8px 8px 0px 0px",
  zIndex: 0,
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
  background: "#00000040",
  margin: "0.5rem 0.5rem 0rem 0rem",
  "&:hover": {
    background: "#00000040",
    // scale: "1.1",
  },
}));
const ThreeDotIconButton = styled(IconButton)(({ theme }) => ({
  background: "#00000040",
  margin: "0.5rem 0.5rem 0rem 0rem",
  // "@media (pointer: fine": {
  "&:hover": {
    background: "#00000040",
    // scale: "1.1",
  },
  // },
}));

const GridImage = ({
  _id,
  slug,
  imagesrc,
  alt,
  dispatch,
  profile_context,
  session,
  loading,
  saved_content,
  created_by,
  page_type,
  isEditor,
  assigned_to,
  preview,
}: {
  _id: ObjectId | string;
  slug: string;
  imagesrc: string;
  alt: string;
  dispatch: Function;
  profile_context: StateInterface["profile_context"];
  session: any;
  loading: boolean;
  saved_content: Array<any>;
  created_by?: any;
  page_type?: string;
  isEditor?: boolean;
  assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
  preview?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  /*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

  const router = React.useRef(useRouter()).current;

  const [bookmark, setBookmark] = React.useState(
    saved_content?.find((item) => {
      return profile_context && profile_context.is_business_profile
        ? item.saved_by.business_profile_id ===
            profile_context?.business_profile_id
        : item.saved_by.user_id === profile_context?.user_id;
    }) !== undefined
  );
  React.useEffect(() => {
    setBookmark(
      saved_content?.find((item) => {
        return profile_context && profile_context.is_business_profile
          ? item.saved_by.business_profile_id ===
              profile_context?.business_profile_id
          : item.saved_by.user_id === profile_context?.user_id;
      }) !== undefined
    );
  }, [profile_context, saved_content]);

  const submit = async () => {
    if (session === null) {
      window.open("/auth/login");
    } else {
      await dispatch(
        addSaveEntityBusinessProfileThunk({
          is_business_profile: profile_context?.is_business_profile,
          user_id: profile_context?.user_id,
          business_profile_id: profile_context?.business_profile_id,
          entity_id: _id,
          entity_type: "ofb",
          add_remove: !bookmark ? "add" : "remove",
          url: router.asPath ? router.asPath : "",
        })
      );
      setBookmark(!bookmark);
    }
  };

  const handleDelete = async () => {
    setOpenDeleteModal(true);
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  if (!loading) {
    return (
			<React.Fragment>
				<DeleteListingPropertyModal
					open={openDeleteModal}
					handleClose={handleCloseDeleteModal}
					creator_user_id={created_by?.userId}
					creator_business_profile_id={created_by?.business_profile_id}
					propertyId={_id}
					session={session}
					isEditor={isEditor ? isEditor : false}
					profile_context={profile_context}
					dispatch={dispatch}
					property_type={"ofb"}
				/>
				<Container>
					{/* Bookmark Icon */}

					{preview ? null : (
						<BookmarkIconButton
							onClick={(event: any) => {
								event.preventDefault();
								session === null ? window.open("/auth/login") : submit();
							}}
							sx={{
								position: "absolute",
								top: 3,
								right: page_type ? 48 : 4,
								zIndex: 4,
							}}
						>
							{bookmark == false ? (
								<BookmarkBorderOutlinedIcon
									sx={{ color: "#fafafa" }}
									fontSize="medium"
								/>
							) : (
								<BookmarkOutlinedIcon
									sx={{ color: theme.palette.primary.main }}
									fontSize="medium"
								/>
							)}
						</BookmarkIconButton>
					)}

					{page_type ? (
						<ThreeDotIconButton
							onClick={(event: any) => {
								event.preventDefault();
								session === null ? window.open("/auth/login") : handleClick(event);
							}}
							sx={{
								position: "absolute",
								top: 3,
								right: 3,
								zIndex: 4,
							}}
						>
							<MoreVertOutlined
								sx={{
									color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
								}}
							/>
						</ThreeDotIconButton>
					) : null}
					{/* Menu */}
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						disableScrollLock={true}
						MenuListProps={{ disablePadding: true }}
						slotProps={{
							paper: {
								sx: {
									height: "auto",
									// padding: "0.5rem 1rem",
									borderRadius: "8px",
									backgroundColor: theme.palette.background.paper,
									backgroundImage: "none",
									[theme.breakpoints.down("xs")]: {
										width: 300,
										height: 440,
										borderRadius: "8px",
									},
								},
							},
						}}
					>
						{session?.user?.dbUser?._id === created_by?.user_id ||
						session?.user?.dbUser?.access_level.includes("super_admin") ||
						(session?.user?.dbUser?.access_level.includes("admin") &&
							session?.user?.dbUser?.access_level.includes("business_profiles") &&
							assigned_to?.includes(session?.user?.dbUser?._id)) ? (
							<MenuItem
								sx={{
									width: "100%",
									justifyContent: "space-between",
									cursor: "pointer",
									padding: "0.5rem 1rem 0.5rem 1rem",
									"&:hover": {
										backgroundColor: theme.palette.mode === "dark" ? "#303030" : "#F5F5F5",
									},
								}}
								onClick={() => {
									window.open(`/properties/${slug}`, "_self");
									handleClose();
								}}
							>
								Edit OFB
							</MenuItem>
						) : null}

						{session?.user?.dbUser?._id === created_by?.user_id ||
						session?.user?.dbUser?.access_level.includes("super_admin") ||
						(session?.user?.dbUser?.access_level.includes("admin") &&
							session?.user?.dbUser?.access_level.includes("business_profiles") &&
							assigned_to?.includes(session?.user?.dbUser?._id)) ? (
							<MenuItem
								sx={{
									width: "100%",
									justifyContent: "space-between",
									cursor: "pointer",
									padding: "0.5rem 1rem 0.5rem 1rem",
									"&:hover": {
										backgroundColor: theme.palette.mode === "dark" ? "#303030" : "#F5F5F5",
									},
								}}
								onClick={handleDelete}
							>
								Delete OFB
							</MenuItem>
						) : null}

						{/* <MenuItem>
                  <Link
                    href={`/boostings/property/${slug}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    onClick={handleClose}
                    rel="noopener"
                    target="_blank"
                    referrerPolicy="no-referrer"
                    component="a"
                  >
                    <ListItemIcon>
                      <img
                        src="/images/icons/business-profile/tunder.svg"
                        width={22}
                        height={22}
                        alt="tunder"
                        referrerPolicy="no-referrer"
                      />
                    </ListItemIcon>
                    Boost property
                  </Link>
                </MenuItem> */}
					</Menu>

					<Image
						src={imagesrc}
						alt={alt}
						referrerPolicy="no-referrer"
					/>
				</Container>
			</React.Fragment>
		);
  } else {
    return (
      <React.Fragment>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
          style={{ borderRadius: "0.25rem" }}
        />
      </React.Fragment>
    );
  }
};

export default GridImage;
