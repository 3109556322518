/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
  property_images,
  PropertyMediaImage,
} from "../../../redux-magic/sub-interfaces/sub-interfaces";
import { ObjectId } from "mongodb";

/* Component Imports */

import Link from "@mui/material/Link";
import OFBCardImage from "./sub-components/OFBCardImage";
import OFBCardTitle from "./sub-components/OFBCardTitle";
import OFBCardDeveloper from "./sub-components/OFBCardDeveloper";
import OFBCardLocation from "./sub-components/OFBCardLocation";
import OFBCardChips from "./sub-components/OFBCardChips";

/* Styled Components */

const OFBLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.mode == "dark" ? "#fff" : "#000",
}));

const OFBCardContainer = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  objectFit: "contain",
  width: "20.53rem",
  margin: "0.1rem",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
  /* XXS breakpoint */
  [theme.breakpoints.up("xxs")]: {
    width: "16.5rem",
    height: "23.5rem",
  },
  /* iphone 5 */
  [theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
    width: "18rem",
    height: "24rem",
  },
  /*galaxy S8+ - 360*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
    width: "20rem",
    height: "24rem",
  },
  /*iphone se - 375*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
    width: "21rem",
    height: "24rem",
    // margin: "0rem 0rem 0.1rem 0rem",
  },
  /* iphone 12 pro and pixel - 390 */
  [theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
    width: "22rem",
    height: "24rem",
  },
  /*iphone XR - 414*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
    width: "23rem",
    height: "24rem",
  },
  [theme.breakpoints.up("xsPlus")]: {
    width: "25.5rem",
    height: "24rem",
    // margin: "0.5rem 0.25% 0.5rem 0.25%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "22rem",
    height: "24rem",
    // margin: "0.5rem 0.25% 0.5rem 0.25%",
  },
  /*ipad Mini */
  [theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
    width: "20.5rem",
    height: "24rem",
  },
  /* ipad Air*/
  [theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
    width: "22rem",
    height: "24rem",
  },
  [theme.breakpoints.up("md")]: {
    // width: "32.33%",
    height: "24rem",
    // margin: "0.5rem 0.167% 0.5rem 0.167%",
  },
  /* MDLG Breakpoint iPadPro*/
  [theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
    width: "20.33rem",
    height: "24rem",
    // margin: "0.5rem 1rem 0.5rem 1rem",
  },
  /*720p and 768p breakpoint */
  [theme.breakpoints.up("lg")]: {
    width: "21.33rem",
    height: "24.5rem",
    // margin: "0.5rem 0.5rem 0.2rem 0.5rem",
  },
  /* 1080p 125% breakpoint*/
  [theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
    width: "22.53rem",
    height: "24.5rem",
    // margin: "0rem 0.5rem 0.5rem 0.5rem",
  },
  /* 1080p breakpoint*/
  [theme.breakpoints.up("xl")]: {
    width: "21.53rem",
    height: "24.5rem",
    // margin: "0rem 0.1rem 0.5rem 0.1rem",
  },
  /* XXL breakpoint  2560p*/
  [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
    width: "23.53rem",
    height: "24.5rem",
    // margin: "0rem 0.5rem 0.2rem 0.5rem",
  },
  /*4k breakpoint 3840p*/
  [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
    width: "26.33rem",
    height: "24.5rem",
    // margin: "0.5rem 0.5rem 0.2rem 0.5rem",
  },
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "1rem",
  margin: "0rem 0rem 0rem 0rem",
  gap: "0.75rem",
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  zIndex: 5,
  cursor: "pointer",
}));

const OFBPropertyCard = ({
  profile_context,
  session,
  dispatch,
  loading,
  _id,
  slug,
  transaction_type,
  property_type,
  title,
  images,
  tags,
  location,
  developer,
  saved_content,
  assigned_to,
  isEditor,
  created_by,
  page_type,
  preview,
}: {
  profile_context: StateInterface["profile_context"];
  session: any;
  dispatch: Function;
  loading: boolean;
  _id: ObjectId | string;
  slug: string;
  transaction_type: string;
  property_type: Array<string>;
  title: string;
  images: Array<property_images>;
  tags: Array<string>;
  location: string;
  developer: string;
  saved_content: Array<any>;
  assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
  isEditor?: boolean;
  created_by?: any;
  page_type?: string;
  preview?: boolean;
}) => {
  const [coverImage, setCoverImage] = React.useState<string>(
    images
      ? images[0]
        ? (images[0].url as PropertyMediaImage) &&
          typeof (images[0].url as PropertyMediaImage) !== "string"
          ? (images[0].url as PropertyMediaImage)
            ? ((images[0].url as PropertyMediaImage).lqip as string) !==
              "https://cdn.beegru.com/images/uploads/property.jpg/1536/864"
              ? ((images[0].url as PropertyMediaImage).lqip as string)
              : process.env.CDN_URL + "images/uploads/property.jpg"
            : process.env.CDN_URL + "images/uploads/property.jpg"
          : process.env.CDN_URL + "images/uploads/property.jpg"
        : process.env.CDN_URL + "images/uploads/property.jpg"
      : process.env.CDN_URL + "images/uploads/property.jpg"
  );

  React.useEffect(() => {
    setCoverImage(
      images
        ? images[0]
          ? (images[0].url as PropertyMediaImage) &&
            typeof (images[0].url as PropertyMediaImage) !== "string"
            ? (images[0].url as PropertyMediaImage)
              ? ((images[0].url as PropertyMediaImage).regular as string) !==
                "https://cdn.beegru.com/images/uploads/property.jpg/1536/864"
                ? ((images[0].url as PropertyMediaImage).regular as string)
                : process.env.CDN_URL + "images/uploads/property.jpg"
              : process.env.CDN_URL + "images/uploads/property.jpg"
            : process.env.CDN_URL + "images/uploads/property.jpg"
          : process.env.CDN_URL + "images/uploads/property.jpg"
        : process.env.CDN_URL + "images/uploads/property.jpg"
    );
  }, [images]);

  return (
    <React.Fragment>
      <OFBLink
        href={preview ? "javascript:void(0)" : `/properties/${slug}`}
        rel="noopener"
        target={preview ? "_self" : "_blank"}
        referrerPolicy="no-referrer"
        title={title ? title : "Unknown Title"}
      >
        <OFBCardContainer>
          <OFBCardImage
            _id={_id}
            slug={slug}
            imagesrc={coverImage}
            alt={coverImage}
            dispatch={dispatch}
            session={session}
            profile_context={profile_context}
            loading={loading}
            saved_content={saved_content}
            created_by={created_by}
            page_type={page_type}
            assigned_to={assigned_to}
            isEditor={isEditor}
            preview={preview}
          />
          <OFBCardInfoContainer>
            <OFBCardTitle
              loading={loading}
              title={title !== "" || undefined ? title : "Unknown Title"}
            />
            <OFBCardChips
              loading={loading}
              transaction_type={transaction_type}
              property_type={property_type}
              tags={tags}
              location={location}
            />
            <OFBCardLocation
              loading={loading}
              location={
                location !== "" || undefined ? location : "Unknown Location"
              }
            />
            <OFBCardDeveloper
              loading={loading}
              developer={
                developer !== "" || undefined ? developer : "Unknown Developer"
              }
            />
          </OFBCardInfoContainer>
        </OFBCardContainer>
      </OFBLink>
    </React.Fragment>
  );
};

export default OFBPropertyCard;
