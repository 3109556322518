/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { PricePerUnit, Area, BHK } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Icon Imports */

import { EventSeatOutlined, SquareFootOutlined, StairsOutlined } from "@mui/icons-material";

/* Library Imports */

import prepareAreaString from "../../../../lib/PrepareAreaString";
import PrepareConfigString from "../../../../lib/PrepareConfigString";

/* Styled Components */

const AreaInfo = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 0rem 0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Heading2 = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const AreaAndConfigIcon = styled("img")(({ theme }) => ({
	margin: "0rem 0.25rem 0rem 0rem",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const GridCardAreaAndConfig = ({
	loading,
	transactionType,
	propertyType,
	area,
	areaType,
	pricePerUnit,
	bhk,
	floors,
	noOfSeats,
}: {
	loading: boolean;
	transactionType: string;
	propertyType: Array<string>;
	area: Area;
	areaType: string;
	pricePerUnit: PricePerUnit;
	bhk: BHK;
	floors: BHK;
	noOfSeats: BHK;
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<React.Fragment>
				<SkeletonSection>
					<Skeleton
						animation="wave"
						variant="text"
						width="30%"
						height="auto"
						style={{ marginBottom: "0.5rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="30%"
						height="auto"
						style={{ marginBottom: "0.5rem" }}
					/>
				</SkeletonSection>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<AreaInfo>
				<Heading>
					<AreaAndConfigIcon
						src={
							theme.palette.mode === "dark" ? "/images/icons/config/area_white.svg" : "/images/icons/config/area.svg"
						}
						alt="Area Icon"
						width={16}
						height={16}
						loading="lazy"
						referrerPolicy="no-referrer"
					/>
					{prepareAreaString(area, areaType)}
				</Heading>
				<Heading2>
					{propertyType.includes("Plot") ||
					propertyType.includes("Farm Plot") ||
					propertyType.includes("Land (Residential)") ||
					propertyType.includes("Land (Commercial)") ||
					propertyType.includes("Land (Agricultural)") ||
					propertyType.includes("Land (Industrial)") ||
					propertyType.includes("Office Space") ||
					propertyType.includes("Factory") ||
					propertyType.includes("Mining") ||
					propertyType.includes("Warehouse") ? (
						<AreaAndConfigIcon
							src={
								theme.palette.mode === "dark"
									? "/images/icons/config/rate_per_sqft_white.svg"
									: "/images/icons/config/rate_per_sqft.svg"
							}
							alt="Config Icon"
							width={16}
							height={16}
							loading="lazy"
							referrerPolicy="no-referrer"
						/>
					) : propertyType.includes("Building") ? (
						<StairsOutlined
							sx={{
								color: theme.palette.mode == "dark" ? "#ffffff" : "#00000099",
								width: "16px",
								height: "16px",
								margin: "0rem 0.25rem 0rem 0rem",
							}}
						/>
					) : propertyType.includes("Co-working") ? (
						<EventSeatOutlined
							sx={{
								color: theme.palette.mode == "dark" ? "#ffffff" : "#00000099",
								width: "16px",
								height: "16px",
								margin: "0rem 0.25rem 0rem 0rem",
							}}
						/>
					) : (
						<AreaAndConfigIcon
							src={
								theme.palette.mode === "dark" ? "/images/icons/config/bhk_white.svg" : "/images/icons/config/bhk.svg"
							}
							alt="Config Icon"
							width={16}
							height={16}
							loading="lazy"
							referrerPolicy="no-referrer"
						/>
					)}

					{PrepareConfigString(transactionType, propertyType, bhk, pricePerUnit, floors, noOfSeats)}
				</Heading2>
			</AreaInfo>
		</React.Fragment>
	);
};

export default GridCardAreaAndConfig;
