const NumberToCurrencyPipe: (
  value: number | string,
  format?: string
) => string = (value: number, format: string = "en-IN") => {
  value = Number(String(value).replace(/\D/g, ""));
  return new Intl.NumberFormat(format, {
    maximumFractionDigits: 0,
  }).format(value);
};

export default NumberToCurrencyPipe;
