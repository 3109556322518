/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.145rem",
  letterSpacing: "0.0025rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
  marginRight: "0.25rem",
}));

const OFBCardDeveloper = ({
  loading,
  developer,
}: {
  loading: boolean;
  developer: string;
}) => {
  const theme = useTheme();
  if (!loading) {
    return (
      <React.Fragment>
        <Heading>
          <InfoIcon
            src={
              theme.palette.mode === "dark"
                ? "/images/icons/singleproperty/bidding/BuilderDark.svg"
                : "/images/icons/singleproperty/bidding/Builder.svg"
            }
            alt="Mail Icon"
            width={16}
            height={16}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
          {developer}
        </Heading>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Skeleton
          animation="wave"
          variant="text"
          width="75%"
          height="auto"
          style={{ marginBottom: "0rem" }}
        />
      </React.Fragment>
    );
  }
};

export default OFBCardDeveloper;
