/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { useTheme } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

/* Styled Components */

const LocationDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.25rem",
	margin: "0.25rem 0rem 0rem 0rem",
}));

const Heading = styled("div")(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	letterSpacing: "0.0025rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#666666",
}));

const GridCardLocation = ({ location }: { location: string }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<LocationDiv>
				<LocationOnOutlinedIcon
					sx={{
						fontSize: "1rem",
						color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
					}}
				/>
				<Heading>{location ? (location.length < 30 ? location : location?.substring(0, 15) + "...") : ""}</Heading>
			</LocationDiv>
		</React.Fragment>
	);
};

export default GridCardLocation;
