/* Common Imports */

import React from "react";
import { styled } from "@mui/system";
import { NextRouter } from "next/router";
import { createHmac } from "crypto";

/* Redux Imports */

import { setCreateNewPropertyOverviewThunk } from "../../../../../redux-magic/store";
import { ObjectId } from "mongodb";
import { PropertyInterface, BusinessProfileInterface, UserInterface } from "../../../../../models";
import StateInterface from "../../../../../redux-magic/state-interface";

/* Component Imports */

import {
	Typography,
	Paper,
	TextField,
	Skeleton,
	Snackbar,
	Alert,
	IconButton,
	CircularProgress,
	Button,
	Tabs,
	Tab,
	Switch,
	useTheme,
} from "@mui/material";
import BeegruButton from "../../../../common-components/buttons/BeegruButton";
import { useRouter } from "next/router";

/* Icon Imports */

import { EditOutlined, Close, AutoAwesomeOutlined } from "@mui/icons-material";

/* Styled Components */

const PaperContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	borderRadius: "0.75rem",
	gap: "1.5rem",
	overflow: "auto",
	marginTop: "2rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
	background: "transparent",
}));

const PropertyOverview = ({ overview_data, dispatch }: { overview_data: string; dispatch: Function }) => {
	return (
		<PaperContainer id="overview">
			<TextField
				value={overview_data}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					dispatch(setCreateNewPropertyOverviewThunk(event.target.value));
				}}
				multiline
				rows={6}
				// label="Overview"
				placeholder="Eg: RERA Registered, BBMP Approved, IGBC Certified, Vaastu Compliance etc."
				fullWidth
				autoComplete="off"
				sx={{ width: "100%" }}
			/>
		</PaperContainer>
	);
};

export default PropertyOverview;
