/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Link, Typography, useTheme } from "@mui/material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexWrap: "wrap",
  width: "100%",
  height: "100%",
  margin: "1rem 0rem 1.25rem 0rem",
}));

const AboutUsSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "35%",
  [theme.breakpoints.down("lg")]: {
    margin: "0rem 0rem 1.5rem 0rem",
    width: "100%",
  },
}));

const CompanySection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "15%",
  [theme.breakpoints.down("lg")]: {
    width: "20%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

const OurServicesSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "10%",
  [theme.breakpoints.down("lg")]: {
    width: "20%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

const GetInTouchSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "27%",
  [theme.breakpoints.down("lg")]: {
    width: "45%",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "1.5rem 0rem 0rem 0rem",
    width: "100%",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  margin: "0rem 0rem 0.75rem 0rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  fontWeight: 400,
  color: theme.palette.mode === "dark" ? "#fff" : "#666666",
}));

const List = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
  gap: "0.5rem",
}));

const CustomLink = styled(Link)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  fontWeight: 400,
  color: theme.palette.mode === "dark" ? "#fff" : "#666666",
  textDecoration: "none",
  "@media (pointer: fine)": {
    "&:hover": {
      // textDecoration: "underline",
      color: theme.palette.primary.main,
    },
  },
}));

const FooterLinks = (props: any) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Container>
        {/* About us */}

        <AboutUsSection>
          <Title variant="body1">About us</Title>
          <Text variant="body2">
            Beegru is a proptech platform that connects property seekers with
            realtors, builders, landowners and professionals.
          </Text>
        </AboutUsSection>

        {/* Company */}

        <CompanySection>
          <Title variant="body1">Company</Title>
          <List>
            <CustomLink
              href="/faqs"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              FAQs
            </CustomLink>
            <CustomLink
              href="/terms-of-use"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Terms of use
            </CustomLink>
            <CustomLink
              href="/privacy"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Privacy policy
            </CustomLink>
            <CustomLink
              href="/payment-policy"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Payment policy
            </CustomLink>
            <CustomLink
              href="/refund-and-cancellation-policy"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Refund and cancellation policy
            </CustomLink>
          </List>
        </CompanySection>

        {/* Our services */}

        <OurServicesSection>
          <Title variant="body1">Our services</Title>
          <List>
            <CustomLink
              href="/intro?type=agent"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Agents
            </CustomLink>
            <CustomLink
              href="/intro?type=property-seeker"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Property seekers
            </CustomLink>
            <CustomLink
              href="/intro?type=landowner"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Landowners
            </CustomLink>
            <CustomLink
              href="/intro?type=developer"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Developers
            </CustomLink>
            <CustomLink
              href="/intro?type=professional"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              Professionals
            </CustomLink>
          </List>
        </OurServicesSection>

        {/* Get in touch */}

        <GetInTouchSection>
          <Title variant="body1">Get in touch</Title>
          <List>
            <Text variant="body2">
              1090i, 4th Floor, 18th Cross Road, 3rd Sector, HSR Layout,
              Bengaluru, Karnataka 560 102
            </Text>
            <CustomLink
              href="tel:+918861039999"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              +91 88610 39999
            </CustomLink>
            <CustomLink
              href="mailto:support@beegru.com"
              rel="noopener"
              target="_blank"
              referrerPolicy="no-referrer"
            >
              support@beegru.com
            </CustomLink>
          </List>
        </GetInTouchSection>
      </Container>
    </React.Fragment>
  );
};

export default FooterLinks;
