/* Common Imports */

import React from "react";

/* SvgIcon Imports */

// function LoadingIcon(props: any) {
//   return (
//     <SvgIcon
//       id="exyWzUZoCjF1"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 256 256"
//       width={props.width ? props.width : "256"}
//       height={props.height ? props.height : "256"}
//       shape-rendering="geometricPrecision"
//       text-rendering="geometricPrecision"
//     >
//       <defs>
//         <linearGradient
//           id="exyWzUZoCjF3-fill"
//           x1="-47.8843"
//           y1="282.7512"
//           x2="223.6589"
//           y2="43.4871"
//           spreadMethod="pad"
//           gradientUnits="userSpaceOnUse"
//           gradientTransform="translate(0 0)"
//         >
//           <stop id="exyWzUZoCjF3-fill-0" offset="6%" stopColor="#f00" />
//           <stop id="exyWzUZoCjF3-fill-1" offset="7%" stopColor="#ff0700" />
//           <stop id="exyWzUZoCjF3-fill-2" offset="15%" stopColor="#ff2500" />
//           <stop id="exyWzUZoCjF3-fill-3" offset="21%" stopColor="#ff3700" />
//           <stop id="exyWzUZoCjF3-fill-4" offset="26%" stopColor="#ff3e00" />
//           <stop id="exyWzUZoCjF3-fill-5" offset="28%" stopColor="#fc4902" />
//           <stop id="exyWzUZoCjF3-fill-6" offset="33%" stopColor="#f26607" />
//           <stop id="exyWzUZoCjF3-fill-7" offset="40%" stopColor="#e3950f" />
//           <stop id="exyWzUZoCjF3-fill-8" offset="43%" stopColor="#dab314" />
//           <stop id="exyWzUZoCjF3-fill-9" offset="49%" stopColor="#aeaf1c" />
//           <stop id="exyWzUZoCjF3-fill-10" offset="56%" stopColor="#7cab26" />
//           <stop id="exyWzUZoCjF3-fill-11" offset="67%" stopColor="#22a66c" />
//           <stop id="exyWzUZoCjF3-fill-12" offset="81%" stopColor="#87468b" />
//           <stop id="exyWzUZoCjF3-fill-13" offset="91%" stopColor="#a90171" />
//           <stop id="exyWzUZoCjF3-fill-14" offset="99%" stopColor="#eb1c61" />
//         </linearGradient>
//         <linearGradient
//           id="exyWzUZoCjF4-fill"
//           x1="223.6382"
//           y1="43.8114"
//           x2="495.1814"
//           y2="-195.4527"
//           spreadMethod="pad"
//           gradientUnits="userSpaceOnUse"
//           gradientTransform="translate(0 0)"
//         >
//           <stop id="exyWzUZoCjF4-fill-0" offset="0%" stopColor="#eb1c61" />
//           <stop id="exyWzUZoCjF4-fill-1" offset="11%" stopColor="#f00" />
//           <stop id="exyWzUZoCjF4-fill-2" offset="13%" stopColor="#ff0700" />
//           <stop id="exyWzUZoCjF4-fill-3" offset="17%" stopColor="#ff2500" />
//           <stop id="exyWzUZoCjF4-fill-4" offset="21%" stopColor="#ff3700" />
//           <stop id="exyWzUZoCjF4-fill-5" offset="26%" stopColor="#ff3e00" />
//           <stop id="exyWzUZoCjF4-fill-6" offset="28%" stopColor="#fc4902" />
//           <stop id="exyWzUZoCjF4-fill-7" offset="33%" stopColor="#f26607" />
//           <stop id="exyWzUZoCjF4-fill-8" offset="40%" stopColor="#e3950f" />
//           <stop id="exyWzUZoCjF4-fill-9" offset="43%" stopColor="#dab314" />
//           <stop id="exyWzUZoCjF4-fill-10" offset="49%" stopColor="#aeaf1c" />
//           <stop id="exyWzUZoCjF4-fill-11" offset="56%" stopColor="#7cab26" />
//           <stop id="exyWzUZoCjF4-fill-12" offset="67%" stopColor="#22a66c" />
//           <stop id="exyWzUZoCjF4-fill-13" offset="81%" stopColor="#87468b" />
//           <stop id="exyWzUZoCjF4-fill-14" offset="91%" stopColor="#a90171" />
//           <stop id="exyWzUZoCjF4-fill-15" offset="99%" stopColor="#eb1c61" />
//         </linearGradient>
//       </defs>
//       <g transform="translate(.117152-.018203)" clipPath="url(#exyWzUZoCjF5)">
//         <g id="exyWzUZoCjF3_to" transform="translate(127.552659,127.969082)">
//           <path
//             d="M102.49,-91.86L-168.7,148L71.13,419.17L342.32,179.34Z"
//             transform="translate(-86.810013,-163.655014)"
//             fill="url(#exyWzUZoCjF3-fill)"
//           />
//         </g>
//         <g id="exyWzUZoCjF4_to" transform="translate(398.747659,-111.86092)">
//           <path
//             d="M373.69,-331.69L102.49,-91.86l239.83,271.2L613.52,-60.5Z"
//             transform="translate(-358.005013,76.174988)"
//             fill="url(#exyWzUZoCjF4-fill)"
//           />
//         </g>
//         <clipPath id="exyWzUZoCjF5">
//           <path
//             d="M0,0v256h256v-256ZM192,192h-128v-128h128Z"
//             transform="translate(-.099095 0.023183)"
//             fill="none"
//           />
//         </clipPath>
//       </g>
//     </SvgIcon>
//   );
// }

const Loading = (props: any) => {
  return (
    <React.Fragment>
      {/* <LoadingIcon width={props.width} height={props.height} /> */}
      <defs>
        <linearGradient
          id="exyWzUZoCjF3-fill"
          x1="-47.8843"
          y1="282.7512"
          x2="223.6589"
          y2="43.4871"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="exyWzUZoCjF3-fill-0" offset="6%" stopColor="#f00" />
          <stop id="exyWzUZoCjF3-fill-1" offset="7%" stopColor="#ff0700" />
          <stop id="exyWzUZoCjF3-fill-2" offset="15%" stopColor="#ff2500" />
          <stop id="exyWzUZoCjF3-fill-3" offset="21%" stopColor="#ff3700" />
          <stop id="exyWzUZoCjF3-fill-4" offset="26%" stopColor="#ff3e00" />
          <stop id="exyWzUZoCjF3-fill-5" offset="28%" stopColor="#fc4902" />
          <stop id="exyWzUZoCjF3-fill-6" offset="33%" stopColor="#f26607" />
          <stop id="exyWzUZoCjF3-fill-7" offset="40%" stopColor="#e3950f" />
          <stop id="exyWzUZoCjF3-fill-8" offset="43%" stopColor="#dab314" />
          <stop id="exyWzUZoCjF3-fill-9" offset="49%" stopColor="#aeaf1c" />
          <stop id="exyWzUZoCjF3-fill-10" offset="56%" stopColor="#7cab26" />
          <stop id="exyWzUZoCjF3-fill-11" offset="67%" stopColor="#22a66c" />
          <stop id="exyWzUZoCjF3-fill-12" offset="81%" stopColor="#87468b" />
          <stop id="exyWzUZoCjF3-fill-13" offset="91%" stopColor="#a90171" />
          <stop id="exyWzUZoCjF3-fill-14" offset="99%" stopColor="#eb1c61" />
        </linearGradient>
        <linearGradient
          id="exyWzUZoCjF4-fill"
          x1="223.6382"
          y1="43.8114"
          x2="495.1814"
          y2="-195.4527"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="exyWzUZoCjF4-fill-0" offset="0%" stopColor="#eb1c61" />
          <stop id="exyWzUZoCjF4-fill-1" offset="11%" stopColor="#f00" />
          <stop id="exyWzUZoCjF4-fill-2" offset="13%" stopColor="#ff0700" />
          <stop id="exyWzUZoCjF4-fill-3" offset="17%" stopColor="#ff2500" />
          <stop id="exyWzUZoCjF4-fill-4" offset="21%" stopColor="#ff3700" />
          <stop id="exyWzUZoCjF4-fill-5" offset="26%" stopColor="#ff3e00" />
          <stop id="exyWzUZoCjF4-fill-6" offset="28%" stopColor="#fc4902" />
          <stop id="exyWzUZoCjF4-fill-7" offset="33%" stopColor="#f26607" />
          <stop id="exyWzUZoCjF4-fill-8" offset="40%" stopColor="#e3950f" />
          <stop id="exyWzUZoCjF4-fill-9" offset="43%" stopColor="#dab314" />
          <stop id="exyWzUZoCjF4-fill-10" offset="49%" stopColor="#aeaf1c" />
          <stop id="exyWzUZoCjF4-fill-11" offset="56%" stopColor="#7cab26" />
          <stop id="exyWzUZoCjF4-fill-12" offset="67%" stopColor="#22a66c" />
          <stop id="exyWzUZoCjF4-fill-13" offset="81%" stopColor="#87468b" />
          <stop id="exyWzUZoCjF4-fill-14" offset="91%" stopColor="#a90171" />
          <stop id="exyWzUZoCjF4-fill-15" offset="99%" stopColor="#eb1c61" />
        </linearGradient>
      </defs>
      <g transform="translate(.117152-.018203)" clipPath="url(#exyWzUZoCjF5)">
        <g id="exyWzUZoCjF3_to" transform="translate(127.552659,127.969082)">
          <path
            d="M102.49,-91.86L-168.7,148L71.13,419.17L342.32,179.34Z"
            transform="translate(-86.810013,-163.655014)"
            fill="url(#exyWzUZoCjF3-fill)"
          />
        </g>
        <g id="exyWzUZoCjF4_to" transform="translate(398.747659,-111.86092)">
          <path
            d="M373.69,-331.69L102.49,-91.86l239.83,271.2L613.52,-60.5Z"
            transform="translate(-358.005013,76.174988)"
            fill="url(#exyWzUZoCjF4-fill)"
          />
        </g>
        <clipPath id="exyWzUZoCjF5">
          <path
            d="M0,0v256h256v-256ZM192,192h-128v-128h128Z"
            transform="translate(-.099095 0.023183)"
            fill="none"
          />
        </clipPath>
      </g>
    </React.Fragment>
  );
};

export default Loading;
