/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { PublishPropertyThunk } from "../../../redux-magic/store";

/* Component Imports */

import {
	Dialog,
	DialogTitle,
	DialogActions,
	// IconButton,
	Alert,
	DialogContent,
	useTheme,
	useMediaQuery,
	CircularProgress,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import PropertyCard from "../../cards/PropertyCardOld/PropertyCard";
import OFBCard from "../../cards/OFBCard/OFBPropertyCard";
import { PropertyInterface } from "../../../redux-magic/sub-interfaces/property-interface";
import { ObjectId } from "mongodb";
import { BusinessProfileInterface, UserInterface } from "../../../redux-magic/sub-interfaces/sub-interfaces";

/* Icon Imports */

// import CloseIcon from "@mui/icons-material/Close";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
	[theme.breakpoints.down("sm")]: {
		top: "5rem",
		bottom: "5rem",
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	fontSize: "1.25rem",
	fontWeight: 400,
	background: theme.palette.background.default,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1rem 1rem 1rem 1rem",
	background: theme.palette.background.default,
	[theme.breakpoints.down("sm")]: { padding: "0rem 0rem 0rem 0rem" },
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0rem 1rem 1rem 0rem",
	background: theme.palette.background.default,
}));

interface LocalPropertyInterface extends Omit<PropertyInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

const PublishButtonModal = ({
	propertyData,
	open,
	handleClose,
	dispatch,
	profile_context,
	isEditor,
}: {
	propertyData: LocalPropertyInterface;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	isEditor: boolean;
}) => {
	const theme = useTheme();

	const profile_link = propertyData
		? propertyData.created_by.is_business_profile
			? process.env.PRODUCTION_URL +
				`${(propertyData.created_by.business_profile_id as BusinessProfileInterface).business_profile_type}s/${(propertyData.created_by.business_profile_id as BusinessProfileInterface).slug}`
			: process.env.PRODUCTION_URL + `users/${(propertyData.created_by.user_id as UserInterface)._id}`
		: "";

	const [openPublishSnack, setOpenPublishSnack] = React.useState<boolean>(false);

	const [openUnpublishSnack, setOpenUnpublishSnack] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const handleCloseSnack = () => {
		setOpenPublishSnack(false);
		setOpenUnpublishSnack(false);
	};

	const Publish = async () => {
		setLoad(true);
		await dispatch(
			PublishPropertyThunk({
				property_id: propertyData ? propertyData._id : "",
				status: status === "unpublish" ? "untouched" : "publish_requested",
				creator_is_business_profile: isEditor
					? profile_context.is_business_profile
					: propertyData.created_by.is_business_profile,
				creator_user_id: isEditor ? profile_context.user_id : (propertyData.created_by.user_id as UserInterface)._id,
				creator_business_profile_id: isEditor
					? profile_context.business_profile_id
					: (propertyData.created_by.business_profile_id as BusinessProfileInterface)._id,
			}),
		);
		handleClose();
		if (propertyData.published) {
			setOpenUnpublishSnack(true);
		} else {
			setOpenPublishSnack(true);
		}
		setLoad(false);
		setTimeout(() => {
			window.open(profile_link, "_self");
		}, 1000);
	};

	const status: string = propertyData && propertyData.status === "published" ? "unpublish" : "publish";

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openUnpublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
				sx={{
					bottom: "5rem",
					borderRadius: "0.5rem",
				}}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%" }}
				>
					Property has been unpublished
				</Alert>
			</Snackbar>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openPublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
				sx={{
					bottom: "5rem",
					borderRadius: "0.5rem",
				}}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
				>
					We will notify you once the property is published
				</Alert>
			</Snackbar>

			<CustomDialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={useMediaQuery(theme.breakpoints.down("sm")) ? true : false}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					{!propertyData.published ? "Property card preview" : "Are you sure you want to unpublish this property?"}
				</CustomDialogTitle>

				{!propertyData.published ? (
					<CustomDialogContent>
						{propertyData.is_ofb ? (
							<OFBCard
								profile_context={profile_context}
								session={null}
								dispatch={dispatch}
								loading={false}
								_id={propertyData._id}
								slug={propertyData.slug}
								transaction_type={propertyData.transaction_type}
								property_type={propertyData.property_type}
								title={propertyData.title}
								images={propertyData.images}
								tags={propertyData.tags}
								location={propertyData.location ? propertyData.location.title : ""}
								developer={propertyData.builder ? propertyData.builder.name : ""}
								saved_content={propertyData.saved_content}
								preview={true}
							/>
						) : (
							<PropertyCard
								profile_context={profile_context}
								session={null}
								dispatch={dispatch}
								loading={false}
								_id={propertyData._id}
								slug={propertyData.slug}
								transaction_type={propertyData.transaction_type}
								property_type={propertyData.property_type}
								title={propertyData.title}
								images={propertyData.images}
								tags={propertyData.tags}
								location={propertyData.location ? propertyData.location.title : ""}
								developer={propertyData.builder ? propertyData.builder.name : ""}
								price={propertyData.price}
								price_per_unit={propertyData.price_per_unit}
								area={propertyData.area}
								bhk={propertyData.bhk}
								floors={propertyData.floors}
								no_of_seats={propertyData.no_of_seats}
								saved_content={propertyData.saved_content}
								boosting={propertyData.boost_information}
								property_status={propertyData.availability}
								preview={true}
							/>
						)}
					</CustomDialogContent>
				) : null}
				<CustomDialogActions>
					<BeegruButton
						flavor="primary"
						variant="outlined"
						onClick={handleClose}
						name="cancel"
						type="button"
					>
						{!propertyData.published ? "Cancel" : "No"}
					</BeegruButton>
					{load ? (
						<CircularProgress
							// size={24}
							sx={{
								marginTop: "1rem",
							}}
						/>
					) : (
						<BeegruButton
							flavor="primary"
							variant="contained"
							onClick={() => {
								Publish();
							}}
							name="publish"
							type="button"
						>
							{!propertyData.published ? "Publish" : "Yes"}
						</BeegruButton>
					)}
				</CustomDialogActions>
			</CustomDialog>
		</React.Fragment>
	);
};

export default PublishButtonModal;
