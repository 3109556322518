/* Common Imports */

import React from "react";
import { styled } from "@mui/system";
import { Dayjs } from "dayjs";

/* Redux Imports */

import {
	SetIsStudioThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyCategoryTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyPlotDimensionsThunk,
} from "../../../../../redux-magic/store";

/* Component Imports */

import {
	Typography,
	useTheme,
	TextField,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	OutlinedInput,
	Checkbox,
	ListItemText,
	SelectChangeEvent,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";

/* Function Imports */

import NumberInWords from "../../../../../lib/NumberInWords";

/* Icon Imports */

import CurrencyToNumberPipe from "../../../../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../../../../lib/NumberToCurrencyPipe";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	borderRadius: "8px",
	gap: "1.5rem",
	overflow: "auto",
	paddingTop: "2rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.6rem",
	width: "100%",
	// padding: "0.25rem 0rem 0.25rem 0rem",
}));

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

const PropertyDetails = ({
	property_transaction_type,
	property_category_type,
	property_type,
	property_subtype,
	property_min_price,
	property_max_price,
	property_security_deposit,
	property_availability,
	property_possession_date,
	property_unit_facing,
	property_land_facing,
	property_age,
	dispatch,
}: {
	property_transaction_type: string | undefined;
	property_category_type: string | undefined;
	property_type: Array<string> | undefined;
	property_subtype: string | undefined;
	property_min_price: number | undefined;
	property_max_price: number | undefined;
	property_security_deposit: security_deposit;
	property_availability: Array<string> | undefined;
	property_possession_date: string | Date | undefined;
	property_unit_facing: Array<string>;
	property_land_facing: Array<string>;
	property_age: number | undefined;
	dispatch: Function;
}) => {
	const theme = useTheme();

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);

	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const [errorPropertyAge, setErrorPropertyAge] = React.useState<boolean>(false);

	const propertyType = property_type ? property_type[0] : "";

	const propertyMaxPrice = property_max_price ? property_max_price : 0;

	const propertyMinPrice = property_min_price ? property_min_price : 0;

	/* Functions */

	const handleChangePropertyType = async (event: SelectChangeEvent<typeof property_type>) => {
		const {
			target: { value },
		} = event;
		if (value === "Studio Apartment") {
			await dispatch(SetIsStudioThunk(true));
			dispatch(setCreateNewPropertyTypeThunk(["Apartment"]));
			dispatch(setCreateNewPropertySubTypeThunk(""));
			dispatch(setCreateNewPropertyMinPriceThunk(0));
			dispatch(setCreateNewPropertyMaxPriceThunk(0));
			dispatch(setCreateNewPropertySecurityDepositThunk(0));
			dispatch(setCreateNewPropertyAvailabiltyThunk([]));
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
			dispatch(setCreateNewPropertyAgeThunk(0));
			dispatch(setCreateNewPropertyUnitFacingThunk([]));
			dispatch(setCreateNewPropertyLandFacingThunk([]));
			dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		} else {
			await dispatch(SetIsStudioThunk(false));
			dispatch(
				setCreateNewPropertyTypeThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? (value.split(",") as Array<string>) : (value as Array<string>),
				),
			);
			dispatch(setCreateNewPropertySubTypeThunk(""));
			dispatch(setCreateNewPropertyMinPriceThunk(0));
			dispatch(setCreateNewPropertyMaxPriceThunk(0));
			dispatch(setCreateNewPropertySecurityDepositThunk(0));
			dispatch(setCreateNewPropertyAvailabiltyThunk([]));
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
			dispatch(setCreateNewPropertyAgeThunk(0));
			dispatch(setCreateNewPropertyUnitFacingThunk([]));
			dispatch(setCreateNewPropertyLandFacingThunk([]));
			dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		}
	};

	const handleChangeAvailability = (event: SelectChangeEvent<typeof property_availability>) => {
		const {
			target: { value },
		} = event;

		if (value === "immediately" || value === "ready_to_move_in" || value === "ready_for_construction") {
			dispatch(setCreateNewePropertyPossessionDateThunk(new Date().toISOString().slice(0, 10)));
			dispatch(
				setCreateNewPropertyAvailabiltyThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? value.split(",") : value,
				),
			);
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
		} else {
			dispatch(
				setCreateNewPropertyAvailabiltyThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? (value.split(",") as Array<string>) : (value as Array<string>),
				),
			);
		}
	};

	const handleChangeUnitFacing = (event: SelectChangeEvent<typeof property_unit_facing>) => {
		const {
			target: { value },
		} = event;
		dispatch(
			setCreateNewPropertyUnitFacingThunk(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value,
			),
		);
	};

	const handleChangeLandFacing = (event: SelectChangeEvent<typeof property_land_facing>) => {
		const {
			target: { value },
		} = event;
		dispatch(
			setCreateNewPropertyLandFacingThunk(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value,
			),
		);
	};

	const pTypes: Array<string> = [];
	const AvailItems: Array<string> = [];
	const subtype_options: Array<string> = [];

	const residential = [
		"Apartment",
		"Independent House",
		"Villa",
		"Plot",
		"Land (Residential)",
		"Co-living/PG",
		"Row House",
		"Builder Floor Apartment",
		"Penthouse",
		"Studio Apartment",
	];

	const residentialWOland = [
		"Apartment",
		"Independent House",
		"Villa",
		"Plot",
		"Co-living/PG",
		"Row House",
		"Builder Floor Apartment",
		"Penthouse",
		"Studio Apartment",
	];

	const commercial = ["Land (Commercial)", "Building", "Shop", "Office Space", "Co-working", "Plot (Commercial)"];

	const commercialWOland = ["Building", "Shop", "Office Space", "Co-working", "Plot (Commercial)"];

	const agricultural = ["Land (Agricultural)", "Farm House", "Farm Plot"];

	const agriculturalWOland = ["Farm House", "Farm Plot"];

	const industrial = ["Land (Industrial)", "Factory", "Mining"];

	const industrialWOland = ["Factory"];

	const all_subtypes = ["SEZ", "Hospitality", "Healthcare", "Educational", "Commercial", "Industrial", "Other"];

	const land_subtypes = ["SEZ", "Commercial", "Industrial", "Other"];

	const office_subtypes = ["SEZ", "Commercial", "Industrial", "Other"];

	const sez_subtypes = ["SEZ", "Other"];

	const warehouse_subtypes = ["SEZ", "Commercial", "Industrial", "Agricultural", "Cold Storage", "Other"];

	const Availabilty = ["Ready to move in", "Under construction", "Built to suit", "Bare shell"];

	const RentAvailabilty = ["Immediately", "Select date"];

	const ApartmentAvailabilty = ["Ready to move in", "Under construction"];

	const PlotResidentialAvailability = ["Ready for registration", "Under construction"];

	const WarehousingAvailabilty = [
		"Ready to move in",
		"Under construction",
		// "Built to suit",
	];

	const Facing = ["North", "South", "East", "West", "North east", "North west", "South east", "South west"];

	/* Property Type options */

	switch (property_transaction_type) {
		case "resale":
			switch (property_category_type) {
				case "residential":
					pTypes.push(...residentialWOland);
					break;
				case "commercial":
					pTypes.push(...commercialWOland);
					break;
				case "agricultural":
					pTypes.push(...agriculturalWOland);
					break;
				case "industrial":
					pTypes.push(...industrialWOland);
					break;
				default:
					pTypes.push();
					break;
			}
			break;
		default:
			switch (property_category_type) {
				case "residential":
					pTypes.push(...residential);
					break;
				case "commercial":
					pTypes.push(...commercial);
					break;
				case "agricultural":
					pTypes.push(...agricultural);
					break;
				case "industrial":
					pTypes.push(...industrial);
					break;
				default:
					pTypes.push();
					break;
			}
	}

	/* Availability / Property Status options */

	switch (propertyType) {
		case "Apartment":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Independent House":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Villa":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Plot":
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case "Farm Plot":
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case "Plot (Commercial)":
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case "Co-living/PG":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Row House":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Builder Floor Apartment":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Penthouse":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Co-working":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Building":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Shop":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Office Space":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Farm House":
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Warehouse":
			AvailItems.push(...WarehousingAvailabilty);
			break;
		case "Factory":
			AvailItems.push(...WarehousingAvailabilty);
			break;
		default:
			AvailItems.push(...Availabilty);
	}

	/* Property Subtype options */

	switch (propertyType) {
		case "Land (Commercial)":
			subtype_options.push(...land_subtypes);
			break;
		case "Plot (Commercial)":
			subtype_options.push(...land_subtypes);
			break;
		case "Building":
			subtype_options.push(...all_subtypes);
			break;
		case "Shop":
			subtype_options.push(...all_subtypes);
			break;
		case "Office Space":
			subtype_options.push(...office_subtypes);
			break;
		case "Co-working":
			subtype_options.push(...office_subtypes);
			break;
		case "Warehouse":
			subtype_options.push(...warehouse_subtypes);
			break;
		case "Land (Industrial)":
			subtype_options.push(...land_subtypes);
			break;
		case "Factory":
			subtype_options.push(...sez_subtypes);
			break;
		case "Mining":
			subtype_options.push(...sez_subtypes);
			break;
		default:
			subtype_options.push();
			break;
	}

	return (
		<PaperContainer id="property-details">
			{/* Transaction Type */}
			{property_transaction_type !== "ofb" ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
						// marginTop: "2rem",
					}}
				>
					<InputLabel id="demo-simple-select-helper-label">Transaction type*</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_transaction_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label="Transaction type*"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							dispatch(setCreateNewPropertyTransactionTypeThunk(event.target.value));
							dispatch(setCreateNewPropertyTypeThunk([]));
							dispatch(setCreateNewPropertySubTypeThunk(""));
							dispatch(setCreateNewPropertyMinPriceThunk(0));
							dispatch(setCreateNewPropertyMaxPriceThunk(0));
							dispatch(setCreateNewPropertySecurityDepositThunk(0));
							dispatch(setCreateNewPropertyAvailabiltyThunk([]));
							dispatch(setCreateNewePropertyPossessionDateThunk(""));
							dispatch(setCreateNewPropertyAgeThunk(0));
							dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
						}}
						// disableScrollLock={true}
					>
						<MenuItem value="buy">Sale</MenuItem>
						<MenuItem value="rent">Rent</MenuItem>
						<MenuItem value="resale">Resale</MenuItem>
					</Select>
				</FormControl>
			) : null}

			{/* Property Category */}

			{property_transaction_type !== "" ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-simple-select-helper-label">
						{(property_transaction_type === "ofb" ? "OFB" : "Property") + " category*"}
					</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_category_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label={(property_transaction_type === "ofb" ? "OFB" : "Property") + " category*"}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							if (event.target.value === "warehousing") {
								dispatch(setCreateNewPropertyTypeThunk(["Warehouse"]));
								dispatch(setCreateNewPropertyCategoryTypeThunk(event.target.value));
								dispatch(setCreateNewPropertySubTypeThunk(""));
								dispatch(setCreateNewPropertyMinPriceThunk(0));
								dispatch(setCreateNewPropertyMaxPriceThunk(0));
								dispatch(setCreateNewPropertySecurityDepositThunk(0));
								dispatch(setCreateNewPropertyAvailabiltyThunk([]));
								dispatch(setCreateNewePropertyPossessionDateThunk(""));
								dispatch(setCreateNewPropertyAgeThunk(0));
								dispatch(setCreateNewPropertyUnitFacingThunk([]));
								dispatch(setCreateNewPropertyLandFacingThunk([]));
							} else {
								dispatch(setCreateNewPropertyTypeThunk([]));
								dispatch(SetIsStudioThunk(false));
								dispatch(setCreateNewPropertyCategoryTypeThunk(event.target.value));
								dispatch(setCreateNewPropertySubTypeThunk(""));
								dispatch(setCreateNewPropertyMinPriceThunk(0));
								dispatch(setCreateNewPropertyMaxPriceThunk(0));
								dispatch(setCreateNewPropertySecurityDepositThunk(0));
								dispatch(setCreateNewPropertyAvailabiltyThunk([]));
								dispatch(setCreateNewePropertyPossessionDateThunk(""));
								dispatch(setCreateNewPropertyAgeThunk(0));
								dispatch(setCreateNewPropertyUnitFacingThunk([]));
								dispatch(setCreateNewPropertyLandFacingThunk([]));
							}
						}}
					>
						{property_transaction_type === "ofb" && (
							<MenuItem value="public infrastructure">Public Infrastructure</MenuItem>
						)}
						{property_transaction_type === "ofb" && <MenuItem value="joint development">Joint Development</MenuItem>}
						{property_transaction_type === "ofb" && <MenuItem value="joint venture">Joint Venture</MenuItem>}
						<MenuItem value="residential">Residential</MenuItem>
						<MenuItem value="commercial">Commercial</MenuItem>
						<MenuItem value="agricultural">Agricultural</MenuItem>
						<MenuItem value="warehousing">Warehousing</MenuItem>
						<MenuItem value="industrial">Industrial</MenuItem>
					</Select>
				</FormControl>
			) : null}

			{/* Property Type */}

			{property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_category_type !== "public infrastructure" &&
			property_category_type !== "joint development" &&
			property_category_type !== "joint venture" &&
			property_category_type !== "warehousing" ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{(property_transaction_type === "ofb" ? "OFB" : "Property") + " type*"}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						label={(property_transaction_type === "ofb" ? "OFB" : "Property") + " type*"}
						onChange={handleChangePropertyType}
						renderValue={(selected) => selected?.join(", ")}
					>
						{pTypes?.map((name: string) => (
							<MenuItem
								key={name}
								value={name}
							>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{/* Property Subtype */}

			{(property_category_type == "commercial" ||
				property_category_type == "industrial" ||
				property_category_type == "warehousing") &&
			property_type?.length !== 0 ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{(property_transaction_type === "ofb" ? "OFB" : "Property") + " subtype :"}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_subtype}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							dispatch(setCreateNewPropertySubTypeThunk(event.target.value));
						}}
						label={(property_transaction_type === "ofb" ? "OFB" : "Property") + " subtype :"}
					>
						{subtype_options?.map((name: string) => (
							<MenuItem
								key={name}
								value={name}
							>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{/* Price */}

			{property_transaction_type !== "ofb" &&
			property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 ? (
				<TextContainer>
					{property_transaction_type == "buy" &&
					!property_type?.includes("Independent House") &&
					!property_type?.includes("Land (Residential)") &&
					!property_type?.includes("Land (Commercial)") &&
					!property_type?.includes("Land (Agricultural)") &&
					!property_type?.includes("Land (Industrial)") &&
					!property_type?.includes("Co-living/PG") &&
					!property_type?.includes("Building") &&
					!property_type?.includes("Shop") &&
					!property_type?.includes("Office Space") &&
					!property_type?.includes("Co-working") &&
					!property_type?.includes("Warehouse") &&
					!property_type?.includes("Factory") &&
					!property_type?.includes("Mining") ? (
						<TextContainer
							sx={{
								alignItems: "flex-start",
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									gap: "1.5rem",
								},
							}}
						>
							<TextField
								value={property_min_price === 0 ? "" : NumberToCurrencyPipe(propertyMinPrice)}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem 0rem 0rem 0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								inputProps={{
									min: 0,
								}}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(property_max_price !== 0 && CurrencyToNumberPipe(event.target.value) > propertyMaxPrice) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										// setSubmitDisabled(false);
									}
								}}
								label="Minimum Price*"
								error={errorMinPrice}
								helperText={errorMinPrice ? "Value is more than max price" : NumberInWords(propertyMinPrice)}
								autoComplete="off"
							/>

							<TextField
								value={property_max_price === 0 ? "" : NumberToCurrencyPipe(propertyMaxPrice)}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem 0rem 0rem 0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								inputProps={{
									min: 0,
								}}
								autoComplete="off"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(property_min_price !== 0 && CurrencyToNumberPipe(event.target.value) < propertyMinPrice) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										// setSubmitDisabled(false);
									}
								}}
								label="Maximum Price*"
								error={errorMaxPrice}
								helperText={errorMaxPrice ? "Value is less than min price" : NumberInWords(property_max_price)}
							/>
						</TextContainer>
					) : (
						<TextContainer>
							<TextField
								value={property_max_price === 0 ? "" : NumberToCurrencyPipe(propertyMaxPrice)}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									dispatch(setCreateNewPropertyMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (isNaN(CurrencyToNumberPipe(event.target.value))) {
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMaxPrice(false);
										// setSubmitDisabled(false);
									}
								}}
								autoComplete="off"
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								label={
									property_transaction_type == "resale" ||
									(property_transaction_type !== "rent" &&
										(property_type?.includes("Independent House") ||
											property_type?.includes("Land (Residential)") ||
											property_type?.includes("Land (Commercial)") ||
											property_type?.includes("Land (Agricultural)") ||
											property_type?.includes("Land (Industrial)") ||
											property_type?.includes("Co-living/PG") ||
											property_type?.includes("Building") ||
											property_type?.includes("Shop") ||
											property_type?.includes("Office Space") ||
											property_type?.includes("Co-working") ||
											property_type?.includes("Warehouse") ||
											property_type?.includes("Factory") ||
											property_type?.includes("Mining")))
										? "Price*"
										: "Monthly rent*"
								}
								error={errorMaxPrice}
								helperText={errorMaxPrice ? "Enter a valid number" : NumberInWords(property_max_price)}
								// helperText={NumberInWords(property_max_price)}
							/>
							{property_transaction_type == "rent" && property_category_type !== "" && property_type?.length !== 0 ? (
								<TextField
									value={property_security_deposit.max === 0 ? "" : NumberToCurrencyPipe(property_security_deposit.max)}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										dispatch(setCreateNewPropertySecurityDepositThunk(CurrencyToNumberPipe(event.target.value)));
									}}
									autoComplete="off"
									sx={{
										width: "100%",
										[theme.breakpoints.down("sm")]: {
											margin: "0rem 0rem 0.5rem 0rem",
											minWidth: "100%",
										},
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									label="Security Deposit"
									helperText={NumberInWords(property_security_deposit.max)}
								/>
							) : null}
						</TextContainer>
					)}
				</TextContainer>
			) : null}

			{/* Property Status / Availability */}

			{(property_transaction_type !== "ofb" &&
				property_transaction_type !== "" &&
				property_category_type !== "" &&
				property_type?.length !== 0 &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "rent" &&
				property_category_type !== "" &&
				(property_type?.includes("Land (Residential)") ||
					property_type?.includes("Land (Commercial)") ||
					property_type?.includes("Land (Agricultural)") ||
					property_type?.includes("Land (Industrial)") ||
					property_type?.includes("Mining"))) ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{property_transaction_type !== "rent" ? "Property status*" : "Available from*"}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_availability?.map(
							(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
						)}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(e: SelectChangeEvent<string[]>) => {
							handleChangeAvailability(e);
						}}
						input={
							<OutlinedInput label={property_transaction_type !== "rent" ? "Property status*" : "Available from*"} />
						}
						renderValue={(selected) => selected.join(", ")}
					>
						{property_transaction_type !== "rent"
							? AvailItems?.map((name: string) => (
									<MenuItem
										key={name}
										value={name}
									>
										<ListItemText primary={name} />
									</MenuItem>
								))
							: RentAvailabilty?.map((name: string) => (
									<MenuItem
										key={name}
										value={name}
									>
										<ListItemText primary={name} />
									</MenuItem>
								))}
					</Select>
				</FormControl>
			) : null}

			{/* Possession Date */}

			{property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			(property_availability?.includes("Under construction") ||
				property_availability?.includes("under_construction") ||
				property_availability?.includes("Select date") ||
				property_availability?.includes("select_date")) ? (
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Possesion date*"
						value={dayjs(property_possession_date)}
						// onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						// 	dispatch(setCreateNewePropertyPossessionDateThunk(new Date(e)));
						// }}
						onChange={(date: Dayjs | null) => {
							if (date) {
								dispatch(setCreateNewePropertyPossessionDateThunk(date.toDate()));
							}
						}}
						disablePast
						format="DD/MM/YYYY"
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
					/>
				</LocalizationProvider>
			) : null}

			{/* Property Age */}

			{property_transaction_type == "resale" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			!property_type?.includes("Land (Residential)") &&
			!property_type?.includes("Land (Commercial)") &&
			!property_type?.includes("Land (Agricultural)") &&
			!property_type?.includes("Land (Industrial)") &&
			!property_type?.includes("Mining") &&
			!property_availability?.includes("Under construction") &&
			!property_availability?.includes("under_construction") &&
			!property_availability?.includes("Select date") &&
			!property_availability?.includes("select_date") ? (
				<TextField
					value={property_age === 0 ? "" : property_age}
					inputProps={{
						min: 0,
					}}
					autoComplete="off"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						dispatch(setCreateNewPropertyAgeThunk(Number(event.target.value.replace(/\D/g, ""))));
						if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
							setErrorPropertyAge(true);
						} else {
							setErrorPropertyAge(false);
						}
					}}
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={errorPropertyAge}
					helperText={errorPropertyAge ? "Enter a valid number" : ""}
					label="Property age (In years)"
				/>
			) : null}

			{/* Unit Facing */}

			{(property_transaction_type !== "" &&
				property_category_type !== "" &&
				property_type?.length !== 0 &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining") &&
				!property_type?.includes("Office Space") &&
				!property_type?.includes("Co-working")) ||
			(property_transaction_type == "buy" &&
				property_category_type !== "" &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "resale" &&
				property_category_type !== "" &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "rent" &&
				property_category_type !== "" &&
				property_type?.includes("Office Space")) ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{property_type?.includes("Plot") ||
						property_type?.includes("Farm Plot") ||
						property_type?.includes("Plot (Commercial)")
							? "Facing"
							: "Facing"}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple={
							property_transaction_type == "buy" &&
							(property_type?.includes("Apartment") ||
								property_type?.includes("Plot") ||
								property_type?.includes("Farm Plot") ||
								property_type?.includes("Plot (Commercial)") ||
								property_type?.includes("Row House") ||
								property_type?.includes("Villa") ||
								property_type?.includes("Builder Floor Apartment") ||
								property_type?.includes("Penthouse"))
								? true
								: false
						}
						value={property_unit_facing}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={handleChangeUnitFacing}
						input={
							<OutlinedInput
								label={
									property_type?.includes("Plot") ||
									property_type?.includes("Farm Plot") ||
									property_type?.includes("Plot (Commercial)")
										? "Facing"
										: "Facing"
								}
							/>
						}
						renderValue={(selected) => selected.join(", ")}
					>
						{Facing?.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								{property_transaction_type == "buy" &&
								(property_type?.includes("Apartment") ||
									property_type?.includes("Plot") ||
									property_type?.includes("Farm Plot") ||
									property_type?.includes("Plot (Commercial)") ||
									property_type?.includes("Row House") ||
									property_type?.includes("Villa") ||
									property_type?.includes("Builder Floor Apartment") ||
									property_type?.includes("Penthouse")) ? (
									<Checkbox checked={property_unit_facing.indexOf(name) > -1} />
								) : null}

								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{/* Land Facing */}

			{property_transaction_type !== "ofb" &&
			property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			(property_type?.includes("Land (Residential)") ||
				property_type?.includes("Land (Commercial)") ||
				property_type?.includes("Land (Agricultural)") ||
				property_type?.includes("Land (Industrial)") ||
				property_type?.includes("Warehouse") ||
				property_type?.includes("Factory") ||
				property_type?.includes("Mining")) ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">Facing</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_land_facing}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={handleChangeLandFacing}
						input={<OutlinedInput label="Facing" />}
						renderValue={(selected) => selected.join(", ")}
					>
						{Facing.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								<Checkbox
									sx={{ display: "none" }}
									checked={property_land_facing.indexOf(name) > -1}
								/>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
		</PaperContainer>
	);
};

export default PropertyDetails;
