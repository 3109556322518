/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "8px 8px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "5px 5px 0px 0px",
}));

const GridImage = ({ images }: { images: string;}) => {
	return (
		<Container>
			<Image
				src={images}
				alt="service image"
				referrerPolicy="no-referrer"
			/>
		</Container>
	);
};

export default GridImage;
