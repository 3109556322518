// FileValidator.ts
/*

* This library function is written to aid in the validation of files selected by the user. It consist of three validation steps.

* Check if the file is a valid image file by checking the file extension and mime type.
* Check if the file size is less than or equal to 10MB.
* Check if the file is a valid file by checking the magic bytes of the file.

^ Before we write the function, lets describe the parameter that the function will take in.

^ @param fileList: File[] - This is an array of files that the user has selected. The File interface is a built-in interface in TypeScript that is used to represent the file coming from the user.
^ @returns Promise<{ validFiles: File[], invalidFiles: File[] }> - This function returns a promise that resolves to an object with two properties: validFiles and invalidFiles. The validFiles property is an array of files that passed the validation and the invalidFiles property is an array of files that failed the validation.
^ Now that we have described the function, lets write the function.

 */

interface FileAndMore {
	file: File;
	mime_type: string;
}

//  & Next, let's describe the maximum accepted size fot different files
const maxSize: { [key: string]: number } = {
	image: 10 * 1024 * 1024, // 10MB for images
	video: 1024 * 1024 * 1024, // 1GB for videos
	application: 50 * 1024 * 1024, // 50MB for PDFs
};

// & Next, let's write the function to get the file extension from the filename
function getFileExtension(filename: string): string {
	// & Next, Split the filename by dot (.) and get the last part of the array which is the file extension and return it as a string
	const parts = filename.split(".");

	// Use non-null assertion (!) to tell TypeScript that parts will not be undefined
	return parts[parts.length - 1]!;
}

// & Next, let's write the function to validate the files
async function validateFiles(
	// * The function takes in the following parameters
	// * fileList: File[] - An array of files that the user has selected
	// * mimeTypes: string[] - An array of mime types that the file should be
	// * validExtensions: string[] - An array of valid file extensions
	// * uploadType: string - A string that represents the type of upload. It can be "post", "property_or_services" or "user_or_business"

	fileList: Array<File>,
	mimeTypes: Array<string>,
	validExtensions: Array<string>,
	uploadType: string,
): // * The function returns a promise that resolves to an object with two properties
Promise<{ validFiles: Array<FileAndMore>; invalidFiles: Array<File> }> {
	// & Next, let's Create two empty arrays to hold the valid files and invalid files
	const validFiles: Array<FileAndMore> = [];
	const invalidFiles: Array<File> = [];

	// & Next, let's Loop through the fileList array and validate each file
	for (let i = 0; i < fileList.length; i++) {
		// & Next, let's Get the current file in the loop
		const currentFile = fileList[i];

		// & Next, let's Check if the upload type is "post". Post accepts images, videos and PDFs so we need to check the magic bytes of the file to determine if it is a valid file
		if (uploadType === "post") {
			// & Next, let's Check if the current file is not null and the mime type of the file is in the mimeTypes array and the file extension is in the validExtensions array
			if (
				currentFile &&
				mimeTypes.includes(currentFile.type) &&
				validExtensions.includes(getFileExtension(currentFile.name))
			) {
				// & Next, let's Get the first 12 bytes of the file and convert it to a Uint8Array. This is used to check the magic bytes of the file. The magic bytes are the first few bytes of a file that are used to identify the file type
				const magicBytes = new Uint8Array(await currentFile.slice(0, 12).arrayBuffer());

				// & Next, let's Check if the file is a JPEG image by checking the first two bytes of the file
				const isJPEG = magicBytes[0] === 0xff && magicBytes[1] === 0xd8;

				// & Next, let's Check if the file is a PNG image by checking the first four bytes of the file
				const isPNG =
					magicBytes[0] === 0x89 && magicBytes[1] === 0x50 && magicBytes[2] === 0x4e && magicBytes[3] === 0x47;

				// & Next, let's Check if the file is a MP4 video by checking the first 12 bytes of the file, the first 4 bytes are offset to 4
				const isMP4 =
					magicBytes[4] === 0x66 &&
					magicBytes[5] === 0x74 &&
					magicBytes[6] === 0x79 &&
					magicBytes[7] === 0x70 &&
					magicBytes[8] === 0x69 &&
					magicBytes[9] === 0x73 &&
					magicBytes[10] === 0x6f &&
					magicBytes[11] === 0x6d;

				// & Next, let's Check if the file is a MOV video by checking the first 12 bytes of the file, the first 4 bytes are offset to 4
				const isMOV =
					magicBytes[4] === 0x66 &&
					magicBytes[5] === 0x74 &&
					magicBytes[6] === 0x79 &&
					magicBytes[7] === 0x70 &&
					magicBytes[8] === 0x71 &&
					magicBytes[9] === 0x74 &&
					magicBytes[10] === 0x20 &&
					magicBytes[11] === 0x20;

				// & Next, let's Check if the file is a PDF file by checking the first 4 bytes of the file
				const isPDF =
					magicBytes[0] === 0x25 && magicBytes[1] === 0x50 && magicBytes[2] === 0x44 && magicBytes[3] === 0x46;

				// & Next, let's Get the file type by splitting the file type string by the forward slash (/) and getting the first part of the array
				const fileType: string = currentFile.type.split("/")[0];

				// & Next, let's Get the maximum size in bytes for the file type
				const maxSizeInBytes = maxSize[fileType];

				// for (let i = 4; i < magicBytes.length; i++) {
				//   console.log(magicBytes[i], magicBytes[i].toString(16));
				// }

				// & Next, let's Check if the file is a valid file and the file size is less than or equal to the maximum size
				if ((isJPEG || isPNG || isMP4 || isMOV || isPDF) && currentFile.size <= maxSizeInBytes) {
					// & Next, If the file is valid, push it to the validFiles array
					validFiles.push({
						file: currentFile,
						mime_type:
							(isJPEG || isPNG ? "image" : isMP4 || isMOV ? "video" : "application") +
							"/" +
							(isJPEG ? "jpeg" : isPNG ? "png" : isMP4 ? "mp4" : isMOV ? "mov" : "pdf"),
					});
				} else {
					// & Next, If the file is invalid, push it to the invalidFiles array
					invalidFiles.push(currentFile);
				}
			} else {
				// & Next, If the file is not valid, push it to the invalidFiles array
				invalidFiles.push(currentFile);
			}

			// & Next, let's Check if the upload type is "property_or_services", property_or_services accepts only images so we need to check the magic bytes of the file to determine if it is a valid file
		} else if (uploadType === "property_or_services") {
			// & Next, let's Check if the current file is not null and the mime type of the file is in the mimeTypes array and the file extension is in the validExtensions array
			if (
				currentFile &&
				mimeTypes.includes(currentFile.type) &&
				validExtensions.includes(getFileExtension(currentFile.name))
			) {
				// & Next, let's Get the first 4 bytes of the file and convert it to a Uint8Array. This is used to check the magic bytes of the file. The magic bytes are the first few bytes of a file that are used to identify the file type
				const magicBytes = new Uint8Array(await currentFile.slice(0, 4).arrayBuffer());

				// & Next, let's Check if the file is a JPEG image by checking the first two bytes of the file
				const isJPEG = magicBytes[0] === 0xff && magicBytes[1] === 0xd8;

				// & Next, let's Check if the file is a PNG image by checking the first four bytes of the file
				const isPNG =
					magicBytes[0] === 0x89 && magicBytes[1] === 0x50 && magicBytes[2] === 0x4e && magicBytes[3] === 0x47;

				// & Next, let's Get the file type by splitting the file type string by the forward slash (/) and getting the first part of the array
				const fileType: string = currentFile.type.split("/")[0];

				// & Next, let's Get the maximum size in bytes for the file type
				const maxSizeInBytes = maxSize[fileType];

				// & Next, let's Check if the file is a valid file and the file size is less than or equal to the maximum size
				if ((isJPEG || isPNG) && currentFile.size <= maxSizeInBytes) {
					// & Next, If the file is valid, push it to the validFiles array
					validFiles.push({
						file: currentFile,
						mime_type: "image/" + (isJPEG ? "jpeg" : "png"),
					});
				} else {
					// & Next, If the file is invalid, push it to the invalidFiles array
					invalidFiles.push(currentFile);
				}
			} else {
				invalidFiles.push(currentFile);
			}

			// & Next, let's Check if the upload type is "user_or_business", user_or_business accepts only images so we need to check the magic bytes of the file to determine if it is a valid file
		} else if (uploadType === "user_or_business") {
			// & Next, let's Check if the current file is not null and the mime type of the file is in the mimeTypes array and the file extension is in the validExtensions array
			if (
				currentFile &&
				mimeTypes.includes(currentFile.type) &&
				validExtensions.includes(getFileExtension(currentFile.name))
			) {
				// & Next, let's Get the first 4 bytes of the file and convert it to a Uint8Array. This is used to check the magic bytes of the file. The magic bytes are the first few bytes of a file that are used to identify the file type
				const magicBytes = new Uint8Array(await currentFile.slice(0, 4).arrayBuffer());

				// & Next, let's Check if the file is a JPEG image by checking the first two bytes of the file
				const isJPEG = magicBytes[0] === 0xff && magicBytes[1] === 0xd8;

				// & Next, let's Check if the file is a PNG image by checking the first four bytes of the file
				const isPNG =
					magicBytes[0] === 0x89 && magicBytes[1] === 0x50 && magicBytes[2] === 0x4e && magicBytes[3] === 0x47;

				// & Next, let's  Get the file type by splitting the file type string by the forward slash (/) and getting the first part of the array
				const fileType: string = currentFile.type.split("/")[0];

				//& Next, let's Get the maximum size in bytes for the file type
				const maxSizeInBytes = maxSize[fileType];

				// & Next, let's Check if the file is a valid file and the file size is less than or equal to the maximum size
				if ((isJPEG || isPNG) && currentFile.size <= maxSizeInBytes) {
					// & Next, If the file is valid, push it to the validFiles array
					validFiles.push({
						file: currentFile,
						mime_type: "image/" + (isJPEG ? "jpeg" : "png"),
					});
				} else {
					// & Next, If the file is invalid, push it to the invalidFiles array
					invalidFiles.push(currentFile);
				}
			} else {
				// & Next, If the file is not valid, push it to the invalidFiles array
				invalidFiles.push(currentFile);
			}
		}
	}

	// & Next, let's Return an object with the validFiles and invalidFiles arrays
	return {
		validFiles,
		invalidFiles,
	};
}

export default validateFiles;
