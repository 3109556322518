/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { updateNotificationViewedThunk } from "../../../../redux-magic/store";
import { ObjectId } from "mongodb";

/* Component Imports */

import { Skeleton, Typography, useTheme } from "@mui/material";

/* Icon Imports */

import { Circle } from "@mui/icons-material";

/* Library Function Imports */

import TimeAgo from "../../../../lib/TimeAgo";

/* Styled Components */

const NotificationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "0.75rem",
  width: "100%",
  textDecoration: "none",
  "@media (pointer: fine)": {
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(0, 0, 0, 0.04)"
          : "rgba(0, 0, 0, 0.04)",
    },
  },
  cursor: "pointer",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const Img = styled("img")(({ theme }) => ({
  width: "3rem",
  height: "3rem",
  borderRadius: "8px",
  objectFit: "cover",
}));

const NotificationContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}));

// const Title = styled(Typography)(({ theme }) => ({
//   fontWeight: "500",
//   fontSize: "0.875rem",
//   lineHeight: "1rem",
// }));

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "0.8rem",
  lineHeight: "1rem",
}));

const TimePassed = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "0.65rem",
  lineHeight: "1rem",
}));

const NotificationCard = ({
  notification_id,
  title,
  description,
  created_at,
  sender_details,
  redirect_url,
  viewed,
  loading,
  navbar,
  dispatch,
  profile_context,
}: {
  notification_id: ObjectId | string;
  title: string;
  description: string;
  created_at: Date | string;
  sender_details: any;
  redirect_url: string;
  viewed: boolean;
  loading: boolean;
  navbar?: boolean;
  dispatch: any;
  profile_context: StateInterface["profile_context"];
}) => {
  const theme = useTheme();

  const [senderImage, setSenderImage] = React.useState(
    sender_details && sender_details.is_business_profile
      ? sender_details.business_profile_id
        ? sender_details.business_profile_id.display_picture_url
          ? sender_details.business_profile_id.display_picture_url.url &&
            sender_details.business_profile_id.display_picture_url.url !==
              "string"
            ? sender_details.business_profile_id.display_picture_url.url.lqip
            : "https://beegru.com/images/icons/Avatar.svg"
          : "https://beegru.com/images/icons/Avatar.svg"
        : "https://beegru.com/images/icons/Avatar.svg"
      : sender_details.user_id
        ? sender_details.user_id.profile_picture
          ? sender_details.user_id.profile_picture.url &&
            sender_details.user_id.profile_picture.url !== "string"
            ? sender_details.user_id.profile_picture.url.lqip
            : "https://beegru.com/images/icons/Avatar.svg"
          : "https://beegru.com/images/icons/Avatar.svg"
        : "https://beegru.com/images/icons/Avatar.svg"
  );

  React.useEffect(() => {
    setSenderImage(
      sender_details && sender_details.is_business_profile
        ? sender_details.business_profile_id
          ? sender_details.business_profile_id.display_picture_url
            ? sender_details.business_profile_id.display_picture_url.url &&
              sender_details.business_profile_id.display_picture_url.url !==
                "string"
              ? sender_details.business_profile_id.display_picture_url.url
                  .regular
              : "https://beegru.com/images/icons/Avatar.svg"
            : "https://beegru.com/images/icons/Avatar.svg"
          : "https://beegru.com/images/icons/Avatar.svg"
        : sender_details.user_id
          ? sender_details.user_id.profile_picture
            ? sender_details.user_id.profile_picture.url &&
              sender_details.user_id.profile_picture.url !== "string"
              ? sender_details.user_id.profile_picture.url.regular
              : "https://beegru.com/images/icons/Avatar.svg"
            : "https://beegru.com/images/icons/Avatar.svg"
          : "https://beegru.com/images/icons/Avatar.svg"
    );
  }, [sender_details]);

  const handleClickNotification = async () => {
    window.open(redirect_url, "_blank");
    if (!viewed) {
      await dispatch(
        updateNotificationViewedThunk({
          notification_id: notification_id,
          profile_context_is_business_profile: profile_context
            ? profile_context.is_business_profile !== undefined
              ? profile_context.is_business_profile
              : false
            : false,
          profile_context_user_id: profile_context
            ? profile_context.user_id
              ? profile_context.user_id
              : ""
            : "",
          profile_context_business_profile_id: profile_context
            ? profile_context.business_profile_id
              ? profile_context.business_profile_id
              : ""
            : "",
        })
      );
    }
  };

  if (loading)
    return (
      <React.Fragment>
        <NotificationContainer
          sx={{
            padding: navbar
              ? "0.5rem 1rem 0.5rem 1rem"
              : "0.75rem 1rem 0.75rem 1rem",
          }}
        >
          <ImageContainer>
            <Skeleton
              variant="rounded"
              sx={{ width: "3.2rem", height: "3.2rem", borderRadius: "8px" }}
            />
          </ImageContainer>
          <NotificationContent sx={{ padding: "0rem 0rem 0rem 0.5rem" }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "0.8rem", width: "60%" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "0.65rem", width: "20%" }}
            />
          </NotificationContent>
        </NotificationContainer>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <NotificationContainer
        sx={{
          padding: navbar
            ? "0.5rem 1rem 0.5rem 1rem"
            : "0.75rem 1rem 0.75rem 1rem",
        }}
        onClick={handleClickNotification}
      >
        <ImageContainer>
          <Img
            src={senderImage}
            alt="notification"
            height={24}
            width={24}
            referrerPolicy="no-referrer"
          />
        </ImageContainer>

        <NotificationContent>
          {/* Title */}

          {/* <Title
            sx={
              viewed
                ? {
                    color: theme.palette.mode == "dark" ? "#818181" : "#818181",
                  }
                : {
                    color:
                      theme.palette.mode == "dark"
                        ? "#ffffff"
                        : "rgba(0, 0, 0, 0.87)",
                  }
            }
            variant="body2"
          >
            {title}
          </Title> */}

          {/* Description */}

          <Description
            sx={
              viewed
                ? {
                    color: theme.palette.mode == "dark" ? "#818181" : "#818181",
                  }
                : {
                    color:
                      theme.palette.mode == "dark"
                        ? "#ffffff"
                        : "rgba(0, 0, 0, 0.87)",
                  }
            }
            variant="body1"
          >
            {/* Bold multiple substrings between ** characters */}

            {description.split("**").map((item, index) => {
              if (index % 2) {
                return <b key={index}>{item}</b>;
              } else {
                return <span key={index}>{item}</span>;
              }
            })}
          </Description>

          {/* Time Passed */}

          <TimePassed
            sx={
              viewed
                ? {
                    color: theme.palette.mode == "dark" ? "#818181" : "#818181",
                  }
                : {
                    color: "#FC8019",
                  }
            }
          >
            {created_at ? TimeAgo(created_at) : ""} ago{" "}
          </TimePassed>
        </NotificationContent>

        {/* Viewed */}

        {viewed ? null : (
          <Circle
            color="primary"
            sx={{
              fontSize: "0.75rem",
              margin: "0rem 0rem 0rem 0.25rem",
              float: "center",
            }}
          />
        )}
      </NotificationContainer>
    </React.Fragment>
  );
};
export default NotificationCard;
