/* Redux Imports */

import StateInterface from "../redux-magic/state-interface";

/* This function takes in the service data and returns the percentage of the completion of mandatory fields for the service */

const CalculateServiceCompletionPercentage = (
  serviceData: StateInterface["service"]
) => {
  /* Let's declare a variable for the percentage of the service completion. We'll give it a default value of 0 */

  let percentage = 0;

  /* Let's declare a variable for the total number of fields that should be filled. This number can change based on several conditions */

  let total_fields = ["service_type", "service_subtype", "location"];

  /* Now, let's declare a variable for the number of fields that have been filled. This number will keep changing as those fields are filled */

  let completed_fields = 0;

  /* Now, let's check if fields are not empty. If it's not, we'll increment the completed_fields variable by 1, other wise we'll decrease completed_fields variable by 1 */

  /* Service Type */

  if (serviceData.service_type.length > 0) {
    completed_fields += 1;
  }

  /* Service Subtype */

  if (serviceData.service_subtype.length > 0) {
    completed_fields += 1;
  }

  /* Service Location */

  if (serviceData.location.title !== "") {
    completed_fields += 1;
  }

  /* Price */

  if (serviceData.price.show_price === true) {
    total_fields.push("price");
    if (
      serviceData.price.min !== 0 &&
      serviceData.price.min !== null &&
      serviceData.price.max !== 0 &&
      serviceData.price.max !== null &&
      serviceData.price.min <= serviceData.price.max
    ) {
      completed_fields += 1;
    }
  }

  /* Calculating the percentage */

  percentage = Math.round((completed_fields / total_fields.length) * 100);

  return percentage;
};

export default CalculateServiceCompletionPercentage;
