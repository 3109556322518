/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton, Typography, useTheme } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.145rem",
  letterSpacing: "0.0025rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
  marginRight: "0.25rem",
}));

const ServiceCardServiceSubtype = ({
  loading,
  service_subtype,
}: {
  loading: boolean;
  service_subtype: string;
}) => {
  const theme = useTheme();
  if (loading) {
    return (
      <React.Fragment>
        <Heading>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            style={{ borderRadius: "0.25rem" }}
          />
        </Heading>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Heading>
        <InfoIcon
          src={
            theme.palette.mode === "dark"
              ? "/images/icons/config/Vector-white.svg"
              : "/images/icons/config/price.svg"
          }
          alt="Mail Icon"
          width={16}
          height={16}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
        {service_subtype.length < 30
          ? service_subtype
          : service_subtype.substring(0, 30) + "..."}
      </Heading>
    </React.Fragment>
  );
};

export default ServiceCardServiceSubtype;
