/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Price, Area, BHK, PricePerUnit } from "../../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import PropertyCardImage from "./sub-components/PropertyCardImage";
import PropertyCardTitle from "./sub-components/PropertyCardTitle";
import PropertyCardConfigAndContact from "./sub-components/PropertyCardConfigAndContact";
import PropertyCardPrice from "./sub-components/PropertyCardPrice";
import PropertyCardLocation from "./sub-components/PropertyCardLocation";
import PropertyCardType from "./sub-components/PropertyCardType";
import PropertyCardCarpetArea from "./sub-components/PropertyCardCarpetArea";
import PropertyCardStatus from "./sub-components/PropertyCardStatus";
import { useTheme } from "@mui/material";
import PrepareConfigString from "../../../../../lib/PrepareConfigString";

/* Styled Components */

const PropertyCardContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "8px",
	objectFit: "contain",
	width: "20.53rem",
	margin: "1rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */

	[theme.breakpoints.up("xxs")]: {
		width: "16.5rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "20rem",
		height: "28.063rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "20rem",
		height: "28.063rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "21rem",
		height: "28.063rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "22rem",
		height: "28.063rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "23rem",
		height: "28.063rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "25.5rem",
		height: "28.063rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22rem",
		height: "28.063rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "20.5rem",
		height: "28.063rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22rem",
		height: "28.063rem",
	},
	[theme.breakpoints.up("md")]: {
		// width: "32.33%",
		height: "28.063rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "20.33rem",
		height: "28.063rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "21.33rem",
		height: "28.063rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.53rem",
		height: "28.063rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "23rem",
		height: "28.063rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "23.53rem",
		height: "28.063rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "26.33rem",
		height: "28.063rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 8px 8px",
	cursor: "pointer",
}));

const PriceTitleAndConfigContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "space-between",
	padding: "0.75rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const PriceAreaTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
}));

const TransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	width: "3.938rem",
	height: "1.75rem",
	borderRadius: "0.5rem",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const PropertyCard = ({
	imagePreview,
	title,
	transaction_type,
	price,
	property_type,
	location,
	propertyStatus,
	area,
	bhk,
	price_per_unit,
	floors,
	no_of_seats,
}: {
	imagePreview: string;
	title: string;
	transaction_type: string;
	price: Price;
	property_type: Array<string>;
	location: string;
	propertyStatus: Array<string>;
	area: Area;
	bhk: BHK;
	price_per_unit: PricePerUnit;
	floors: BHK;
	no_of_seats: BHK;
}) => {
	const theme = useTheme();

	const themetextcolorrent = theme.palette.mode == "dark" ? "#FFDCA8" : "#5F2B01";

	return (
		<React.Fragment>
			<PropertyCardContainer>
				{/* Image */}

				<PropertyCardImage images={imagePreview} />

				<PriceTitleAndConfigContainer>
					<PriceAreaTransactionContainer>
						<PropertyCardPrice price={price} />
						<TransactionContainer
							sx={{
								backgroundColor:
									transaction_type === "buy"
										? "#E8F6F1"
										: transaction_type === "rent"
											? "rgba(255, 181, 71, 0.25)"
											: "#E8F6F1",
								borderColor: "none",
							}}
						>
							<div
								style={{
									margin: "0.25rem 0rem 0rem 0rem",
									color:
										transaction_type === "buy"
											? "#1BA672"
											: transaction_type === "rent"
												? themetextcolorrent
												: "#1BA672",
									fontSize: "0.875rem",
								}}
							>
								{transaction_type && transaction_type === "buy"
									? "Sale"
									: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)}
							</div>
						</TransactionContainer>
					</PriceAreaTransactionContainer>

					<PropertyCardTitle title={title !== "" || undefined ? title : "Unknown Title"} />
				</PriceTitleAndConfigContainer>

				<PropertyCardInfoContainer>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							// alignItems:"flex-start",
							marginBottom: "1rem",
							width: "100%",
						}}
					>
						<PropertyCardType propertyType={property_type ? property_type : []} />

						<VerticalLine />

						<PropertyCardLocation location={location !== "" || undefined ? location : "Unknown Location"} />
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: "1rem",
							width: "100%",
						}}
					>
						<PropertyCardCarpetArea
							area={area}
							areaType={
								property_type
									? property_type.includes("Land (Residential)") ||
										property_type.includes("Land (Commercial)") ||
										property_type.includes("Land (Agricultural)") ||
										property_type.includes("Land (Industrial)")
										? "land_area"
										: "super_built_up_area"
									: "super_built_up_area"
							}
						/>
						<VerticalLine />
						<PropertyCardStatus propertyStatus={propertyStatus} />
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "flex-start",
							marginBottom: "1rem",
							width: "100%",
						}}
					>
						<PropertyCardConfigAndContact
							config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
						/>
					</div>
				</PropertyCardInfoContainer>
			</PropertyCardContainer>
		</React.Fragment>
	);
};

export default PropertyCard;
