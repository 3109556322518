/* Common Imports */

import React from "react";

/* Component Imports */

import { Button } from "@mui/material";
import { styled } from "@mui/system";

/* Primary Button Styles */

const PrimaryButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	textTransform: "none",
	fontSize: "0.875rem",
	"&.MuiButton-contained": {
		background: "#1BA672",
		color: "#FFFFFF",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#157f57",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		},
		"&.Mui-disabled": {
			background: "#BDBDBD",
			color: "#858585",
		},
	},
	"&.MuiButton-outlined": {
		color: theme.palette.mode === "dark" ? "#7fffcf" : "#1BA672",
		border: theme.palette.mode === "dark" ? "1px solid #7fffcf" : "1px solid #1BA672",
		background: theme.palette.mode === "dark" ? "#424242" : "#FFFFFF",
		"&:hover": {
			background: theme.palette.mode === "dark" ? "#157f57" : "#E8F6F1",
		},
		"&.Mui-disabled": {
			color: "#BDBDBD",
			border: "1px solid #BDBDBD",
		},
	},
	"&.MuiButton-text": {
		color: "#1BA672",
		"&:hover": {
			// background: "#FEEADA",
		},
		"&.Mui-disabled": {
			color: "#BDBDBD",
		},
	},
}));

/* Secondary Button Styles */

const SecondaryButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	fontSize: "0.875rem",
	textTransform: "none",
	"&.MuiButton-contained": {
		background: "#FFFFFF",
		color: "#212B36",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#808080",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			color: "#ffffff",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
		},
	},
	"&.MuiButton-outlined": {
		background: "#FFFFFF",
		color: "#212B36",
		border: "1px solid #919eab52",
		"&:hover": {
			background: "#919eab14",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
			border: "1px solid #919eab3d",
		},
	},
	"&.MuiButton-text": {
		color: theme.palette.mode == "dark" ? "#FFFFFF" : "#212B36",
		"&:hover": {
			background: "none",
		},
		"&:disabled": {
			color: "#919eabcc",
		},
	},
}));

/* Error Button Styles */

const ErrorButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	textTransform: "none",
	fontSize: "0.875rem",
	"&.MuiButton-contained": {
		background: "#1890FF",
		color: "#FFFFFF",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#0C53B7",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
		},
	},
	"&.MuiButton-outlined": {
		background: "#FFFFFF",
		color: "#1890FF",
		border: "1px solid #1890ff7a",
		"&:hover": {
			background: "#1890ff14",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
			border: "1px solid #919eab3d",
		},
	},
	"&.MuiButton-text": {
		background: theme.palette.mode == "dark" ? "#FFFFFF" : "#212B36",
		color: "#1890FF",
		"&:hover": {
			background: "none",
		},
		"&:disabled": {
			color: "#919eabcc",
		},
	},
}));

/* Warning Button Styles */

const WarningButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	textTransform: "none",
	fontSize: "0.875rem",
	"&.MuiButton-contained": {
		background: "#1890FF",
		color: "#FFFFFF",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#0C53B7",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
		},
	},
	"&.MuiButton-outlined": {
		background: "#FFFFFF",
		color: "#1890FF",
		border: "1px solid #1890ff7a",
		"&:hover": {
			background: "#1890ff14",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
			border: "1px solid #919eab3d",
		},
	},
	"&.MuiButton-text": {
		background: theme.palette.mode == "dark" ? "#FFFFFF" : "#212B36",
		color: "#1890FF",
		"&:hover": {
			background: "none",
		},
		"&:disabled": {
			color: "#919eabcc",
		},
	},
}));

/* Info Button Styles */

const InfoButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	textTransform: "none",
	fontSize: "0.875rem",
	"&.MuiButton-contained": {
		background: "#1890FF",
		color: "#FFFFFF",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#0C53B7",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
		},
	},
	"&.MuiButton-outlined": {
		background: "#FFFFFF",
		color: "#1890FF",
		border: "1px solid #1890ff7a",
		"&:hover": {
			background: "#1890ff14",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
			border: "1px solid #919eab3d",
		},
	},
	"&.MuiButton-text": {
		background: theme.palette.mode == "dark" ? "#FFFFFF" : "#212B36",
		color: "#1890FF",
		"&:hover": {
			background: "none",
		},
		"&:disabled": {
			color: "#919eabcc",
		},
	},
}));

/* Success Button Styles */

const SuccessButton = styled(Button)(({ theme }) => ({
	borderRadius: "12px",
	textTransform: "none",
	fontSize: "0.875rem",
	"&.MuiButton-contained": {
		background: "#1890FF",
		color: "#FFFFFF",
		boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		"&:hover": {
			background: "#0C53B7",
			boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
		},
	},
	"&.MuiButton-outlined": {
		background: "#FFFFFF",
		color: "#1890FF",
		border: "1px solid #1890ff7a",
		"&:hover": {
			background: "#1890ff14",
		},
		"&:disabled": {
			background: "#919eab3d",
			color: "#919eabcc",
			border: "1px solid #919eab3d",
		},
	},
	"&.MuiButton-text": {
		background: theme.palette.mode == "dark" ? "#FFFFFF" : "#212B36",
		color: "#1890FF",
		"&:hover": {
			background: "none",
		},
		"&:disabled": {
			color: "#919eabcc",
		},
	},
}));

const BeegruButton = (props: any) => {
	const { children } = props;
	return (
		<React.Fragment>
			{/* Primary Button */}

			{(props.flavor === "primary" || props.flavor === undefined) && (
				<PrimaryButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</PrimaryButton>
			)}

			{/* Secondary Button */}

			{props.flavor === "secondary" && (
				<SecondaryButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</SecondaryButton>
			)}

			{/* Error Button */}

			{props.flavor === "error" && (
				<ErrorButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</ErrorButton>
			)}

			{/* Warning Button */}

			{props.flavor === "warning" && (
				<WarningButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</WarningButton>
			)}

			{/* Info Button */}

			{props.flavor === "info" && (
				<InfoButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</InfoButton>
			)}

			{/* Success Button */}

			{props.flavor === "success" && (
				<SuccessButton
					variant={props.variant ? props.variant : "contained"}
					size={props.size ? props.size : "large"}
					{...props}
				>
					{children ? children : ""}
				</SuccessButton>
			)}
		</React.Fragment>
	);
};

export default BeegruButton;
