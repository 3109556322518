/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
  images_interface,
  ServiceMediaImage,
  ServicePrice,
} from "../../../redux-magic/sub-interfaces/sub-interfaces";
import { ObjectId } from "mongodb";

/* Component Imports */

import Link from "@mui/material/Link";
import ServiceCardImage from "./sub-components/ServiceCardImage";
import ServiceCardTitle from "./sub-components/ServiceCardTitle";
import ServiceCardServiceProvider from "./sub-components/ServiceCardServiceProvider";
import ServiceCardPrice from "./sub-components/ServiceCardPrice";
import ServiceCardLocation from "./sub-components/ServiceCardLocation";
import ServiceCardChips from "./sub-components/ServiceCardChips";
import ServiceCardServiceSubtype from "./sub-components/ServiceCardServiceSubtype";


/* Styled Components */

const ServiceLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.mode == "dark" ? "#fff" : "#000",
}));

const ServiceCardContainer = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  objectFit: "contain",
  width: "20.53rem",
  margin: "0.1rem",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
  /* XXS breakpoint */
  [theme.breakpoints.up("xxs")]: {
    width: "16.5rem",
    height: "27.5rem",
  },
  /* iphone 5 */
  [theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
    width: "18rem",
    height: "27.5rem",
  },
  /*galaxy S8+ - 360*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
    width: "20rem",
    height: "27rem",
  },
  /*iphone se - 375*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
    width: "21rem",
    height: "27.5rem",
  },
  /* iphone 12 pro and pixel - 390 */
  [theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
    width: "22rem",
    height: "27.5rem",
  },
  /*iphone XR - 414*/
  [theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
    width: "23rem",
    height: "27.5rem",
  },
  [theme.breakpoints.up("xsPlus")]: {
    width: "25.5rem",
    height: "27.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    width: "22rem",
    height: "27.5rem",
  },
  /*ipad Mini */
  [theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
    width: "20.5rem",
    height: "27.5rem",
  },
  /* ipad Air*/
  [theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
    width: "22rem",
    height: "27.5rem",
  },
  [theme.breakpoints.up("md")]: {
    // width: "32.33%",
    height: "27.5rem",
  },
  /* MDLG Breakpoint iPadPro*/
  [theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
    width: "20.33rem",
    height: "27.5rem",
  },
  /*720p and 768p breakpoint */
  [theme.breakpoints.up("lg")]: {
    width: "21.33rem",
    height: "27.5rem",
  },
  /* 1080p 125% breakpoint*/
  [theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
    width: "22.53rem",
    height: "27.5rem",
  },
  /* 1080p breakpoint*/
  [theme.breakpoints.up("xl")]: {
    width: "21.53rem",
    height: "27.5rem",
  },
  /* XXL breakpoint  2560p*/
  [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
    width: "23.53rem",
    height: "28rem",
  },
  /*4k breakpoint 3840p*/
  [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
    width: "26.33rem",
    height: "28rem",
  },
}));

const ServiceCardInfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "1rem",
  margin: "0rem 0rem 0rem 0rem",
  gap: "0.75rem",
  width: "100%",
  height: "100%",
  borderRadius: "0px 0px 8px 8px",
  zIndex: 5,
  cursor: "pointer",
}));

const ServiceCard = ({
  profile_context,
  session,
  dispatch,
  loading,
  service_id,
  slug,
  title,
  images,
  tags,
  location,
  price,
  service_provider,
  service_subtype,
  saved_content,
  assigned_to,
  isEditor,
  created_by,
  page_type,
  boosting,
  preview,
}: {
  profile_context: StateInterface["profile_context"];
  session: any;
  dispatch: Function;
  loading: boolean;
  service_id: string | ObjectId;
  slug: string;
  title: string;
  images: Array<images_interface>;
  tags: Array<string>;
  location: string;
  price: ServicePrice;
  service_provider: string;
  service_subtype: Array<string>;
  saved_content: Array<any>;
  assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
  isEditor?: boolean;
  created_by?: any;
  page_type?: string;
  boosting?: StateInterface["service"]["boost_information"];
  preview?: boolean;
}) => {
  let BoostingCheck = boosting?.active ? true : false;

  const [coverImage, setCoverImage] = React.useState<string>(
    images
      ? images[0]
        ? (images[0].url as ServiceMediaImage) &&
          typeof (images[0].url as ServiceMediaImage) !== "string"
          ? (images[0].url as ServiceMediaImage)
            ? ((images[0].url as ServiceMediaImage).lqip as string) !==
              "https://cdn.beegru.com/images/uploads/service.jpg/1536/864"
              ? ((images[0].url as ServiceMediaImage).lqip as string)
              : process.env.CDN_URL + "images/uploads/service.jpg"
            : process.env.CDN_URL + "images/uploads/service.jpg"
          : process.env.CDN_URL + "images/uploads/service.jpg"
        : process.env.CDN_URL + "images/uploads/service.jpg"
      : process.env.CDN_URL + "images/uploads/service.jpg"
  );

  React.useEffect(() => {
    setCoverImage(
      images
        ? images[0]
          ? (images[0].url as ServiceMediaImage) &&
            typeof (images[0].url as ServiceMediaImage) !== "string"
            ? (images[0].url as ServiceMediaImage)
              ? ((images[0].url as ServiceMediaImage).regular as string) !==
                "https://cdn.beegru.com/images/uploads/service.jpg/1536/864"
                ? ((images[0].url as ServiceMediaImage).regular as string)
                : process.env.CDN_URL + "images/uploads/service.jpg"
              : process.env.CDN_URL + "images/uploads/service.jpg"
            : process.env.CDN_URL + "images/uploads/service.jpg"
          : process.env.CDN_URL + "images/uploads/service.jpg"
        : process.env.CDN_URL + "images/uploads/service.jpg"
    );
  }, [images]);

  return (
    <React.Fragment>
      <ServiceLink
        href={preview ? "javascript:void(0)" : `/services/${slug}`}
        target={preview ? "_self" : "_blank"}
        referrerPolicy="no-referrer"
        title={title ? title : "Unknown Title"}
      >
        <ServiceCardContainer>
          <ServiceCardImage
            service_id={service_id}
            slug={slug}
            imagesrc={coverImage}
            alt={coverImage}
            dispatch={dispatch}
            session={session}
            profile_context={profile_context}
            loading={loading}
            saved_content={saved_content}
            created_by={created_by}
            page_type={page_type}
            assigned_to={assigned_to}
            isEditor={isEditor}
            boosting={boosting}
            preview={preview}
          />

          <ServiceCardInfoContainer
            sx={{
              borderBottom: BoostingCheck ? "3px solid #fc8019" : "none",
              borderRight: BoostingCheck ? "3px solid #fc8019" : "none",
              borderLeft: BoostingCheck ? "3px solid #fc8019" : "none",
              background: BoostingCheck ? "#fc80190d" : "none",
            }}
          >
            <ServiceCardTitle
              loading={loading}
              title={title !== "" || undefined ? title : "Unknown Title"}
            />
            <ServiceCardChips
              loading={loading}
              service_subtype={service_subtype}
              location={location}
              tags={tags}
            />
            <ServiceCardLocation
              loading={loading}
              location={
                location !== "" || undefined ? location : "Unknown Location"
              }
            />
            <ServiceCardServiceProvider
              loading={loading}
              service_provider={
                service_provider !== "" || undefined
                  ? service_provider
                  : "Unknown Service Provider"
              }
            />
            <ServiceCardPrice loading={loading} price={price} />
            <ServiceCardServiceSubtype
              loading={loading}
              service_subtype={
                service_subtype
                  ? service_subtype.length > 0
                    ? service_subtype[0]
                    : "Unknown Service Subtype"
                  : "Unknown Service Subtype"
              }
            />
          </ServiceCardInfoContainer>
        </ServiceCardContainer>
      </ServiceLink>
    </React.Fragment>
  );
};

export default ServiceCard;
