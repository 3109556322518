/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Area } from "../../../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";

/* Library Imports */

import prepareAreaString from "../../../../../../lib/PrepareAreaString";

/* Styled Components */

const Heading2 = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	// justifyContent: "space-between",
	alignItems: "flex-start",
	fontWeight: 400,
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.43rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const GridCardCarpetAndStatus = ({ area, areaType }: { area: Area; areaType: string }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Heading2>
				<>{prepareAreaString(area, areaType)}</>
				<Typography
					variant="body2"
					sx={{
						fontSize: "0.75rem",
						lineHeight: "1.245rem",
						letterSpacing: "0.025rem",
						margin: "0rem 0rem 0rem 0rem",
						fontWeight: 400,
						color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#666",
					}}
				>
					Area
				</Typography>
			</Heading2>
		</React.Fragment>
	);
};

export default GridCardCarpetAndStatus;
