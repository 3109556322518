import NumberToCurrencyPipe from "./NumberToCurrencyPipe";

const preparePriceString = (price: number) => {
	/* Absolute Price */

	let NewPriceWoUnit: number | string = Math.abs(price);

	let NewPrice: number | string = "";

	/* Showing price in lakhs if price is >= 1 lakh */

	if (NewPriceWoUnit >= 100000 && NewPriceWoUnit < 10000000) {
		NewPriceWoUnit = (NewPriceWoUnit / 100000).toFixed(2);

		/* If decimals have 0 value then remove them */

		if (NewPriceWoUnit.split(".")[1] === "00") {
			NewPriceWoUnit = NewPriceWoUnit.split(".")[0];
		}

		NewPrice = NewPriceWoUnit + "L";

		/* Showing price in crores if price is >= 1 crore */
	} else if (NewPriceWoUnit >= 10000000) {
		NewPriceWoUnit = (NewPriceWoUnit / 10000000).toFixed(2);

		/* If decimals have 0 value then remove them */

		if (NewPriceWoUnit.split(".")[1] === "00") {
			NewPriceWoUnit = NewPriceWoUnit.split(".")[0];
		}

		NewPrice = NewPriceWoUnit + "Cr";
	} else {
		NewPrice = NumberToCurrencyPipe(NewPriceWoUnit);
	}

	return NewPrice;
};

export default preparePriceString;
