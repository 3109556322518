/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Link, useTheme } from "@mui/material";

/* Styled Components */

const SocialRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
}));

const SocialIcon = styled("img")(({ theme }) => ({
  borderRadius: "8px",
}));

const FooterSocialIcons = (props: any) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <SocialRow>
        {/* Facebook */}

        <Link
          href="//facebook.com/beegrurealty"
          rel="noopener"
          target="_blank"
          referrerPolicy="no-referrer"
          title="Follow Beegru on Facebook"
        >
          <SocialIcon
            src={
              theme.palette.mode === "dark"
                ? process.env.CDN_URL +
                  "images/icons/social/facebook-white.svg"
                : process.env.CDN_URL + "images/icons/social/facebook.svg"
            }
            alt="Facebook"
            width={28}
            height={28}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </Link>

        {/* Instagram */}

        <Link
          href="//instagram.com/beegrurealty"
          rel="noopener"
          target="_blank"
          referrerPolicy="no-referrer"
          title="Follow Beegru on Instagram"
        >
          <SocialIcon
            src={
              theme.palette.mode === "dark"
                ? process.env.CDN_URL +
                  "images/icons/social/instagram-white.svg"
                : process.env.CDN_URL + "images/icons/social/instagram.svg"
            }
            alt="Instagram"
            width={28}
            height={28}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </Link>

        {/* X */}

        <Link
          href="//twitter.com/beegrurealty"
          title="Follow Beegru on X"
          rel="noopener"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <SocialIcon
            src={
              theme.palette.mode === "dark"
                ? process.env.CDN_URL + "images/icons/social/x-white.svg"
                : process.env.CDN_URL + "images/icons/social/x.svg"
            }
            alt="X"
            width={28}
            height={28}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </Link>

        {/* Linkedin */}

        <Link
          href="//linkedin.com/company/beegru"
          rel="noopener"
          target="_blank"
          referrerPolicy="no-referrer"
          title="Follow Beegru on Linkedin"
        >
          <SocialIcon
            src={
              theme.palette.mode === "dark"
                ? process.env.CDN_URL +
                  "images/icons/social/linkedin-white.svg"
                : process.env.CDN_URL + "images/icons/social/linkedin.svg"
            }
            alt="Linkedin"
            width={28}
            height={28}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </Link>

        {/* YouTube */}

        <Link
          href="//youtube.com/@BeegruRealty"
          title="Subscribe to Beegru on YouTube"
          rel="noopener"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <SocialIcon
            sx={{ margin: "0.25rem 0rem 0rem 0rem" }}
            src={
              theme.palette.mode === "dark"
                ? process.env.CDN_URL + "images/icons/social/youtube-white.svg"
                : process.env.CDN_URL + "images/icons/social/youtube.svg"
            }
            alt="YouTube"
            width={28}
            height={28}
            loading="lazy"
            referrerPolicy="no-referrer"
          />
        </Link>
      </SocialRow>
    </React.Fragment>
  );
};

export default FooterSocialIcons;
