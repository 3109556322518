/* Common Imports */

import React from "react";

/* Redux Imports */

import { ServicePrice } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography, useTheme, Skeleton } from "@mui/material";

/* Library Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.145rem",
  letterSpacing: "0.0025rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
  margin: "0rem 0.25rem 0.15rem 0rem",
}));

const ServiceCardPrice = ({
  price,
  loading,
}: {
  price: ServicePrice;
  loading: boolean;
}) => {
  const theme = useTheme();
  if (loading) {
    return (
      <React.Fragment>
        <Skeleton
          animation="wave"
          variant="text"
          width="75%"
          height="auto"
          style={{ marginBottom: "0.5rem" }}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Heading>
        <InfoIcon
          src={
            theme.palette.mode === "dark"
              ? "/images/icons/config/currency_rupee_white.svg"
              : "/images/icons/config/currency_rupee.svg"
          }
          alt="Price Icon"
          width={16}
          height={16}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
        {price
          ? price.show_price !== undefined
            ? price.show_price
              ? price.min === price.max
                ? PreparePriceString(price.max)
                : PreparePriceString(price.min) +
                  " - " +
                  PreparePriceString(price.max)
              : "Price on request"
            : "Price on request"
          : "Price on request"}
      </Heading>
    </React.Fragment>
  );
};

export default ServiceCardPrice;
