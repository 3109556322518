/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { createNewBusinessProfileThunk } from "../../../redux-magic/thunks";

/* Component Imports */

import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
} from "@mui/material";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/* Icon Imports */

import { Close } from "@mui/icons-material";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
  padding: "1rem 1rem 1rem 1rem",
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "0.5rem 1rem 0.5rem 1rem",
  background: theme.palette.background.paper,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "2rem",
  padding: "2rem 1rem 1.5rem 1rem",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  gap: "1rem",
  padding: "0rem 1rem 1.25rem 0rem",
  background: theme.palette.background.paper,
}));

const CreateBusinessModal = ({
  open,
  onClose,
  session,
  dispatch,
  new_business_profile_url,
}: {
  open: boolean;
  onClose: any;
  session: any;
  dispatch: Function;
  new_business_profile_url: StateInterface["new_business_profile_url"];
}) => {
  const [title, setTitle] = React.useState<string>("");
  const [type, setType] = React.useState<string>("agent");
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [currentModal, setCurrentModal] = React.useState<boolean>(false);

  const handleChange = (event: any) => {
    setType(event.target.value);
  };

  const handleClickCreateNewBusiness = async () => {
    setSubmitting(true);
    setCurrentModal(true);
    await dispatch(
      createNewBusinessProfileThunk({
        slug: title
          .toLowerCase()
          .replace(/\W+/g, "-")
          .replace(/^-/, "")
          .replace(/\-$/, ""),
        title: title,
        business_profile_type: type.toLowerCase(),
        creator_user_id: session.user.dbUser._id,
      })
    );
  };

  React.useEffect(() => {
    if (
      currentModal &&
      new_business_profile_url !== undefined &&
      new_business_profile_url !== "" &&
      type !== ""
    ) {
      window.open(
        `/${type.toLowerCase()}s/${new_business_profile_url}`,
        "_self"
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [new_business_profile_url]);

  return (
    <React.Fragment>
      <CustomDialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth={true}
        disableScrollLock={true}
      >
        <CustomDialogTitle>
          Create new business profile
          <IconButton aria-label="close" onClick={onClose}>
            {submitting ? null : <Close />}
          </IconButton>
        </CustomDialogTitle>

        <CustomDialogContent>
          <TextField
            id="title"
            name="Title"
            label="Business Name"
            required={true}
            value={title}
            variant="outlined"
            size="small"
            onChange={(event: any) => {
              setTitle(event.target.value);
            }}
            sx={{ width: "100%", marginTop: "0.5rem" }}
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="profile-type" sx={{ padding: "0rem" }}>
              Business profile type
            </InputLabel>
            <Select
              labelId="profile-type"
              label="Business profile type"
              size="small"
              value={type}
              onChange={handleChange}
            >
              <MenuItem value="agent">Agent</MenuItem>
              <MenuItem value="developer">Developer</MenuItem>
              <MenuItem value="landowner">Landowner</MenuItem>
              <MenuItem value="professional">Professional</MenuItem>
            </Select>
          </FormControl>
        </CustomDialogContent>

        <CustomDialogActions>
          {!submitting ? (
            <React.Fragment>
              {/* <BeegruButton
                flavor="primary"
                variant="outlined"
                onClick={onClose}
                name="cancel"
                type="button"
              >
                Cancel
              </BeegruButton> */}

              <BeegruButton
                flavor="primary"
                variant="contained"
                onClick={() => {
                  handleClickCreateNewBusiness();
                }}
                name="submit"
                type="button"
                disabled={title === ""}
              >
                Submit
              </BeegruButton>
            </React.Fragment>
          ) : (
            <CircularProgress />
          )}
        </CustomDialogActions>
      </CustomDialog>
    </React.Fragment>
  );
};

export default CreateBusinessModal;
