/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	fontWeight: 400,
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.43rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 1rem",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));

const GridCardStatus = ({ propertyStatus }: { propertyStatus: Array<string>; }) => {
	const theme = useTheme();

	let status = propertyStatus?.[0];
	let splitSata = status?.split("_") || [];

	status = splitSata.join(" ");

	return (
		<React.Fragment>
			<Heading>
				<Typography
					variant="body2"
					sx={{
						fontSize: "0.875rem",
						lineHeight: "1.43rem",
						letterSpacing: "0.0025rem",
						// margin: "0rem 0rem 0rem 1rem",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						maxWidth: "100%",
					}}
				>
					{status.substring(0, 1).toUpperCase() + status.substring(1)}
				</Typography>
				<Typography
					variant="body2"
					sx={{
						fontSize: "0.75rem",
						lineHeight: "1.245rem",
						letterSpacing: "0.009rem",
						color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#666",
						margin: "0rem 0rem 0rem 0rem",
						fontWeight: 400,
					}}
				>
					Status
				</Typography>
			</Heading>
		</React.Fragment>
	);
};

export default GridCardStatus;
