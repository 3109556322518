/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const ServiceCardTitle = ({
  loading,
  title,
}: {
  loading: boolean;
  title: string;
}) => {
  if (loading) {
    return (
      <React.Fragment>
        <Skeleton animation="wave" variant="text" width="75%" height="auto" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Heading>
        {title?.length < 30 ? title : title?.substring(0, 30) + "..."}
      </Heading>
    </React.Fragment>
  );
};

export default ServiceCardTitle;
