/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";

/* Styled Components */

const Heading2 = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	// justifyContent: "space-between",
	alignItems: "flex-start",
	fontWeight: 400,
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.43rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0.2rem 0rem 0rem",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));

const GridCardAreaAndConfig = ({ propertyType }: { propertyType: Array<string> }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Heading2>
				<Typography
					variant="body2"
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						maxWidth: "100%",
						letterSpacing: "0.009rem",
						"&:hover": {
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						},
					}}
				>
					{propertyType}
				</Typography>

				<Typography
					variant="body2"
					sx={{
						fontSize: "0.75rem",
						lineHeight: "1.245rem",
						letterSpacing: "0.025rem",
						color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#666",
						margin: "0rem 0rem 0rem 0rem",
						fontWeight: 400,
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						maxWidth: "100%",
						"&:hover": {
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						},
					}}
				>
					Type
				</Typography>
			</Heading2>
		</React.Fragment>
	);
};

export default GridCardAreaAndConfig;
