/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	fontWeight: 400,
	fontSize: "0.875rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1.145rem",
	letterSpacing: "0.0025rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#666666",
}));

const ServiceCardServiceProvider = ({ service_provider_name }: { service_provider_name: string }) => {
	return (
		<React.Fragment>
			<Heading>{service_provider_name}</Heading>
			<SubHeading>Service Provider </SubHeading>
		</React.Fragment>
	);
};

export default ServiceCardServiceProvider;
