/* Common Imports */

import React from "react";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";

/* Component Imports */

import { Typography } from "@mui/material";

/* Styled Components */
const ImgContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "12px",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
	fontWeight: 450,
	fontSize: "1.25rem",
	lineHeight: "1.25rem",
	textDecoration: "none",
	textAlign: "center",
	wordBreak: "break-word",
}));

const Initials = ({
	title,
	profiledropdown,
	header,
	businessprofile,
	postcard,
	comment,
	bottomnav,
	profilesdrawer,
	selectProfile,
	search,
}: {
	title: string;
	profiledropdown: boolean;
	header: boolean;
	businessprofile: boolean;
	postcard: boolean;
	comment: boolean;
	bottomnav?: boolean;
	profilesdrawer?: boolean;
	selectProfile?: boolean;
	search?: boolean;
}) => {
	const theme = useTheme();
	let initials;

	const words = title?.split(" ") || [];
	const firstName = words[0] || "";
	const lastName = words[words.length - 1] === "" ? words[words.length - 2] || "" : words[words.length - 1] || "";

	if (words.length === 1) {
		initials = firstName.substring(0, 1).toUpperCase();
	} else {
		initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
	}
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();

		// Event listener for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	let height;
	let width;
	let background;

	if (profiledropdown) {
		height = "3.75rem";
		width = "4.55rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (header) {
		height = "2.625rem";
		width = "2.625rem";
		background = theme.palette.mode === "dark" ? "#424242" : "#F5F5F5";
	} else if (businessprofile) {
		height = isMobile ? "3.15rem" : "4rem";
		width = isMobile ? "3.95rem" : "4rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (postcard) {
		height = isMobile ? "3.5rem" : "4.55rem";
		width = isMobile ? "7.5rem" : "6.45rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (comment) {
		height = "2.5rem";
		width = "2.5rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (bottomnav) {
		height = "1.75rem";
		width = "1.75rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (profilesdrawer) {
		height = "3rem";
		width = "3rem!important";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (selectProfile) {
		height = "2.75rem";
		width = "2.75rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else if (search) {
		height = isMobile ? "6.75rem" : "10.75rem";
		width = isMobile ? "8.25rem" : "10.75rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	} else {
		height = "6.25rem";
		width = "6.25rem";
		background = theme.palette.mode === "dark" ? "#303030" : "#F5F5F5";
	}

	return (
		<React.Fragment>
			{initials !== "B" ? (
				<ImgContainer
					sx={{
						height: height,
						width: width,
						background: background,
					}}
				>
					<Title
						sx={{
							fontSize: bottomnav ? "1rem" : "1.25rem",
							width: profilesdrawer ? "3rem" : selectProfile ? "2.75rem" : "auto",
						}}
					>
						{initials}
					</Title>
				</ImgContainer>
			) : (
				<PersonIcon
					sx={{
						display: "flex",
						width: "1.5em",
						height: "1.85rem",
						// height: "100%",
						// [theme.breakpoints.down("sm")]: {
						// 	width: "54%",
						// },
					}}
				/>
			)}
		</React.Fragment>
	);
};

export default Initials;
