/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import { StoreLeadsThunk } from "../../redux-magic/store";
import { ObjectId } from "mongodb";
import { BusinessProfileInterface, ServiceInterface, UserInterface } from "../../redux-magic/sub-interfaces/sub-interfaces";

/* Interfaces */

interface adminInterface {
  _id: string | ObjectId;
  admins: Array<{}>;
}

/* Component Imports */

import {
  BottomNavigation,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import BeegruButton from "../common-components/buttons/BeegruButton";
import { signIn } from "next-auth/react";

/* Modal Imports */

import PublishButtonModal from "../modals/ServiceModals/PublishUnpublishModal";

/* Library Function Imports */

import CalculateServiceCompletionPercentage from "../../lib/CalculateServiceCompletionPercentage";

/* Styled Components */

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  height: "4rem",
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 5,
  background: theme.palette.mode == "dark" ? "#212121" : "#FFFFFF",
  opacity: 1,
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  "& .Mui-selected": {
    "& .MuiBottomNavigationAction-label": {
      fontSize: "0.75rem",
      lineHeight: "20px",
    },
  },
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

interface LocalServiceInterface extends Omit<ServiceInterface, "created_by"> {
  created_by: {
    is_business_profile: boolean;
    business_profile_id: string | ObjectId | BusinessProfileInterface;
    user_id: string | ObjectId | UserInterface;
  };
}

const BottomNavService = ({
	session,
	dispatch,
	profile_context,
	serviceData,
	newPropertyData,
	studio,
	newServiceData,
}: {
	session?: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	serviceData?: any;
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();

	const [publishmodal, setPublishmodal] = React.useState(false);

	const [updateButtonText, setUpdateButtonText] = React.useState<string>(serviceData.service_data.status);

	const creator_id: ObjectId | string = serviceData.service_data.created_by
		? serviceData.service_data.created_by.is_business_profile
			? serviceData.service_data.created_by.business_profile_id._id
			: serviceData.service_data.created_by.user_id
		: "";

	const profile_context_id: ObjectId | string = profile_context
		? profile_context.is_business_profile
			? profile_context.business_profile_id
			: profile_context.user_id
		: "";

	const user_id: ObjectId | string = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser._id
					? session.user.dbUser._id
					: ""
				: ""
			: ""
		: "";

	const logged_user_access: string = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser
					? session.user.dbUser.access_level
						? session.user.dbUser.access_level
						: ""
					: ""
				: ""
			: ""
		: "";

	const adminAccess: adminInterface | string | ObjectId =
		serviceData.service_data.created_by.business_profile_id !== ""
			? serviceData.service_data.created_by.business_profile_id._id
			: { _id: "", admins: [] };

	let isEditor =
		(adminAccess as adminInterface)?.admins?.find((admin: any) => admin.user_id === session?.user?.dbUser._id) !==
		undefined;

	const phone = serviceData.service_data?.created_by?.is_business_profile
		? serviceData?.service_data?.created_by.business_profile_id
			? serviceData?.service_data?.created_by.business_profile_id.phone
				? serviceData?.service_data?.created_by.business_profile_id.phone.country_code +
					" " +
					serviceData?.service_data?.created_by?.business_profile_id?.phone?.phone_number
				: ""
			: ""
		: serviceData?.service_data?.created_by?.user_id
			? serviceData?.service_data?.created_by.user_id.country_code
				? serviceData?.service_data?.created_by.user_id.country_code +
					" " +
					serviceData?.service_data?.created_by.user_id.phone_number
				: ""
			: "";

	/* Button text */

	React.useEffect(() => {
		setUpdateButtonText(
			serviceData.service_data.status === "publish_requested"
				? "Publish requested"
				: serviceData.service_data.status === "published" && serviceData.service_data.published === true
					? "Unpublish"
					: serviceData.service_data.status === "working_in_progress"
						? "Service under review"
						: serviceData.service_data.status === "pending_qc"
							? "Service under review"
							: serviceData.service_data.status === "edited"
								? "Publish"
								: "Publish",
		);
	}, [serviceData.service_data.status, serviceData.service_data.published]);

	const GetCall = async () => {
		await dispatch(
			StoreLeadsThunk({
				name: session
					? session.user
						? session.user.dbUser
							? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
							: ""
						: ""
					: "",
				phone: {
					country_code: session
						? session.user
							? session.user.dbUser
								? session.user.dbUser.country_code
								: "+91"
							: "+91"
						: "+91",
					phone_number: session ? (session.user ? (session.user.dbUser ? session.user.dbUser.phone : "") : "") : "",
				},
				agreed_to_tc: true,
				source: "/services/" + serviceData.service_data?.service_data?.slug,
				listing_slug: serviceData.service_data?.service_data?.slug,
				listing_type: "service",
				is_guest: session ? false : true,
				listing_title: serviceData.service_data?.service_data?.title,
				listing_owner: {
					is_business_profile: serviceData.service_data?.service_data?.created_by?.is_business_profile,
					user_id: serviceData.service_data?.service_data?.created_by?.user_id,
					business_profile_id: serviceData.service_data?.service_data?.created_by?.business_profile_id?._id,
				},
				user_id: session ? (session.user ? (session.user.dbUser ? session.user.dbUser._id : "") : "") : "",
			}),
		);
	};

	return (
		<React.Fragment>
			{/* Publish Modal */}

			<PublishButtonModal
				serviceData={serviceData.service_data as LocalServiceInterface}
				open={publishmodal}
				handleClose={() => {
					setPublishmodal(false);
				}}
				dispatch={dispatch}
				profile_context={profile_context}
				isEditor={isEditor}
			/>

			{/* Bottom Navigation */}

			<CustomBottomNavigation showLabels>
				{creator_id ? (
					creator_id === profile_context_id ||
					isEditor ||
					(logged_user_access.includes("admin") &&
						logged_user_access.includes("service") &&
						serviceData.service_data.assigned_to.includes(user_id)) ||
					logged_user_access.includes("super_admin") ? (
						<ButtonsContainer sx={{ flexDirection: "column" }}>
							<Typography
								variant="caption"
								align="center"
								sx={{ color: "text.secondary" }}
							>
								{!serviceData.service_data.published
									? CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) < 25
										? "Please fill the mandatory fields"
										: CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) < 75
											? "Just a few more fields to go"
											: CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) < 100
												? "Your service is almost ready to publish"
												: "Tip: Services with full details get better reach"
									: "Your service is published"}
							</Typography>

							<BeegruButton
								flavor="primary"
								variant="contained"
								sx={{
									width: "95%",
									margin: "0rem 0.5rem 0rem 0.5rem",
									padding: "0rem",
								}}
								onClick={() => setPublishmodal(true)}
								disabled={
									(!serviceData.service_data.published &&
										CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) < 100) ||
									serviceData.service_data.status === "publish_requested" ||
									serviceData.service_data.status === "working_in_progress" ||
									serviceData.service_data.status === "pending_qc"
								}
							>
								<Typography
									variant="body1"
									sx={{
										position: "absolute",
										width: "100%",
										height: "2.3rem",
										zIndex: 1,
										textAlign: "center",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: "#FFFFFF",
									}}
								>
									{CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) < 100 &&
									!serviceData.service_data.published
										? CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface) + "%"
										: updateButtonText
											? updateButtonText
											: "Publish"}
								</Typography>
								<LinearProgress
									variant="determinate"
									sx={{
										width: "100%",
										height: "2.3rem",
										borderRadius: "8px",
									}}
									value={
										!serviceData.service_data.published
											? CalculateServiceCompletionPercentage(serviceData.service_data as ServiceInterface)
											: 100
									}
								/>
							</BeegruButton>
						</ButtonsContainer>
					) : (
						<ButtonsContainer>
							{serviceData.service_data.show_contact_details === true ? (
								<BeegruButton
									flavor="primary"
									variant="outlined"
									sx={{ width: "100%", margin: "0rem 0.5rem 0rem 0.5rem" }}
									href={"tel:" + phone}
								>
									Call now
								</BeegruButton>
							) : null}

							<BeegruButton
								flavor="primary"
								variant="contained"
								sx={{ width: "100%", margin: "0rem 0.5rem 0rem 0.5rem" }}
								onClick={() => (session ? GetCall() : signIn())}
							>
								Get a callback
							</BeegruButton>
						</ButtonsContainer>
					)
				) : null}
			</CustomBottomNavigation>
		</React.Fragment>
	);
};

export default BottomNavService;
