/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { useTheme } from "@mui/material";
import ServiceCardImage from "./sub-components/ServiceCardImage";
import ServiceCardTitle from "./sub-components/ServiceCardTitle";
import { Price } from "../../../../../redux-magic/sub-interfaces/sub-interfaces";
import ServiceCardPrice from "./sub-components/ServiceCardPrice";
import ServiceCardLocation from "./sub-components/ServiceCardLocation";
import ServiceCardServicetype from "./sub-components/ServiceCardServicetype";
import ServiceCardServiceSubtype from "./sub-components/ServiceCardServiceSubtype";
import ServiceCardServiceProvider from "./sub-components/ServiceCardServiceProvider";

/* Styled Components */

const ServiceCardContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "8px",
	objectFit: "contain",
	width: "20.53rem",
	margin: "1rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: "16.5rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "20rem",
		height: "28.5rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "20rem",
		height: "28.5rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "21rem",
		height: "28.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "22rem",
		height: "28.5rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "23rem",
		height: "28.5rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "25.5rem",
		height: "28.5rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22rem",
		height: "28.5rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "20.5rem",
		height: "28.5rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22rem",
		height: "28.5rem",
	},
	[theme.breakpoints.up("md")]: {
		// width: "32.33%",
		height: "28.5rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "20.33rem",
		height: "28.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "21.33rem",
		height: "28.5rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.53rem",
		height: "28.5rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "23rem",
		height: "28.5rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "23.53rem",
		height: "28.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "26.33rem",
		height: "28.5rem",
	},
}));

const ServiceCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 8px 8px",
	cursor: "pointer",
}));

const TypeAndSubtypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "space-between",
	gap: "0.25rem",
	width: "100%",
	padding: "1rem 0rem 1rem 0rem",
}));

const ServiceCard = ({
	imagePreview,
	title,
	price,
	location,
	service_type,
	service_subtype,
	other_service_subtype,
	service_provider_name,
}: {
	imagePreview: string;
	title: string;
	price: Price;
	location: string;
	service_type: Array<string>;
	service_subtype: Array<string>;
	other_service_subtype: string;
	service_provider_name: string;
}) => {
	const theme = useTheme();

	const themetextcolorrent = theme.palette.mode == "dark" ? "#FFDCA8" : "#5F2B01";

	return (
		<React.Fragment>
			<ServiceCardContainer>
				<ServiceCardImage images={imagePreview} />
				<ServiceCardInfoContainer>
					<ServiceCardPrice price={price} />
					<ServiceCardTitle title={title !== "" || undefined ? title : "Unknown Title"} />
					<ServiceCardLocation location={location} />
					<TypeAndSubtypeContainer>
						<ServiceCardServicetype service_type={service_type[0]} />
						<ServiceCardServiceSubtype
							service_subtype={service_subtype}
							other_service_subtype={other_service_subtype}
						/>
					</TypeAndSubtypeContainer>
					<ServiceCardServiceProvider
						service_provider_name={
							service_provider_name !== "" || undefined ? service_provider_name : "Unknown Provider"
						}
					/>
				</ServiceCardInfoContainer>
			</ServiceCardContainer>
		</React.Fragment>
	);
};

export default ServiceCard;
