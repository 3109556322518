/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Price } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Library Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.145rem",
  letterSpacing: "0.0025rem",
  margin: "0rem 0rem 0rem 0rem",
}));

const InfoIcon = styled("img")(({ theme }) => ({
  margin: "0rem 0.25rem 0.15rem 0rem",
}));

const GridCardPrice = ({
  price,
  loading,
}: {
  price: Price;
  loading: boolean;
}) => {
  const theme = useTheme();

  if (loading) {
    return (
      <React.Fragment>
        <Skeleton
          animation="wave"
          variant="text"
          width="75%"
          height="auto"
          style={{ marginBottom: "0.5rem" }}
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Heading>
        <InfoIcon
          src={
            theme.palette.mode === "dark"
              ? "/images/icons/config/currency_rupee_white.svg"
              : "/images/icons/config/currency_rupee.svg"
          }
          alt="Price Icon"
          width={16}
          height={16}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
        {price !== undefined
          ? price
            ? price.min === price.max
              ? PreparePriceString(price.max)
              : PreparePriceString(price.min) +
                " - " +
                PreparePriceString(price.max)
            : "Price not available"
          : ""}
      </Heading>
    </React.Fragment>
  );
};

export default GridCardPrice;
