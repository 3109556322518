/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";

/* Styled Components */

const Heading = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	// alignItems: "center",
	fontWeight: 400,
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.43rem",
	letterSpacing: "0.0025rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const GridCardLocation = ({ location }: {  location: string }) => {
	const theme = useTheme();
	
	return (
		<React.Fragment>
			<Heading>
				<>{location ? (location.length < 30 ? location : location?.substring(0, 15) + "...") : ""}</>
				<Typography
					variant="body2"
					sx={{
						fontSize: "0.75rem",
						lineHeight: "1.245rem",
						letterSpacing: "0.009rem",
						color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#666",
						margin: "0rem 0rem 0rem 0rem",
						fontWeight: 400,
					}}
				>
					Location
				</Typography>
			</Heading>
		</React.Fragment>
	);
};

export default GridCardLocation;
