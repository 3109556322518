/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { property_images, Price, PricePerUnit, Area, BHK } from "../../../redux-magic/sub-interfaces/sub-interfaces";
import { ObjectId } from "mongodb";

/* Component Imports */

import Link from "@mui/material/Link";
import PropertyCardImage from "./sub-components/PropertyCardImage";
import PropertyCardTitle from "./sub-components/PropertyCardTitle";
import PropertyCardDeveloper from "./sub-components/PropertyCardDeveloper";
import PropertyCardPrice from "./sub-components/PropertyCardPrice";
import PropertyCardLocation from "./sub-components/PropertyCardLocation";
import PropertyCardChips from "./sub-components/PropertyCardChips";
import PropertyCardAreaAndConfig from "./sub-components/PropertyCardAreaAndConfig";
import { Box, useTheme } from "@mui/material";
import { ToggleBoostModalThunk } from "../../../redux-magic/store";

/* Styled Components */

const PropertyBox = styled(Box)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const PropertyLink = styled(Link)(({ theme }) => ({
	width: "100%",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const PropertyCardContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "8px",
	objectFit: "contain",
	width: "20.53rem",
	margin: "0.1rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: "16.5rem",
		height: "27.5rem",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "18rem",
		height: "27.5rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "20rem",
		height: "27rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "21rem",
		height: "27.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "22rem",
		height: "27.5rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "23rem",
		height: "27.5rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "25.5rem",
		height: "27.5rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22rem",
		height: "27.5rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "20.5rem",
		height: "27.5rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22rem",
		height: "27.5rem",
	},
	[theme.breakpoints.up("md")]: {
		// width: "32.33%",
		height: "27.5rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "20.33rem",
		height: "27.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "21.33rem",
		height: "27.5rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.53rem",
		height: "27.5rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "21.53rem",
		height: "27.5rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "23.53rem",
		height: "28rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "26.33rem",
		height: "28rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	padding: "1rem",
	margin: "0rem 0rem 0rem 0rem",
	gap: "0.75rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 8px 8px",
	zIndex: 4,
	cursor: "pointer",
}));

const PropertyCard = ({
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	property_type,
	title,
	images,
	property_status,
	tags,
	location,
	developer,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	saved_content,
	page_type,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	showBoostBtn = false,
}: {
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	transaction_type: string;
	property_type: Array<string>;
	title: string;
	images: Array<property_images>;
	property_status: Array<string>;
	tags: Array<string>;
	location: string;
	developer: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	saved_content: Array<any>;
	page_type?: string;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean;
	boosting: StateInterface["property"]["boost_information"];
	preview?: boolean;
	showBoostBtn?: boolean;
}) => {
	const theme = useTheme();

	const setPropertyBoostData = () => {
		dispatch(ToggleBoostModalThunk({ state: true, id: slug }));
	};

	let BoostingCheck = boosting?.active ? true : false;

	const LinkContainer = ({ children }: React.PropsWithChildren) => (
		(
			<PropertyLink
				href={preview ? "javascript:void(0)" : `/properties/${slug}`}
				rel="noopener"
				target={preview ? "_self" : "_blank"}
				referrerPolicy="no-referrer"
				title={title ? title : "Unknown Title"}
			>
				{children}
			</PropertyLink>
		)
	);

	return (
		<React.Fragment>
			<PropertyBox>
				<PropertyCardContainer>
					<PropertyCardImage
						_id={_id}
						slug={slug}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						page_type={page_type}
						created_by={created_by}
						assigned_to={assigned_to}
						isEditor={isEditor}
						boosting={boosting}
						preview={preview}
						title={title}
						showBoostBtn={showBoostBtn}
						setPropertyBoostData={setPropertyBoostData}
					/>

					<LinkContainer>
						<PropertyCardInfoContainer
							sx={
								loading
									? { gap: "0rem" }
									: {
											borderBottom: BoostingCheck ? "3px solid #fdb375" : "none",
											borderRight: BoostingCheck ? "3px solid #fdb375" : "none",
											borderLeft: BoostingCheck ? "3px solid #fdb375" : "none",
											background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
										}
							}
						>
							<PropertyCardTitle
								loading={loading}
								title={title !== "" || undefined ? title : "Unknown Title"}
							/>
							<PropertyCardChips
								loading={loading}
								transaction_type={transaction_type}
								property_type={property_type}
								tags={tags ? tags : []}
								property_status={property_status}
								location={location}
							/>
							<PropertyCardLocation
								loading={loading}
								location={location !== "" || undefined ? location : "Unknown Location"}
							/>
							<PropertyCardDeveloper
								loading={loading}
								developer={developer !== "" || undefined ? developer : "Unknown Developer"}
							/>
							<PropertyCardPrice
								loading={loading}
								price={price}
							/>
							<PropertyCardAreaAndConfig
								loading={loading}
								transactionType={transaction_type}
								propertyType={property_type ? property_type : []}
								area={area}
								areaType={
									property_type
										? property_type.includes("Land (Residential)") ||
											property_type.includes("Land (Commercial)") ||
											property_type.includes("Land (Agricultural)") ||
											property_type.includes("Land (Industrial)")
											? "land_area"
											: "super_built_up_area"
										: "super_built_up_area"
								}
								bhk={bhk}
								floors={floors}
								noOfSeats={no_of_seats}
								pricePerUnit={price_per_unit}
							/>
						</PropertyCardInfoContainer>
					</LinkContainer>
				</PropertyCardContainer>
			</PropertyBox>
		</React.Fragment>
	);
};

export default PropertyCard;
