/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const ChipPacket = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 0rem 0rem",
	overflowX: "scroll",
	overflowY: "hidden",
	"&::-webkit-scrollbar": {
		display: "none",
	},
	scrollbarWidth: "none",
}));

const Chip = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.125rem 0.5rem 0.125rem 0.5rem",
	margin: "0rem 0.5rem 0rem 0rem",
	borderRadius: "8px",
	whiteSpace: "nowrap",
	background: theme.palette.mode === "dark" ? "#919EAB14" : "#fafafa",
}));

const ChipText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.75rem",
	lineHeight: "1.125rem",
	color: theme.palette.mode === "dark" ? "#ffffff" : "#00000099",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
}));

const ServiceCardChips = ({
	loading,
	tags,
	service_subtype,
	location,
}: {
	loading: boolean;
	tags: Array<string>;
	service_subtype: Array<string>;
	location: string;
}) => {
	if (loading) {
		return (
			<React.Fragment>
				<SkeletonSection>
					<Skeleton
						animation="wave"
						variant="text"
						width="20%"
						height="auto"
						sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="20%"
						height="auto"
						sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="20%"
						height="auto"
						sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="20%"
						height="auto"
						sx={{ margin: "1rem 0.5rem 1rem 0rem" }}
					/>
				</SkeletonSection>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<ChipPacket>
				<Chip>
					<ChipText>{"Service"}</ChipText>
				</Chip>

				{service_subtype !== undefined && service_subtype.length > 0 ? (
					<Chip>
						<ChipText>
							{(service_subtype[0]?.charAt(0).toUpperCase() + service_subtype[0]?.slice(1))
								.replace(/_/g, " ")
								.replace(/Hvac /g, "HVAC ")
								.replace(/Ac /g, "AC ")
								.replace(/Tv /g, "TV ")
								.replace(/Lpg/g, "LPG")
								.replace(/Cctv/g, "CCTV")
								.replace(/open parenthesis it close parenthesis/g, "(IT)")
								.replace(/ or /g, " / ")
								.replace(/ and /g, " & ")}
						</ChipText>
					</Chip>
				) : null}

				{location !== undefined && location !== "" ? (
					<Chip>
						<ChipText>{location}</ChipText>
					</Chip>
				) : null}
				{/* 
        {tags.map((tag: string, key: number) => {
          return (
            <Chip key={key}>
              <ChipText>{tag.charAt(0).toUpperCase() + tag.slice(1)}</ChipText>
            </Chip>
          );
        })} */}
			</ChipPacket>
		</React.Fragment>
	);
};

export default ServiceCardChips;
